/* #region Like Item */

export const LIKE_ITEM_PRE_INIT = 'ITEM/LIKE_PRE_INIT'
/**
 * @param {string} [url]
 * @param {Array} [dataToInvalidate]
 */
export const requestPreInit = (url, dataToInvalidate) => ({
  type: LIKE_ITEM_PRE_INIT,
  url,
  dataToInvalidate
})

export const LIKE_ITEM_SUCCEEDED = 'ITEM/LIKE_SUCCEEDED'
/**
 * @param {Object} payload
 */
export const requestSucceeded = payload => ({
  type: LIKE_ITEM_SUCCEEDED,
  payload
})

/* #endregion */

/* #region Score Item */
export const SCORE_ITEM_PRE_INIT = 'ITEM/SCORE_PRE_INIT'
export const scoreRequestPreInit = (url, refreshUrl, id, data, callback) => ({
  type: SCORE_ITEM_PRE_INIT,
  url,
  refreshUrl,
  id,
  data,
  callback
})

/* #endregion */

/* #region Publish Idea */

export const PUBLISH_IDEA_PRE_INIT = 'IDEA/PUBLISH_PRE_INIT'

/**
 * @param {string} url
 * @param {Array} [dataToInvalidate]
 * @param {function} [callback]
 */
export const publishIdeaPreInit = (url, dataToInvalidate, callback) => ({
  type: PUBLISH_IDEA_PRE_INIT,
  url,
  dataToInvalidate,
  callback
})

export const PUBLISH_IDEA_SUCCEEDED = 'IDEA/PUBLISH_SUCCEEDED'

/**
 * @param {Object} payload
 */
export const publishIdeaSucceeded = payload => ({
  type: PUBLISH_IDEA_SUCCEEDED,
  payload
})

/* #endregion */

/* #region Assign an expert */

export const ASSIGN_EXPERTS_PRE_INIT = 'IDEA/ASSIGN_EXPERTS_PRE_INIT'

/**
 * @param {string} url
 * @param {Object} data
 * @param {string} [refreshUrl]
 * @param {function} [callback]
 */
export const assignExpertsPreInit = (url, data, refreshUrl, callback) => ({
  type: ASSIGN_EXPERTS_PRE_INIT,
  url,
  data,
  refreshUrl,
  callback
})

/* #endregion */

/* #region Comments */
export const INVALIDATE_COMMENTS = 'COMMENTS/INVALIDATE'
export const invalidateComments = () => {
  return {
    type: INVALIDATE_COMMENTS,
    dataType: 'comments'
  }
}
/* #endregion */

/* #region Idea Documents */
export const ASSIGN_DOCUMENTS_PRE_INIT = 'IDEA/ASSIGN_DOCUMENTS_PRE_INIT'

/**
 * @param {string} url
 * @param {Object} data
 * @param {function} [callback]
 */

export const assignDocumentsPreInit = (url, data, callback) => ({
  type: ASSIGN_DOCUMENTS_PRE_INIT,
  url,
  data,
  callback
})

export const ASSIGN_DOCUMENTS_SUCCEEDED = 'IDEA/ASSIGN_DOCUMENTS_SUCCEEDED'

/**
 * @param {Object} payload
 */

export const assignDocumentsSucceeded = (payload) => ({
  type: ASSIGN_DOCUMENTS_SUCCEEDED,
  payload
})

export const REMOVE_DOCUMENTS_PRE_INIT = 'IDEA/REMOVE_DOCUMENTS_PRE_INIT'

/**
 * @param {string} url
 * @param {Object} data
 * @param {function} [callback]
 * @param {string} [refreshUrl]
 */

export const removeDocumentsPreInit = (url, data, callback, refreshUrl) => ({
  type: REMOVE_DOCUMENTS_PRE_INIT,
  url,
  data,
  callback,
  refreshUrl
})

export const REMOVE_DOCUMENTS_SUCCEEDED = 'IDEA/REMOVE_DOCUMENTS_SUCCEEDED'

/**
 * @param {Object} payload
 */

export const removeDocumentsSucceeded = (payload) => ({
  type: REMOVE_DOCUMENTS_SUCCEEDED,
  payload
})
/* #endregion */

export const actionTypes = {
  LIKE_ITEM_PRE_INIT,
  LIKE_ITEM_SUCCEEDED,
  SCORE_ITEM_PRE_INIT,
  PUBLISH_IDEA_PRE_INIT,
  PUBLISH_IDEA_SUCCEEDED,
  ASSIGN_EXPERTS_PRE_INIT,
  INVALIDATE_COMMENTS,
  ASSIGN_DOCUMENTS_PRE_INIT,
  ASSIGN_DOCUMENTS_SUCCEEDED,
  REMOVE_DOCUMENTS_PRE_INIT,
  REMOVE_DOCUMENTS_SUCCEEDED
}

const actionCreators = {
  requestPreInit,
  requestSucceeded,
  scoreRequestPreInit,
  publishIdeaPreInit,
  publishIdeaSucceeded,
  assignExpertsPreInit,
  invalidateComments,
  assignDocumentsPreInit,
  assignDocumentsSucceeded,
  removeDocumentsPreInit,
  removeDocumentsSucceeded
}
export default actionCreators
