import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Form } from './'
import actions from '../../../state/actions/data'
import { getCreateChallengeUrl, getUserDetails } from '../../../selectors'
import {
  handleDraftSubmitTemplate,
  convertFileForApi,
  handleSubmitTemplate
} from '../../../helpers/forms'

const CreateForm = ({ notificationsTrigger }) => {
  const dispatch = useDispatch()
  const createUrl = useSelector(getCreateChallengeUrl)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const user = useSelector(getUserDetails())

  const initialValues = {
    title: '',
    subTitle: '',
    tab1: '',
    tab2: '',
    smallImage: [],
    largeImage: [],
    order: '',
    author: [
      {
        value: user?.id,
        label: user?.displayName
      }
    ]
  }

  // wp: validator used when Formik OnSubmit
  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    subTitle: Yup.string(),
    tab1: Yup.string().required('Required'),
    tab2: Yup.string(),
    smallImage: Yup.array().required('Required'),
    largeImage: Yup.array().required('Required'),
    order: Yup.number().typeError('Number needed')
  })

  const sendDataToApi = data => {
    setIsSubmitting(true)
    const dataToInvalidate = [{ dataType: 'challenges' }]

    const handleNotificationTrigger = res => {
      const itemId = res.status === 'HIBERNATED' ? res.masterId : res.id
      const returnUrl = `/challenge-details/${itemId}`

      notificationsTrigger({ id: itemId, url: returnUrl })
      setIsSubmitting(false)
    }

    dispatch(
      actions.createDataPreInit(
        createUrl,
        data,
        'challenges',
        dataToInvalidate,
        handleNotificationTrigger
      )
    )
  }

  async function formatData(values) {
    let smallImage = null
    let largeImage = null

    if (!!values.smallImage.length) {
      const smallImageFile = values.smallImage[0]
      smallImage = await convertFileForApi(smallImageFile)
    }

    if (!!values.largeImage.length) {
      const largeImageFile = values.largeImage[0]
      largeImage = await convertFileForApi(largeImageFile)
    }

    return { ...values, largeImage, smallImage }
  }

  const handleDraftSubmit = handleDraftSubmitTemplate(formatData, sendDataToApi)
  const handleSubmit = handleSubmitTemplate(formatData, sendDataToApi)

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleDraftSubmit={handleDraftSubmit}
      inProgress={isSubmitting}
    />
  )
}

CreateForm.defaultProps = {
  notificationsTrigger: () => {}
}

CreateForm.propTypes = {
  notificationsTrigger: PropTypes.func
}

export default CreateForm
