import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';
import { getEnvVariable } from '../.env.custom';
import { withRouter } from '../utils/withRouter';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
  state = {
    initialized: false,
  };

  componentDidMount() {
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey = getEnvVariable(
      'REACT_APP_INSIGHTS_INSTRUMENTATION_KEY'
    );
    if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey)) {
      ai.initialize(AppInsightsInstrumentationKey);
      this.setState({ initialized: true });
    }
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
