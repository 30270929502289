// @ts-check

import React from 'react'
import { useTranslation } from 'react-i18next'

const Loading = () => {
  const { t } = useTranslation()

  return <p>{t('common.loading')}</p>
}

export default Loading
