import React from 'react'
import { VfContainer } from '../Components/DesignSystem/Utils'
import { VfTextButton } from '../Components/DesignSystem/Buttons'
import image from '../assets/images/404.svg'
import './404.scss'

const PageNotFound = () => {
  return <VfContainer className="no-page__container">
    <h1>Oh, DEAR...</h1>
    <div className="vf-row no-page__row vf-row--vertical-center">
      <div className="vf-col-sm mt-3">
        <img className="no-page__container__image" src={image} alt="404" />
      </div>
      <div className="vf-col-sm mt-3">
        <p>We have a problem... 404</p>
        <p>Page you are looking for cannot be launched.</p>
        <VfTextButton to="/" text="Back home" />
      </div>
    </div>
  </VfContainer>
}

export default PageNotFound
