// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { Header, Footer } from './'

const Template = ({ id, children, ...props }) => {
  return (
    <>
      <Header pageId={id} />
      {children}
      <Footer />
    </>
  )
}

Template.propTypes = {
  // own props
  children: PropTypes.element,
  id: PropTypes.number
}

export default Template
