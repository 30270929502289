import React from 'react'
import {
  ActivateItem,
  EditItem,
  CloseItem,
  DeleteItem,
  OnHoldItem
} from '../Components/ctas/items'

export const ctas = (
  challengeId = '',
  { handleDelete, handleActivate, handleClose, handleOnHold }
) => ({
  DRAFT: {
    USER: [],
    ADMIN: [
      <EditItem key='EditItem' to={`/cms/challenge/${challengeId}/update`} />,
      <DeleteItem key='DeleteItem' handleClick={handleDelete} />
    ]
  },
  PUBLISHED: {
    USER: [],
    ADMIN: [
      <EditItem key='EditItem' to={`/cms/challenge/${challengeId}/update`} />,
      <ActivateItem key='ActivateItem' handleClick={handleActivate} />,
      <OnHoldItem key='OnHoldItem' handleClick={handleOnHold} />,
      <DeleteItem key='DeleteItem' handleClick={handleDelete} />
    ]
  },
  ACTIVE: {
    USER: [],
    ADMIN: [
      <EditItem key='EditItem' to={`/cms/challenge/${challengeId}/update`} />,
      <CloseItem key='CloseItem' handleClick={handleClose} />,
      <DeleteItem key='DeleteItem' handleClick={handleDelete} />
    ]
  },
  CLOSED: {
    USER: [],
    ADMIN: [
      <ActivateItem key='ActivateItem' handleClick={handleActivate} />,
      <DeleteItem key='DeleteItem' handleClick={handleDelete} />
    ]
  },
  DELETED: {
    USER: [],
    ADMIN: []
  }
})
