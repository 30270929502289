import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { useLanguage } from '../../../hooks'
import actions from '../../../state/actions/data'
import { languages } from '../challenge'
import {
  convertFileForApi,
  handleSubmitTemplate,
} from '../../../helpers/forms'
import { mergeUrlWithParams } from '../../../helpers/url'
import InfoBoxForm from './InfoBoxForm'
import { Loading } from '../../Common'
import { requestPreInitInfoBoxes } from '../../../state/actions/infoboxes'
import { getEnvVariable } from '../../../.env.custom'

const EditInfoBoxForm = ({
  notificationsTrigger,
  item,
}) => {
  const dispatch = useDispatch()
  const defaultLanguage = useLanguage()
  const [lng, setLng] = useState(defaultLanguage)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const url = `${getEnvVariable('REACT_APP_API_ROOT')}info-boxes/overview/?lng=${lng}`
    dispatch(requestPreInitInfoBoxes(url))
  }, [dispatch, lng])

  const validationSchema = Yup.object({
    title: Yup.string(),
    description: Yup.string(),
    language: Yup.string()
      .oneOf(languages.map(({ value }) => value))
      .required('required'),
    image: Yup.array().required('Required'),
  })

  const initialValues = {
    title: item?.title || '',
    description: item?.description || '',
    image: [item?.image].filter(item => item),
    language: item?.language || 'EN',
  }

  const handleCountryChange = (_, value) => setLng(value)

  const formatContext = (url, pageId, lng) => {
    const base = url.split('?')[0]
    const context = mergeUrlWithParams({
      pageId,
      lng,
    })(`${base}{?}`)

    return context
  }

  const sendDataToApi = data => {
    setIsSubmitting(true)
    const url = item.links[0].href
    const context = formatContext(
      url,
      item?.id,
      lng,
    )

    const handleNotificationTrigger = res => {
      const itemId = res.id
      const returnUrl = `/overview`

      notificationsTrigger({
        id: itemId,
        url: returnUrl
      })
      setIsSubmitting(false)
    }

    const dataToInvalidate = [
      {
        dataType: 'info-boxes',
        context,
      },
    ]

    dispatch(
      actions.updateDataPreInit(
        url,
        data,
        'info-boxes',
        dataToInvalidate,
        handleNotificationTrigger,
      ),
    )
  }

  const isNewFile = file => file.constructor.name === 'File'

  async function formatData(values) {
    let imageContent = null
    let imageId = null

    if (!!values.image.length) {
      if (isNewFile(values.image[0])) {
        const imageFile = values.image[0]
        imageContent = await convertFileForApi(imageFile)
      } else {
        imageId = values.image[0].id
      }
    }

    const linkUrl = item?.linkUrl || ''

    // Create a new object excluding the 'image' field
    const {
      image,
      ...restValues
    } = values


    return {
      ...restValues,
      imageContent: {
        ...imageContent,
        id: null,
      },
      imageId,
      linkUrl,
    }
  }

  const handleSubmit = handleSubmitTemplate(formatData, sendDataToApi)

  if (!item) {
    return <Loading />
  }

  return item ? (
    <>
      <InfoBoxForm
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleCountryChange={handleCountryChange}
        showLanguageDdl
        inProgress={isSubmitting}
      />
    </>
  ) : null
}

EditInfoBoxForm.defaultProps = {
  notificationsTrigger: () => {
  },
}

EditInfoBoxForm.propTypes = {
  notificationsTrigger: PropTypes.func,
}

export default EditInfoBoxForm
