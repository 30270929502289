import { combineReducers } from 'redux'
import { actionTypes } from '../actions/notifications'

const payload = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_SUCCEEDED:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_INIT:
      return true
    case actionTypes.FETCH_NOTIFICATIONS_SUCCEEDED:
      return false

    default:
      return state
  }
}

const isError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_FAILED:
      return true
    case actionTypes.FETCH_NOTIFICATIONS_INIT:
    case actionTypes.FETCH_NOTIFICATIONS_SUCCEEDED:
      return false

    default:
      return state
  }
}

const notifications = combineReducers({ payload, isFetching, isError })

export default notifications
