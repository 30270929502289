// @ts-check
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mergeUrlWithParams } from "../helpers/url";

/**
 * @param {string} key
 * @param {object} params
 * @param {function} urlSelector
 * @param {function} dataSelector
 * @param {function} requestAction
 * @param {function} [isFetchingSelector]
 * @param {string} [paginationUrl]
 */
const useData = (
  key,
  params,
  urlSelector,
  dataSelector,
  requestAction,
  isFetchingSelector,
  paginationUrl
) => {
  const rawUrl = useSelector(urlSelector);
  const url = paginationUrl || mergeUrlWithParams(params)(rawUrl);
  const dispatch = useDispatch();
  const data = useSelector(dataSelector(url));
  const isFetchingSelectorTmp = !!isFetchingSelector
    ? isFetchingSelector
    : () => () => null;
  const isFetching = useSelector(isFetchingSelectorTmp(url));

  useEffect(() => {
    !!rawUrl && dispatch(requestAction(url, key));
  }, [dispatch, key, rawUrl, requestAction, url]);

  return { data, url, isFetching };
};

export default useData;
