import React from 'react'
import PropTypes from 'prop-types'

const SubmitButton = ({
  button,
  inProgress,
  inProgressText = 'In progress...',
  disabled = false
}) => {
  return React.cloneElement(button, {
    type: 'submit',
    text: `${inProgress ? inProgressText : button.props.text}`,
    disabled
  })
}

SubmitButton.propTypes = {
  button: PropTypes.node.isRequired,
  inProgress: PropTypes.bool.isRequired,
  inProgressText: PropTypes.string,
  disabled: PropTypes.bool
}

export default SubmitButton
