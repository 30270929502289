// @ts-check

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { VfButton } from '../../DesignSystem/Buttons'
import RadarChart from './RadarChart'
import ExpertSummary from './ExpertSummary'
import RatingForm from './RatingForm'
import DecisionForm from './DecisionForm'
// import ResponsibleExpert from './ResponsibleExpert'

import './expert-view.scss'

const categories = {
  levelOfInnovation: { id: 'levelOfInnovation', label: 'Level of innovation' },
  valueForCustomers: { id: 'valueForCustomers', label: 'Value for customers' },
  longtermCostEffectiveness: { id: 'longtermCostEffectiveness', label: 'Long Term Cost Effectiveness' },
  valueForVattenfall: {
    id: 'valueForVattenfall',
    label: 'Value for Vattenfall'
  },
  feasibility: { id: 'feasibility', label: 'Feasibility' },
  fitToStrategyAndPurpose: {
    id: 'fitToStrategyAndPurpose',
    label: 'Fit to strategy and purpose'
  }
}

const ExpertView = ({ idea, ...other }) => {
  const { t } = useTranslation()
  const [ratingFormVisible, setRatingFormVisible] = useState(false)
  const closeRatingForm = () => setRatingFormVisible(false)
  const openRatingForm = () => setRatingFormVisible(true)

  const [decisionFormVisible, setDecisionFormVisible] = useState(false)
  const closeDecisionForm = () => setDecisionFormVisible(false)
  const openDecisionForm = () => setDecisionFormVisible(true)

  return (
    <div>
      {/* <ResponsibleExpert idea={idea} /> */}
      <RadarChart t={t} idea={idea} categories={categories} />

      <ExpertSummary t={t} categories={categories} idea={idea} />

      <div className='vf-row mt-2'>
        <VfButton
          text={t('expert-view.addRatings')}
          color='secondary'
          className='vf-col m-1'
          onClick={() => {
            closeDecisionForm()
            openRatingForm()
          }}
          disabled={ratingFormVisible}
        />

        <VfButton
          text={t('expert-view.makeDecision')}
          color='secondary'
          className='vf-col m-1'
          onClick={() => {
            openDecisionForm()
            closeRatingForm()
          }}
          disabled={decisionFormVisible}
        />
      </div>

      {ratingFormVisible && (
        <div className='expert-view__form-container mt-3'>
          <h4>{t('expert-view.addRatings')}</h4>

          <RatingForm
            categories={categories}
            handleCancel={closeRatingForm}
            idea={idea}
            {...other}
          />
        </div>
      )}

      {decisionFormVisible && (
        <div className='expert-view__form-container mt-3'>
          <h4>{t('expert-view.makeDecision')}</h4>

          <DecisionForm
            handleCancel={closeDecisionForm}
            idea={idea}
            {...other}
          />
        </div>
      )}
    </div>
  )
}

ExpertView.propTypes = {
  idea: PropTypes.object
}

export default ExpertView
