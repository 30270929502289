// @ts-check

import React from 'react';

import { CreateForm } from '../Components/Ideas';
import { useParams } from 'react-router-dom';

const IdeaCreate = () => {
  const { challengeId } = useParams();

  return <CreateForm selectedChallenge={challengeId} />;
};

export default IdeaCreate;
