// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { VfCardAsLink } from '../../DesignSystem/Cards';
import {
  StarsPainter,
  LikesPainter,
  CommentsSummary,
  ViewsPainter,
} from '../../Common';
import { getAuthorFullName } from '../../../helpers/author';
import { getChallengeTheme } from '../../../helpers/color';
import { dateLocal } from '../../../helpers/date';

const Item = ({
  item: {
    id,
    title,
    localizedChallengeInfo = {},
    published = '',
    author,
    description,
    starsCount = 0,
    commentsCount = 0,
    likesCount = 0,
    numberOfViews = 0,
    challenge: { id: challengeId },
  },
  showViews = false,
}) => {
  const { t } = useTranslation();

  const colors = getChallengeTheme(challengeId);

  return (
    <div className="vf-col-sm-12 vf-col-md-6 vf-col-lg-4">
      <VfCardAsLink
        to={`/idea-details/${id}`}
        title={title}
        bodyText={`${description.slice(0, 200)}...`}
        headerBgColor={colors.mainColor}
        headerTitle={
          localizedChallengeInfo.title || localizedChallengeInfo.titles.EN
        }
        headerCategory={t('common.challenge_plural')}
        caption={`${t('common.published')} ${dateLocal(published)}`}
        barColor={colors.bar}
      >
        <div className="vf-card__text">
          {author && (
            <p className="mb-1 idea-card__author">
              {t('common.contributor')} <b>{getAuthorFullName(author)}</b>
            </p>
          )}
          <div className="idea-opinions">
            {showViews && <ViewsPainter count={numberOfViews} />}
            <StarsPainter rating={starsCount} />
            <LikesPainter count={likesCount} />
            <CommentsSummary count={commentsCount} t={t} />
          </div>
        </div>
      </VfCardAsLink>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  showViews: PropTypes.bool,
};

export default Item;
