import { combineReducers } from 'redux'
import { actionTypes } from '../actions/peopleGroups'

const payload = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_PEOPLE_GROUPS_SUCCEEDED:
      return action.payload
    case actionTypes.CREATE_PEOPLE_GROUPS_SUCCESS:
      return [...state, action.payload]
    case actionTypes.REMOVE_PEOPLE_GROUPS_SUCCESS:
      return state.filter(group => group.id !== action.payload.id)
    case actionTypes.UPDATE_PEOPLE_GROUPS_SUCCESS:
      return state.map(group =>
        group.id === action.payload.id ? action.payload : group,
      )
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PEOPLE_GROUPS_INIT:
    case actionTypes.FETCH_PEOPLE_GROUPS_PRE_INIT:
      return true
    case actionTypes.FETCH_PEOPLE_GROUPS_FAILED:
    case actionTypes.FETCH_PEOPLE_GROUPS_SUCCEEDED:
      return false
    default:
      return state
  }
}

const isError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PEOPLE_GROUPS_FAILED:
      return true
    case actionTypes.FETCH_PEOPLE_GROUPS_SUCCEEDED:
      return false
    default:
      return state
  }
}

const isMutating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.CREATE_PEOPLE_GROUPS_INIT:
    case actionTypes.CREATE_PEOPLE_GROUPS_PRE_INIT:
    case actionTypes.REMOVE_PEOPLE_GROUPS_INIT:
    case actionTypes.REMOVE_PEOPLE_GROUPS_PRE_INIT:
    case actionTypes.UPDATE_PEOPLE_GROUPS_INIT:
    case actionTypes.UPDATE_PEOPLE_GROUPS_PRE_INIT:
      return true
    case actionTypes.CREATE_PEOPLE_GROUPS_FAILED:
    case actionTypes.CREATE_PEOPLE_GROUPS_SUCCESS:
    case actionTypes.REMOVE_PEOPLE_GROUPS_FAILED:
    case actionTypes.REMOVE_PEOPLE_GROUPS_SUCCESS:
    case actionTypes.UPDATE_PEOPLE_GROUPS_FAILED:
    case actionTypes.UPDATE_PEOPLE_GROUPS_SUCCESS:
      return false
    default:
      return state
  }
}

const peopleGroups = combineReducers({
  payload,
  isFetching,
  isError,
  isMutating,
})

export default peopleGroups
