import React from 'react';
import { icons } from '../../assets/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomTooltip from './CustomTooltip';

import './Events.scss';
import {
  formatIsoDate,
  formatTimeDifference,
  hasDatePassed,
} from '../../helpers/date';

import addToCalendarIcon from '../../assets/icons/VF_more_rgb.svg';
import redirectIcon from '../../assets/icons/VF_arrow_right_rgb.svg';

const eventTypesToIcons = {
  MEETING: icons.Event,
  WORKSHOP: icons.Thinking,
  PHASE_START: icons.Start,
  PHASE_END: icons.Flag,
  FINAL: icons.Celebration,
};

const sharepointLink = 'https://vattenfall.sharepoint.com/';

const createICSFile = (event) => {
  const { name, takesPlaceOn, eventType } = event;
  const eventTypeName = eventType?.camelCase || 'Meeting' ;
  const startDate = new Date(takesPlaceOn)
    .toISOString()
    .replace(/[-:]/g, '')
    .split('.')[0];
  const endDate = new Date(new Date(takesPlaceOn).getTime() + 3600000) // 1-hour duration
    .toISOString()
    .replace(/[-:]/g, '')
    .split('.')[0];

  const icsContent = `BEGIN:VCALENDAR\r\nVERSION:2.0\r\nBEGIN:VEVENT\r\nSUMMARY:${eventTypeName}:${name}\r\nDTSTART:${startDate}\r\nDTEND:${endDate}\r\nDESCRIPTION:Event Type: ${eventType || 'Meeting'}\r\nEND:VEVENT\r\nEND:VCALENDAR\r\n`;
  const blob = new Blob([icsContent], { type: 'text/calendar' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${eventTypeName}:${name}.ics`;
  a.click();

  URL.revokeObjectURL(url);
};

const EventsDates = ({ events }) => {
  const { t } = useTranslation();

  const validEvents = Array.isArray(events) ? events : [];
  const sortedDates = validEvents.sort(
    (a, b) => new Date(a.takesPlaceOn) - new Date(b.takesPlaceOn),
  );

  const upcomingEvents = sortedDates.filter(
    (event) => !hasDatePassed(event.takesPlaceOn),
  );
  const nextActiveEventDate = upcomingEvents[0]?.takesPlaceOn;

  const renderEvents = (event) => {
    const { eventType, takesPlaceOn, name } = event;

    const isPassed = hasDatePassed(takesPlaceOn);

    return (
      <div
        className={`event${isPassed ? ' event__passed' : ''}`}
        key={`${takesPlaceOn}-${name}`}
      >
        <img
          alt=""
          src={eventTypesToIcons[eventType || 'MEETING']}
          className="event-type-icon"
        />
        &nbsp;
        <span className="event-name">{`${formatIsoDate(
          takesPlaceOn,
        )} - ${name}`}</span>
        &nbsp;
        {isPassed ? (
          <CustomTooltip title="View Materials" arrow>
            <a href={sharepointLink} target="_blank" rel="noopener noreferrer">
              <img
                src={redirectIcon}
                alt={t('events.viewMaterials')}
                className="active-icon"
              />
            </a>
          </CustomTooltip>
        ) : (
          <CustomTooltip title="Add to Calendar" arrow>
            <img
              src={addToCalendarIcon}
              alt={t('events.addToCalendar')}
              className="active-icon"
              onClick={() =>
                createICSFile({
                  name,
                  takesPlaceOn,
                  eventType,
                })
              }
            />
          </CustomTooltip>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="events-header">
        {events?.[0] && !!nextActiveEventDate && (
          <p className="next-event">
            <strong>{t('events.nextEventIn')}: </strong>&nbsp;
            <span>{formatTimeDifference(nextActiveEventDate, t)}</span>
          </p>
        )}
      </div>
      <div className="events-section">
        {sortedDates.map((event) => renderEvents(event))}
      </div>
    </div>
  );
};

EventsDates.propTypes = {
  events: PropTypes.array,
};

EventsDates.defaultProps = {
  events: [],
};

export default EventsDates;
