import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field, Form } from 'formik'

import { useVfAlchemyScript } from '../../../hooks'
import {
  DropDown,
  RichTextEditor,
  TextInput,
  SubmitButton,
} from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import { ImageInput } from '../../Common/Attachments'
import { languages } from '../challenge'

const InfoBoxFormContainer = ({
  initialValues,
  validationSchema,
  handleSubmit,
  ...parentProps
}) => {
  useVfAlchemyScript()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}>
      {formikProps => {
        return (
          <InfoBoxFormComponent
            {...formikProps}
            {...parentProps}
          />
        )
      }}
    </Formik>
  )
}

InfoBoxFormContainer.propTypes = {
  initialValues: PropTypes.object,
  validationSchema: PropTypes.any,
  handleSubmit: PropTypes.func,
  handleCountryChange: PropTypes.func,
  showLanguageDdl: PropTypes.bool,
}

const InfoBoxFormComponent = ({
  handleCountryChange,
  showLanguageDdl,
  isValid,
  inProgress,
  ...props
}) => {

  return (
    <Form>
      {showLanguageDdl && (
        <Field
          name="language"
          label="Language"
          component={DropDown}
          onChange={handleCountryChange}
          options={languages.map(item => ({
            ...item,
            label: item.label,
          }))}
        />
      )}

      <Field name="title" label="Title" component={TextInput} />

      <Field
        name="description"
        label="Description (optional)"
        component={RichTextEditor}
      />

      <div>
        <Field
          name="image"
          label="image (360px x 165px)"
          imageSize={{
            w: 360,
            h: 165,
          }}
          component={ImageInput}
        />
      </div>

      {!isValid && (
        <p className="vf-utility-typo--error mt-3">
          One or more fields have an error. Please check and try again.
        </p>
      )}
      <div className="vf-row">
        <div className="mt-3 ml-auto">
          <SubmitButton
            button={<VfButton text="Save" type="submit" />}
            inProgress={inProgress}
            disabled={inProgress}
          />
        </div>
      </div>
    </Form>
  )
}

InfoBoxFormComponent.propTypes = {
  handleCountryChange: PropTypes.func,
  validateForm: PropTypes.func,
  values: PropTypes.any,
  setFieldTouched: PropTypes.func,
  setFieldError: PropTypes.func,
  showLanguageDdl: PropTypes.bool,
  isValid: PropTypes.bool,
  inProgress: PropTypes.bool,
}

export default InfoBoxFormContainer
