// @ts-check
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import userActions from '../state/actions/users';
import { mergeUrlWithParams } from '../helpers/url';
import { getUserUrl, getUserDetails, getIsUserFetching } from '../selectors';
import { getCurrentUserId } from '../utils/azure';

const useProfile = (profileId = getCurrentUserId()) => {
  const dispatch = useDispatch();
  const requestedProfileId = profileId;
  const rawUrl = useSelector(getUserUrl);
  const urlUser = mergeUrlWithParams({ requestedProfileId })(rawUrl);
  const user = useSelector(getUserDetails(requestedProfileId));
  const isFetching = useSelector(getIsUserFetching);

  useEffect(() => {
    !!rawUrl &&
      dispatch(userActions.requestPreInit(urlUser, requestedProfileId));
  }, [dispatch, profileId, rawUrl, requestedProfileId, urlUser]);

  return { user, isFetching };
};

export default useProfile;
