import Publish from "./event_security_rgb.png";
import Edit from "./edit_rgb.png";
import Pause from "./pause_rgb.png";
import Play from "./play_rgb.png";
import Close from "./off_rgb.png";
import Delete from "./trash_rgb.png";
import Event from "./event.png";
import Flag from "./flag.png";
import Celebration from "./celebration.png";
import Start from "./start.png";
import Thinking from "./thinking.png";

export const icons = {
  Celebration,
  Close,
  Delete,
  Edit,
  Event,
  Flag,
  Pause,
  Play,
  Publish,
  Start,
  Thinking,
};
