import { combineReducers } from 'redux'
import { actionTypes } from '../actions/contacts'

const payload = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTACTS_SUCCEEDED:
      return action.payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTACTS_INIT:
    case actionTypes.FETCH_CONTACTS_PRE_INIT:
      return true
    case actionTypes.FETCH_CONTACTS_FAILED:
    case actionTypes.FETCH_CONTACTS_SUCCEEDED:
      return false

    default:
      return state
  }
}

const isError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTACTS_FAILED:
      return true
    case actionTypes.FETCH_CONTACTS_SUCCEEDED:
      return false

    default:
      return state
  }
}

const isMutating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTACT_INIT:
    case actionTypes.CREATE_CONTACT_PRE_INIT:
    case actionTypes.REMOVE_CONTACT_INIT:
    case actionTypes.REMOVE_CONTACT_PRE_INIT:
    case actionTypes.UPDATE_CONTACT_INIT:
    case actionTypes.UPDATE_CONTACT_PRE_INIT:
      return true
    case actionTypes.CREATE_CONTACT_FAILED:
    case actionTypes.CREATE_CONTACT_SUCCESS:
    case actionTypes.REMOVE_CONTACT_FAILED:
    case actionTypes.REMOVE_CONTACT_SUCCESS:
    case actionTypes.UPDATE_CONTACT_FAILED:
    case actionTypes.UPDATE_CONTACT_SUCCESS:
      return false

    default:
      return state
  }
}

const contacts = combineReducers({
  payload,
  isFetching,
  isError,
  isMutating,
})

export default contacts
