import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../DesignSystem/Modal";
import { VfButton } from "../DesignSystem/Buttons";

import { getEventTypes, getEventsState } from "../../selectors";
import { Loading } from "../Common";
import { useLanguage, useVfAlchemyScript } from "../../hooks";

import { getEnvVariable } from "../../.env.custom";
import { requestPreInitEvents } from "../../state/actions/events";
import { capitalizedWithDashesToFormatted } from "../../helpers/forms";

import "./Events.scss";
import EventForm from "./EventForm";

const EventsEditor = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const lng = useLanguage();
  const [lang, setLang] = useState(lng);
  const { t } = useTranslation();
  const eventTypes = useSelector(getEventTypes);
  const { isFetching, payload: events } = useSelector(getEventsState);

  useVfAlchemyScript();

  useEffect(() => {
    if (isOpen) {
      const url = `${getEnvVariable("REACT_APP_API_ROOT")}events/?lng=${lang}`;
      dispatch(requestPreInitEvents(url));
    }
  }, [isOpen, lang, dispatch]);

  const eventTypesOptions = eventTypes.map((type) => ({
    label: capitalizedWithDashesToFormatted(type),
    value: type,
  }));

  const existingEvents = Array.isArray(events) ? events
    .filter((item) => item.id !== null)
    .map((item) => (
      <EventForm
        event={item}
        eventTypesOptions={eventTypesOptions}
        isEditMode
        onClose={onClose}
        key={item.id}
        lang={lang}
      />
    )) : []

  return (
    <Modal hidden={!isOpen} closeModal={onClose} fullScreen>
      <h4>Events editor</h4>
      <EventForm
        eventTypesOptions={eventTypesOptions}
        onClose={onClose}
        lang={lang}
        setLang={setLang}
      />
      <p className="mt-2 mb-1">Created events</p>
      {isFetching && <Loading />}
      {!isFetching && events.length > 0 && existingEvents}

      <div className="vf-row my-3">
        <VfButton
          text={t("common.cancel")}
          color="secondary"
          onClick={onClose}
          className="vf-col m-1"
        />
      </div>
    </Modal>
  );
};

EventsEditor.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

EventsEditor.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default EventsEditor;
