import React from 'react'
import { VfContainer } from '../../Components/DesignSystem/Utils'
import { CreateForm } from '../../Components/cms/challenge'
import { WithNotification } from '../../Components/cms/challenge/Notifications'

const CreateChallenge = () => {
  const FormWithNotifications = <WithNotification Component={CreateForm} />

  return (
    <VfContainer>
      <h1 className='my-4'>Create Challenge</h1>
      <p>
        Please fill the form below in English and save. To create other language
        version go back to the Challenge through Edit page.
      </p>
      {FormWithNotifications}
    </VfContainer>
  )
}

export default CreateChallenge
