// @ts-check

export const FETCH_CONFIG_INIT = 'CONFIG/FETCH_INIT'
export const requestInit = () => ({
  type: FETCH_CONFIG_INIT
})

export const FETCH_CONFIG_PRE_INIT = 'CONFIG/FETCH_PRE_INIT'
/**
 * @param {string} [url]
 */
export const requestPreInit = (url) => ({
  type: FETCH_CONFIG_PRE_INIT,
  url
})

export const FETCH_CONFIG_SUCCEEDED = 'CONFIG/FETCH_SUCCEEDED'
/**
 * @param {Object} payload
 */
export const fetchSucceeded = (payload) => ({
  type: FETCH_CONFIG_SUCCEEDED,
  payload
})

export const FETCH_CONFIG_FAILED = 'CONFIG/FETCH_FAILED'
/**
 * @param {Object} payload
 */
export const fetchFailed = (payload) => ({
  type: FETCH_CONFIG_FAILED,
  payload
})

export const actionTypes = {
  FETCH_CONFIG_PRE_INIT,
  FETCH_CONFIG_INIT,
  FETCH_CONFIG_SUCCEEDED,
  FETCH_CONFIG_FAILED
}

const actionCreators = {
  requestPreInit,
  requestInit,
  fetchSucceeded,
  fetchFailed
}

export default actionCreators
