// @ts-check
import React, { useState, lazy, Suspense } from 'react'
import { SearchIdeasForm } from '../Components/Ideas'
import { Loading } from '../Components/Common'

const IdeasList = lazy(() => import('../Components/Ideas'))

const SearchIdeas = () => {
  const [layoutType, setLayoutType] = useState('box')
  const [params, setParams] = useState({
    pageSize: 30,
    page: 0,
    sort: 'LAST_PUBLISHED'
  })
  const [isSearching, setIsSearching] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const [sortedByTrendiest, setSortedByTrendiest] = useState(false)

  const handleSearch = newParams => {
    setParams({
      ...params,
      ...newParams
    })

    setFiltered(true)

    if (newParams.sort === 'TRENDIEST') {
      setSortedByTrendiest(true)
    } else {
      setSortedByTrendiest(false)
    }
  }

  return (
    <>
      <SearchIdeasForm handleSearch={handleSearch} isSearching={isSearching} />

      <Suspense fallback={<Loading />}>
        <IdeasList
          layoutType={layoutType}
          setLayoutType={setLayoutType}
          showHeader
          urlParams={params}
          setIsSearching={setIsSearching}
          filtered={filtered}
          sortedByTrendiest={sortedByTrendiest}
          showTopPagination
          showBottomPagination
        />
      </Suspense>
    </>
  )
}

export default SearchIdeas
