// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import VfHero from '../DesignSystem/Hero'
import { VfContainer } from '../DesignSystem/Utils'

import headerVideoMp4 from '../../assets/videos/header_video2021.mp4'
import Events from '../Events'

const Template = ({
  heroImg,
  title,
  children,
  ...props
}) => {
  const { showVideo } = children.props
  const videos = [
    // { src: headerVideoWebm, type: 'video/webm' },
    {
      src: headerVideoMp4,
      type: 'video/mp4',
    },
  ]

  return (
    <div className="overview-page">
      <VfContainer>
        <div className="vf-row">
          <div className="vf-col-lg-6 vf-col-md-6 vf-col-sm-12">
            <VfHero
              imageUrl={heroImg}
              videoSrcSet={videos}
              showVideo={showVideo}
            />
          </div>

          <div className="vf-col-lg-6 vf-col-md-6 vf-col-sm-12 mt-2">
            <Events title={title} />
          </div>
        </div>
        {children}
      </VfContainer>
    </div>
  )
}

Template.propTypes = {
  heroImg: PropTypes.string,
  title: PropTypes.string,
  showVideo: PropTypes.bool,
  children: PropTypes.node,
}

Template.defaultProps = {
  title: '',
}

export default Template
