// @ts-check

export const FETCH_NOTIFICATIONS_INIT = 'NOTIFICATIONS/FETCH_INIT'
export const requestInit = () => ({
  type: FETCH_NOTIFICATIONS_INIT
})

export const FETCH_NOTIFICATIONS_PRE_INIT = 'NOTIFICATIONS/FETCH_PRE_INIT'
/**
 * @param {string} url
 */
export const requestPreInit = url => ({
  type: FETCH_NOTIFICATIONS_PRE_INIT,
  url
})

export const FETCH_NOTIFICATIONS_SUCCEEDED = 'NOTIFICATIONS/FETCH_SUCCEEDED'
/**
 * @param {Object} payload
 */
export const fetchSucceeded = payload => ({
  type: FETCH_NOTIFICATIONS_SUCCEEDED,
  payload
})

export const FETCH_NOTIFICATIONS_FAILED = 'NOTIFICATIONS/FETCH_FAILED'
/**
 * @param {Object} payload
 */
export const fetchFailed = payload => ({
  type: FETCH_NOTIFICATIONS_FAILED,
  payload
})

export const actionTypes = {
  FETCH_NOTIFICATIONS_PRE_INIT,
  FETCH_NOTIFICATIONS_INIT,
  FETCH_NOTIFICATIONS_SUCCEEDED,
  FETCH_NOTIFICATIONS_FAILED
}

const actionCreators = {
  requestPreInit,
  requestInit,
  fetchSucceeded,
  fetchFailed
}

export default actionCreators
