import {
  InnerTemplate,
  InnerTemplateWithHeaderImg,
  InnerTemplateWithHeaderImgAndContactSection,
  NotificationBadge,
  OverviewTemplate,
} from './Components/Template';
import { AvatarCurrentUser } from './Components/User/Avatar';
// @ts-check
import * as pages from './pages';
import * as cmsPages from './pages/cms';

const routes = [
  {
    id: 1,
    pageId: 'overview',
    titleTkey: 'mainNav.overview',
    path: '/',
    component: pages.Overview,
    exact: true,
    nav: {
      parent: 0,
      templatedItem: null,
    },
    template: {
      component: OverviewTemplate,
    },
  },
  {
    id: 2,
    pageId: 'challenges',
    titleTkey: 'mainNav.challenges',
    path: '/challenges',
    component: pages.Challenges,
    nav: {
      parent: 0,
      templatedItem: null,
    },
    template: {
      component: InnerTemplateWithHeaderImg,
    },
  },
  {
    id: 3.5,
    pageId: 'all-about-innovation',
    titleTkey: 'mainNav.allAboutInnovation',
    path: '/all-about-innovation',
    component: pages.AllAboutInnovation,
    nav: {
      parent: 0,
      templatedItem: null,
    },
    template: {
      component: InnerTemplateWithHeaderImg,
    },
  },
  {
    id: 3,
    titleTkey: 'Challenge Details',
    path: '/challenge-details/:challengeId',
    component: pages.ChallengeDetails,
  },
  {
    id: 4,
    pageId: 'ideas',
    titleTkey: 'mainNav.searchIdeas',
    path: '/search-ideas',
    component: pages.SearchIdeas,
    nav: {
      parent: 0,
      templatedItem: null,
    },
    template: {
      component: InnerTemplateWithHeaderImg,
    },
  },
  {
    id: 4.5,
    titleTkey: 'Edit Idea',
    path: '/idea-details/:ideaId/edit',
    component: pages.IdeaEdit,
    template: {
      component: InnerTemplate,
    },
  },
  {
    id: 5,
    titleTkey: 'Idea Details',
    path: '/idea-details/:ideaId',
    component: pages.IdeaDetails,
  },
  {
    id: 6,
    pageId: 'about-us',
    titleTkey: 'mainNav.vtfInnovation',
    path: '/vattenfall-innovation',
    component: pages.ContentPage,
    nav: {
      parent: 0,
      templatedItem: null,
    },
    template: {
      component: InnerTemplateWithHeaderImgAndContactSection,
    },
  },
  // {
  //   id: 13,
  //   pageId: 'hall-of-fame',
  //   titleTkey: 'mainNav.vtfHallOfFame',
  //   path: '/hall-of-fame',
  //   component: pages.HallOfFame,
  //   nav: {
  //     parent: 0,
  //     templatedItem: null,
  //   },
  // },
  {
    id: 7,
    pageId: 'terms',
    titleTkey: 'Terms of agreement',
    path: '/terms-of-agreement',
    component: pages.ContentPage,
    template: {
      component: InnerTemplateWithHeaderImg,
    },
  },
  {
    id: 8,
    pageId: 'user',
    titleTkey: 'mainNav.user',
    path: '/user/:userId',
    component: pages.User,
    template: {
      component: InnerTemplateWithHeaderImg,
    },
  },
  {
    id: 9,
    pageId: 'user',
    titleTkey: 'mainNav.user',
    navIcon: AvatarCurrentUser,
    path: '/user',
    component: pages.User,
    nav: {
      parent: 0,
      templatedItem: NotificationBadge,
    },
    template: {
      component: InnerTemplateWithHeaderImg,
    },
  },
  {
    id: 10,
    pageId: 'user-settings',
    titleTkey: 'mainNav.settings',
    path: '/settings',
    component: pages.Settings,
    template: {
      component: InnerTemplateWithHeaderImg,
    },
  },
  {
    id: 11,
    pageId: 'create-idea',
    titleTkey: 'Create Idea',
    path: '/create-idea/:challengeId',
    component: pages.IdeaCreate,
    template: {
      component: InnerTemplateWithHeaderImg,
    },
  },
  {
    path: '/cms/chellenge/create',
    component: cmsPages.CreateChallenge,
  },
  {
    path: '/cms/challenge/:challengeId/update',
    component: cmsPages.EditChallenge,
  },
  // http://localhost:3000/cms/page/about-us/update
  {
    path: '/cms/page/:pageId/update',
    component: cmsPages.EditPage,
  },
  {
    path: '/cms/overviewBox/:overviewBoxId/update',
    component: cmsPages.EditOverviewBox,
  },
  // 404 needs to be last
  {
    id: 12,
    titleTkey: '404 Page',
    component: pages.PageNotFound,
  },
];

/**
 * @param {number} parentId
 */
export const getNavigationItemsByParentId = (parentId = 0) =>
  routes.filter(({ nav }) => nav && nav.parent === parentId);

export const getHomePageConfig = () => routes.find((item) => item.path === '/');

export default routes;
