// @ts-check
import { put, call, takeEvery, select } from "redux-saga/effects";
import * as actionCreators from "../actions/intranetNews";
import api from "../api";
import { getFetchIntranetNewsIfNeeded } from "../../selectors";

function* fetchIntranetNews({ url }) {
  try {
    const fetchNeeded = yield select(getFetchIntranetNewsIfNeeded);
    if (fetchNeeded) {
      const events = yield call(api, url);

      yield put(actionCreators.fetchSucceeded(events));
    }
  } catch (error) {
    console.log("Intranet news error", error);

    yield put(actionCreators.fetchFailed(error));
  }
}

export default function* eventsSaga() {
  yield takeEvery("INTRANET_NEWS/FETCH_PRE_INIT", fetchIntranetNews);
}
