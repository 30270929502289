// @ts-check

import {
  fetchChallengeDetailsIfNeeded,
  fetchChallengesListIfNeeded,
  fetchCommentsListIfNeeded,
  fetchFormIfNeeded,
  fetchIdeaDetailsIfNeeded,
  fetchIdeasListIfNeeded,
  fetchOverviewBoxesIfNeeded,
  fetchPageDetailsIfNeeded,
} from "../selectors";

const dataTypes = {
  challengeDetails: {
    name: "challenge-details",
    payloadFormatter: (payload) => payload,
    allIdsFormatter: () => [],
    fetchIfNeeded: (item) => fetchChallengeDetailsIfNeeded(item),
  },
  ideaDetails: {
    name: "idea-details",
    payloadFormatter: (payload) => payload,
    allIdsFormatter: () => [],
    fetchIfNeeded: (item) => fetchIdeaDetailsIfNeeded(item),
  },
  pageDetails: {
    name: "page-details",
    payloadFormatter: (payload) => payload,
    allIdsFormatter: () => [],
    fetchIfNeeded: (item) => fetchPageDetailsIfNeeded(item),
  },
  forms: {
    name: "forms",
    payloadFormatter: (payload) => payload,
    allIdsFormatter: () => [],
    fetchIfNeeded: (item) => fetchFormIfNeeded(item),
  },
  activities: {
    name: "activities",
    payloadFormatter: (payload) => payload,
    allIdsFormatter: (payload) => Object.keys(payload.items),
    fetchIfNeeded: (context) => () => true, // fetchActivitiesIfNeeded(context)
  },
  challenges: {
    name: "challenges",
    payloadFormatter: (payload) => payload,
    allIdsFormatter: (payload) => Object.keys(payload.items),
    fetchIfNeeded: (context) => fetchChallengesListIfNeeded(context),
  },
  ideas: {
    name: "ideas",
    payloadFormatter: (payload) => payload,
    allIdsFormatter: (payload) => Object.keys(payload.items),
    fetchIfNeeded: (context) => fetchIdeasListIfNeeded(context),
  },
  comments: {
    name: "comments",
    payloadFormatter: (payload) => ({ items: payload.comments }),
    allIdsFormatter: (payload) => Object.keys(payload.comments),
    fetchIfNeeded: (context) => fetchCommentsListIfNeeded(context),
  },
  overviewBoxes: {
    name: "overview-boxes",
    payloadFormatter: (payload) => ({ items: payload.boxes }),
    allIdsFormatter: (payload) => Object.keys(payload.boxes),
    fetchIfNeeded: (context) => fetchOverviewBoxesIfNeeded(context),
  },
};

const objToArr = () => Object.keys(dataTypes).map((key) => dataTypes[key]);

export const getPayloadFormatter = (dataType, payload) =>
  objToArr()
    .find(({ name }) => name === dataType)
    .payloadFormatter(payload);

export const allIdsFormatter = (dataType, payload) =>
  objToArr()
    .find(({ name }) => name === dataType)
    .allIdsFormatter(payload);

export const getFetchIfNeeded = (dataType, item) =>
  objToArr()
    .find(({ name }) => name === dataType)
    .fetchIfNeeded(item);

export default dataTypes;
