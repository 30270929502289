import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import callApi from '../state/api';
import { ScrollableTimeline } from '../Components/Common';
import { getEnvVariable } from '../.env.custom';
import { YEARS_DATA } from './hallOfFameData';
import { Year2017, Year2018, Year2019, Year2020, Year2023, Year2021, OngoingIdeas, Year2022, Year2024 } from './HallOfFameYears';
import CmsTitle from './cmsBlocks/cmsTitle';


const yearComponents = {
  ongoingIdeas: OngoingIdeas,
  2017: Year2017,
  2018: Year2018,
  2019: Year2019,
  2020: Year2020,
  2021: Year2021,
  2022: Year2022,
  2023: Year2023,
  2024: Year2024,
};


function HallOfFamePage({ description, draftId, ...props }) {
  const [page, setPage] = useState({});
  const url = getEnvVariable('REACT_APP_STATIC_HF');
  const [selectedYear, setSelectedYear] = useState(YEARS_DATA.find((year) => year.year === '2024'));

  const SelectedComponent = selectedYear ? yearComponents[selectedYear.year] : null;

  useEffect(() => {
    callApi(url).then((content) => {
      setPage(content);
    });
  }, []);

  const titleBlock = {
    headlineType: 'h1',
    title: selectedYear.title,
    subtitle: selectedYear.subtitle,
  };
  return page.contentBlocks ? (
    <>
      <CmsTitle block={titleBlock} />
      <ScrollableTimeline years={YEARS_DATA} onYearSelect={setSelectedYear} selectedYear={selectedYear} />
      {selectedYear ? (
        <>
          {SelectedComponent && <SelectedComponent />}
        </>
      ) : (
        <div>
          <h2>{selectedYear.year}</h2>
          {selectedYear.contentBlocks?.map((block, index) => (
            <div key={index}>
              {block.type === 'text' ? (
                <p>{block.content}</p>
              ) : block.type === 'image' ? (
                <img src={block.url} alt={block.alt} />
              ) : null}
            </div>
          ))}
        </div>
      )}
    </>
  ) : (
    <div className="vf-container">loading...</div>
  );
}

HallOfFamePage.propTypes = {
  description: PropTypes.string,
  draftId: PropTypes.string,
};

export default HallOfFamePage;
