// @ts-check

export const FETCH_CONTACTS_INIT = 'CONTACTS/FETCH_INIT'
export const requestInit = () => ({
  type: FETCH_CONTACTS_INIT
})

export const FETCH_CONTACTS_PRE_INIT = 'CONTACTS/FETCH_PRE_INIT'
/**
 * @param {string} [url]
 */
export const requestPreInitContacts = (url) => ({
  type: FETCH_CONTACTS_PRE_INIT,
  url
})

export const FETCH_CONTACTS_SUCCEEDED = 'CONTACTS/FETCH_SUCCEEDED'
/**
 * @param {Object} payload
 */
export const fetchSucceeded = (payload) => ({
  type: FETCH_CONTACTS_SUCCEEDED,
  payload
})

export const FETCH_CONTACTS_FAILED = 'CONTACTS/FETCH_FAILED'
/**
 * @param {Object} payload
 */
export const fetchFailed = (payload) => ({
  type: FETCH_CONTACTS_FAILED,
  payload
})

// @ts-check

export const CREATE_CONTACT_INIT = 'CONTACT/CREATE_CONTACT_INIT'
export const createContactInit = () => {
  return {
    type: CREATE_CONTACT_INIT
  }
}

export const CREATE_CONTACT_PRE_INIT = 'CONTACT/CREATE_CONTACT_PRE_INIT'
/**
 * @param {string} url
 * @param {Object} payload
 */
export const createContactPreInit = (url, payload) => {
  return {
    type: CREATE_CONTACT_PRE_INIT,
    url,
    payload
  }
}

export const CREATE_CONTACT_SUCCESS = 'CONTACT/CREATE_CONTACT_SUCCESS'
/**
 * @param {Object} payload
 */
export const createContactSuccess = (payload) => {
  return {
    type: CREATE_CONTACT_SUCCESS,
    payload
  }
}

export const CREATE_CONTACT_FAILED = 'CONTACT/CREATE_CONTACT_FAILED'
/**
 * @param {Object} payload
 */
export const createContactFailed = (payload) => {
  return {
    type: CREATE_CONTACT_FAILED,
    payload
  }
}

export const UPDATE_CONTACT_INIT = 'CONTACT/UPDATE_CONTACT_INIT'
export const updateContactInit = () => {
  return {
    type: UPDATE_CONTACT_INIT
  }
}

export const UPDATE_CONTACT_PRE_INIT = 'CONTACT/UPDATE_CONTACT_PRE_INIT'
/**
 * @param {string} url
 * @param {Object} payload
 */
export const updateContactPreInit = (url, payload, lang) => {
  return {
    type: UPDATE_CONTACT_PRE_INIT,
    url,
    payload,
    lang
  }
}

export const UPDATE_CONTACT_SUCCESS = 'CONTACT/UPDATE_CONTACT_SUCCESS'
/**
 * @param {Object} payload
 */
export const updateContactSuccess = (payload) => {
  return {
    type: UPDATE_CONTACT_SUCCESS,
    payload
  }
}

export const UPDATE_CONTACT_FAILED = 'CONTACT/UPDATE_CONTACT_FAILED'
/**
 * @param {Object} payload
 */
export const updateContactFailed = (payload) => {
  return {
    type: UPDATE_CONTACT_FAILED,
    payload
  }
}

export const REMOVE_CONTACT_INIT = 'CONTACT/REMOVE_CONTACT_INIT'
export const removeContactInit = () => {
  return {
    type: REMOVE_CONTACT_INIT
  }
}

export const REMOVE_CONTACT_PRE_INIT = 'CONTACT/REMOVE_CONTACT_PRE_INIT'
/**
 * @param {string} url
 * @param {Object} contactId
 */
export const removeContactPreInit = (url, contactId, lang) => {
  return {
    type: REMOVE_CONTACT_PRE_INIT,
    url,
    contactId,
    lang
  }
}

export const REMOVE_CONTACT_SUCCESS = 'CONTACT/REMOVE_CONTACT_SUCCESS'
/**
 * @param {Object} payload
 */
export const removeContactSuccess = (payload) => {
  return {
    type: REMOVE_CONTACT_SUCCESS,
    payload
  }
}

export const REMOVE_CONTACT_FAILED = 'CONTACT/REMOVE_CONTACT_FAILED'
/**
 * @param {Object} payload
 */
export const removeContactFailed = (payload) => {
  return {
    type: REMOVE_CONTACT_FAILED,
    payload
  }
}

export const actionTypes = {
  CREATE_CONTACT_FAILED,
  CREATE_CONTACT_INIT,
  CREATE_CONTACT_PRE_INIT,
  CREATE_CONTACT_SUCCESS,
  FETCH_CONTACTS_FAILED,
  FETCH_CONTACTS_INIT,
  FETCH_CONTACTS_PRE_INIT,
  FETCH_CONTACTS_SUCCEEDED,
  REMOVE_CONTACT_FAILED,
  REMOVE_CONTACT_INIT,
  REMOVE_CONTACT_PRE_INIT,
  REMOVE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILED,
  UPDATE_CONTACT_INIT,
  UPDATE_CONTACT_PRE_INIT,
  UPDATE_CONTACT_SUCCESS
}

const actionCreators = {
  createContactFailed,
  createContactInit,
  createContactPreInit,
  createContactSuccess,
  fetchFailed,
  fetchSucceeded,
  removeContactFailed,
  removeContactInit,
  removeContactPreInit,
  removeContactSuccess,
  requestInit,
  requestPreInitContacts,
  updateContactFailed,
  updateContactInit,
  updateContactPreInit,
  updateContactSuccess
}

export default actionCreators
