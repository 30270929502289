import React from 'react'

import ChallengesList from '../Components/Challenges'
import { VfLinkButton } from '../Components/DesignSystem/Buttons'
import { useProfile } from '../hooks'

const OurChallenges = () => {
  const { user } = useProfile()
  const isAdmin = !!user?.administrator;

  return (
    <>
      {isAdmin && (
        <div className='mb-2'>
          <VfLinkButton
            to='/cms/chellenge/create'
            text='Add New Challenge'
            color='secondary'
            className='mr-1'
            style={{ minWidth: 'auto' }}
          />
        </div>
      )}
      <ChallengesList state='ACTIVE,PUBLISHED' />

      <ChallengesList state='CLOSED' titleKey='titles.completedChallenges' />

      {isAdmin && (
        <ChallengesList state='DRAFT' titleKey='titles.draftChallenges' />
      )}
    </>
  )
}

export default OurChallenges
