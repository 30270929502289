import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Details from './Details'
import Modal from '../DesignSystem/Modal'
import { VfButton } from '../DesignSystem/Buttons'

import {
  getChallengeDetailsById,
  getChallengeDetailsUrl
} from '../../selectors'
import actions from '../../state/actions/data'
import { dataTypes } from '../../configurations'
import { useData, useLanguage, useProfile } from '../../hooks'

import ContextMenu from '../ctas/items'
import { ctas } from '../../configurations/ctas'
import { Message } from '../Common'

const DetailsContainer = ({ challengeId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const lng = useLanguage()
  const params = { lng, challengeId }
  const { data: item } = useData(
    dataTypes.challengeDetails.name,
    params,
    getChallengeDetailsUrl,
    getChallengeDetailsById,
    actions.requestPreInit
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [message, setMessage] = useState(null)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const { user } = useProfile()
  const role = !!user?.administrator ? 'ADMIN' : 'USER'

  const handleActivate = () => updateChallenge('activate')
  const handleClose = () => updateChallenge('close')
  const handleOnHold = () => updateChallenge('depublish')
  const handleDelete = () => openModal()

  const ctaHandlers = {
    handleDelete,
    handleActivate,
    handleClose,
    handleOnHold
  }

  const updateChallenge = (
    hrefKey,
    callback = () => {
      setProcessing(false)
      closeModal()
    }
  ) => {
    setProcessing(true)
    const selfUrl = item._links.self.href
    const dataToInvalidate = [
      { dataType: 'challenges' },
      { dataType: 'challenge-details', context: selfUrl }
    ]

    const url = item._links[hrefKey]?.href || ''
    if (!url) {
      console.error('No url provided for given action')
      closeModal()
      setMessage({ text: 'No-URL Error occured.', type: 'error' })
      return
    }
    const action = actions.createDataPreInit(
      url,
      {},
      'challenge-details',
      dataToInvalidate,
      callback,
      selfUrl
    )

    dispatch(action)
  }

  const removeChallenge = () => {
    const selfUrl = item._links.self.href
    const dataToInvalidate = [
      { dataType: 'challenges' },
      { dataType: 'challenge-details', context: selfUrl }
    ]
    const callback = () => {
      closeModal()
      setMessage({
        text: 'Success! Challenge has been DELETED.',
        type: 'success'
      })
    }
    const url = item._links.delete?.href || ''
    if (!url) {
      console.error('No url provided for given action')
      closeModal()
      setMessage({ text: 'No-URL Error occured.', type: 'error' })
      return
    }

    const action = actions.deleteDataPreInit(
      url,
      null,
      'challenge-details',
      dataToInvalidate,
      null,
      callback
    )

    dispatch(action)
  }

  if (!!item) {
    const itemId = item.draftVersionId || item.id
    const ctasByState = ctas(itemId, ctaHandlers)[item.state]
    if (!ctasByState) {
      console.error('Unknown state', item.state)
      return null
    }
    const CtasList = !!ctasByState ? ctasByState[role] : []
    const ContextMenuSection = !!CtasList.length ? (
      <>
        <h3>Item state</h3>
        <p>{item.state}</p>
        <h3>Admin Actions</h3>
        <ContextMenu>{CtasList}</ContextMenu>
      </>
    ) : null

    return (
      <>
        {!!message ? (
          <div className='vf-container'>
            <Message type={message.type}>{message.text}</Message>
          </div>
        ) : (
          <Details challenge={item} ContextMenuSection={ContextMenuSection} />
        )}
        <Modal hidden={!modalOpen} closeModal={closeModal}>
          <>
            <p>{t('common.deleteConfirm')}</p>

            <div className='vf-row mb-2'>
              <VfButton
                text={t('common.no')}
                color='secondary'
                disabled={processing}
                onClick={closeModal}
                className='vf-col m-1'
              />

              <VfButton
                text={processing ? t('common.deleting') : t('common.yes')}
                disabled={processing}
                color='primary'
                onClick={removeChallenge}
                className='vf-col m-1'
              />
            </div>
          </>
        </Modal>
      </>
    )
  }

  return null
}

DetailsContainer.propTypes = {
  challengeId: PropTypes.string
}

export default DetailsContainer
