// @ts-check

export const FETCH_USER_INIT = 'USERS/FETCH_INIT'
export const requestInit = () => ({
  type: FETCH_USER_INIT
})

export const FETCH_USER_PRE_INIT = 'USERS/FETCH_PRE_INIT'
/**
 * @param {string} url
 * @param {string} id
 * @param {function} [callback]
 */
export const requestPreInit = (url, id, callback) => {
  return {
    type: FETCH_USER_PRE_INIT,
    url,
    id,
    callback
  }
}

export const FETCH_USER_SUCCEEDED = 'USERS/FETCH_SUCCEEDED'
/**
 * @param {Object} payload
 */
export const fetchSucceeded = payload => {
  return {
    type: FETCH_USER_SUCCEEDED,
    payload: { [payload.id]: payload },
    allIds: [payload.id]
  }
}

export const FETCH_USER_FAILED = 'USERS/FETCH_FAILED'
/**
 * @param {Object} payload
 */
export const fetchFailed = payload => ({
  type: FETCH_USER_FAILED,
  payload
})

export const actionTypes = {
  FETCH_USER_PRE_INIT,
  FETCH_USER_INIT,
  FETCH_USER_SUCCEEDED,
  FETCH_USER_FAILED
}

const actionCreators = {
  requestPreInit,
  requestInit,
  fetchSucceeded,
  fetchFailed
}

export default actionCreators
