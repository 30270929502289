// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { VfButton } from '../../DesignSystem/Buttons'
import { SubmitButton } from '../../Common'
import { useTranslation } from 'react-i18next'

const CommentForm = ({
  className = '',
  handleSubmit,
  handleCancelClick,
  initialValues
}) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    content: yup.string().required(t('forms.comment.required'))
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty }) => {
        return (
          <Form className={`mt-2 ${className}`}>
            <Field
              name='content'
              render={({ field }) => (
                <>
                  <textarea
                    {...field}
                    name='content'
                    id='content'
                    rows={4}
                    placeholder={`${t('forms.comment.comment')} *`}
                  />
                  <ErrorMessage name='content' component='p' />
                </>
              )}
            />

            <SubmitButton
              button={
                <VfButton className='mt-2' text={t('forms.comment.button')} />
              }
              inProgress={isSubmitting}
              inProgressText={t('common.submitting')}
              disabled={!dirty || isSubmitting}
            />
            {!!handleCancelClick && (
              <VfButton
                color='secondary'
                className='mt-2'
                text={t('common.cancel')}
                onClick={handleCancelClick}
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

CommentForm.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancelClick: PropTypes.func,
  initialValues: PropTypes.object
}

export default CommentForm
