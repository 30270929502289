// @ts-check
import { put, call, takeEvery, select } from 'redux-saga/effects'
import actionCreators from '../actions/contacts'
import { getFetchContactsIfNeeded } from '../../selectors'
import api, { apiDelete, apiPost, apiPut } from '../api'

function * fetchContacts({ url }) {
  try {
    const fetchNeeded = yield select(getFetchContactsIfNeeded)
    if (fetchNeeded) {
      const contacts = yield call(api, url)

      yield put(actionCreators.fetchSucceeded(contacts))
    }
  } catch (error) {
    console.log('contacts error', error)

    yield put(actionCreators.fetchFailed(error))
  }
}

function * createContact({
  url,
  payload,
}) {
  try {
    yield put(actionCreators.createContactInit())

    const response = yield call(api, url, apiPost(payload))

    if (response) {
      yield put(actionCreators.createContactSuccess(response))
      yield put(actionCreators.requestPreInitContacts(url))
    }
  } catch (err) {
    yield put(actionCreators.creatContactFailed(err))
    console.log('create contact error', err)
  }
}

function * updateContact({
  url,
  payload,
  lang,
}) {
  try {
    yield put(actionCreators.updateContactInit())

    const response = yield call(
      api,
      `${url}/${payload.id}?lng=${lang}`,
      apiPut(payload),
    )

    if (response) {
      yield put(actionCreators.updateContactSuccess(response))
      yield put(actionCreators.requestPreInitContacts(`${url}?lng=${lang}`))
    }
  } catch (err) {
    yield put(actionCreators.updateContactFailed(err))
    console.log('update contact error', err)
  }
}

function * removeContact({
  url,
  contactId,
  lang,
}) {
  try {
    yield put(actionCreators.removeContactInit())

    const response = yield call(api, `${url}/${contactId}`, apiDelete())

    if (response) {
      yield put(actionCreators.removeContactSuccess(response))
      yield put(actionCreators.requestPreInitContacts(`${url}?lng=${lang}`))
    }
  } catch (err) {
    yield put(actionCreators.removeContactFailed(err))
    console.log('remove contact error', err)
  }
}

export default function * contactsSaga() {
  yield takeEvery('CONTACTS/FETCH_PRE_INIT', fetchContacts)
  yield takeEvery('CONTACT/CREATE_CONTACT_PRE_INIT', createContact)
  yield takeEvery('CONTACT/UPDATE_CONTACT_PRE_INIT', updateContact)
  yield takeEvery('CONTACT/REMOVE_CONTACT_PRE_INIT', removeContact)
}
