// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field, Form } from 'formik'
import { useSelector } from 'react-redux'

import { useVfAlchemyScript } from '../../../hooks'
import {
  TextArea,
  TextInput,
  DropDown,
  MSGraphSearch,
  SubmitButton,
} from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import { ImageInput } from '../../Common/Attachments'
import { getUrlFromConfig } from '../../../selectors'

export const languages = [
  {
    label: 'English',
    value: 'EN',
  },
  {
    label: 'German',
    value: 'DE',
  },
  {
    label: 'Dutch',
    value: 'NL',
  },
  {
    label: 'Swedish',
    value: 'SV',
  },
]

const FormContainer = ({
  initialValues,
  validationSchema,
  handleSubmit,
  handleDraftSubmit,
  ...parentProps
}) => {
  useVfAlchemyScript()
  const profileSearchUrl = useSelector(getUrlFromConfig('profile-search'))

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}>
      {formikProps => {
        return (
          <FormComponent
            handleDraftSubmit={handleDraftSubmit}
            profileSearchUrl={profileSearchUrl}
            {...formikProps}
            {...parentProps}
          />
        )
      }}
    </Formik>
  )
}

FormContainer.propTypes = {
  initialValues: PropTypes.object,
  validationSchema: PropTypes.any,
  handleSubmit: PropTypes.func,
  handleDraftSubmit: PropTypes.func,
  handleCountryChange: PropTypes.func,
  showLanguageDdl: PropTypes.bool,
}

const FormComponent = ({
  handleDraftSubmit,
  handleCountryChange,
  showLanguageDdl,
  isValid,
  profileSearchUrl,
  inProgress,
  ...props
}) => {
  return (
    <Form>
      {showLanguageDdl && (
        <div style={{ width: '250px' }}>
          <Field
            name="language"
            label="Language"
            component={DropDown}
            onChange={handleCountryChange}
            options={languages.map(item => ({
              ...item,
              label: item.label,
            }))}
          />
        </div>
      )}

      <Field name="title" label="Title" component={TextInput} />
      <Field name="subTitle" label="Subtitle" component={TextInput} />
      <Field name="tab1" label="Tab1 (Description)" component={TextArea} />
      <Field
        name="tab2"
        label="Tab2 (What we are looking for | optional)"
        component={TextArea}
      />
      <Field
        name="smallImage"
        label="Small Image (360px x 165px)"
        imageSize={{
          w: 360,
          h: 165,
        }}
        component={ImageInput}
      />

      <Field
        name="largeImage"
        label="Large Image (1280px x 340px)"
        imageSize={{
          w: 1280,
          h: 340,
        }}
        component={ImageInput}
      />
      <Field
        name="videoFrame"
        label="Video embed code"
        component={TextArea}
      />
      <div style={{ width: '250px' }}>
        <Field
          name="order"
          label="Tile order on the list (optional)"
          component={TextInput}
        />
      </div>
      <Field
        name="author"
        label="Author"
        component={MSGraphSearch}
        rawUrl={profileSearchUrl}
        isMulti={false}
      />

      {!isValid && (
        <p className="vf-utility-typo--error mt-3">
          One or more fields have an error. Please check and try again.
        </p>
      )}
      <div className="vf-row">
        <div className="mt-3 ml-auto">
          <SubmitButton
            button={
              <VfButton
                text="Keep it as Draft"
                color="secondary"
                onClick={() => handleDraftSubmit(props)}
              />
            }
            inProgress={inProgress}
            disabled={inProgress}
          />{' '}
          <SubmitButton
            button={<VfButton text="Save and publish" type="submit" />}
            inProgress={inProgress}
            disabled={inProgress}
          />
        </div>
      </div>
    </Form>
  )
}

FormComponent.propTypes = {
  handleDraftSubmit: PropTypes.func,
  handleCountryChange: PropTypes.func,
  validateForm: PropTypes.func,
  values: PropTypes.any,
  setFieldTouched: PropTypes.func,
  setFieldError: PropTypes.func,
  showLanguageDdl: PropTypes.bool,
  isValid: PropTypes.bool,
  profileSearchUrl: PropTypes.string,
  inProgress: PropTypes.bool,
}

export default FormContainer
