/**
 * test the solution
 * in VCS terminal run: ($env:REACT_APP_CUSTOM_ENV = "acc") -and (npm start)
 *
 * remember
 * to set in azure-pipelines.yml value for: REACT_APP_CUSTOM_ENV
 */

const envVariables = {
  dev: {
    REACT_APP_API_ROOT: 'https://smip-web-dev.azurewebsites.net/iportal-api/',
    REACT_APP_CMS_ROOT: 'https://smip-cms-api-dev.azurewebsites.net/',
    REACT_APP_I18_TRANSLATIONS:
      'https://smipi18translationsdev.blob.core.windows.net/translations/',
    REACT_APP_FILES_UPLOADER:
      'https://smip-web-dev.azurewebsites.net/iportal-api/idea-documents/upload',
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      '2828c76b-a0ea-4f72-a8f6-d4c649b0cc0c',
    REACT_APP_STATIC_HF:
      'https://smip-web-fe-dev.azurewebsites.net/hf/hf-dev.json',
  },
  tst: {
    REACT_APP_API_ROOT: 'https://smip-web-tst.azurewebsites.net/iportal-api/',
    REACT_APP_CMS_ROOT: 'https://smip-cms-api-tst.azurewebsites.net/',
    REACT_APP_I18_TRANSLATIONS:
      'https://smipi18translationsdev.blob.core.windows.net/translations/',
    REACT_APP_FILES_UPLOADER:
      'https://smip-web-tst.azurewebsites.net/iportal-api/idea-documents/upload',
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      '310bdddb-946f-4810-a750-526262e8bb4b',
    REACT_APP_STATIC_HF:
      'https://smip-web-fe-tst.azurewebsites.net/hf/hf-tst.json',
  },
  prd: {
    REACT_APP_API_ROOT: 'https://smip-web-prd.azurewebsites.net/iportal-api/',
    REACT_APP_CMS_ROOT: 'https://smip-cms-api-prd.azurewebsites.net/',
    REACT_APP_I18_TRANSLATIONS:
      'https://smipi18translationsprd.blob.core.windows.net/translations/',
    REACT_APP_FILES_UPLOADER:
      'https://smip-web-prd.azurewebsites.net/iportal-api/idea-documents/upload',
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      'c8727063-2e78-4115-81d1-cedf5f7945da',
    REACT_APP_STATIC_HF:
      'https://innovation-portal.vattenfall.com/hf/hf-prd.json',
  },
};

export const getEnvVariable = (variable) => {
  const customEnv = process.env.REACT_APP_CUSTOM_ENV;

  try {
    return !!customEnv
      ? envVariables[customEnv][variable]
      : process.env[variable];
  } catch (err) {
    console.error(
      'Cannot get requested ENV VARIABLE, env:',
      customEnv,
      'var:',
      variable
    );

    return '';
  }
};
