import React, { useState } from 'react'
import PropTypes from 'prop-types'
import '../../Ideas/Details/TabContent.scss'

const TabBarTabItem = ({
  index,
  title,
  handleClick,
  isActive
}) => {
  const className = `vf-tab-bar-item ${
    isActive ? 'vf-tab-bar-item--active' : ''
  }`
  return (
    <li
      id={`vf-tab-${index}`}
      className={className}
      role='tab'
      aria-controls={`vf-panel-${index}`}
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      onClick={handleClick}>
      <div className='vf-tab-bar-link vf-icon-idea-details'>{title}</div>
    </li>
  )
}
TabBarTabItem.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  handleClick: PropTypes.func,
  isActive: PropTypes.bool
}

const TabBarContentItem = ({
  index,
  content,
  isActive
}) => {
  const hidden = isActive ? {} : { hidden: true }

  return (
    <div
      id={`vf-panel-${index}`}
      className='vf-panel'
      role='tabpanel'
      tabIndex={index}
      aria-labelledby={`vf-tab-${index}`}
      {...hidden}
    >
      <>{content}</>
    </div>
  )
}

TabBarContentItem.propTypes = {
  index: PropTypes.number,
  content: PropTypes.any,
  isActive: PropTypes.bool
}

const TabBar = ({
  tabs,
  headers,
  infoText = false
}) => {
  const cleanTabs = tabs.filter(item => item)
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className='vf-tab-bar-container'>
      <ul className='vf-tab-bar' aria-label='Tab bar' role='tablist'>
        {cleanTabs.map((item, idx) => {
          return (
            <TabBarTabItem
              key={idx}
              index={idx}
              title={headers[idx]}
              handleClick={() => setActiveTab(idx)}
              isActive={activeTab === idx}
            />
          )
        })}
      </ul>
      {infoText && (
        <p className='mt-4'>
          {infoText}
        </p>
      )}
      <div className='vf-tab-bar-panels'>
        {cleanTabs.map((item, idx) => {
          return (
            <TabBarContentItem
              key={idx}
              index={idx}
              content={item}
              isActive={activeTab === idx}
            />
          )
        })}
      </div>
    </div>
  )
}

TabBar.defaultProps = {
  tabs: []
}

TabBar.propTypes = {
  // own props
  tabs: PropTypes.array,
  headers: PropTypes.array,
  infoText: PropTypes.string
}

export default TabBar
