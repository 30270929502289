import { useEffect } from 'react'

const useDropDownFix = (parentSelector = 'form') => {
  const isOpen = el => el.classList.contains('on')

  const closeDropdown = dropDownEl => {
    dropDownEl
      .querySelector('.vf-dropdown-label')
      .dispatchEvent(new Event('click'))
  }

  useEffect(() => {
    const parentNode = document.querySelector(parentSelector)
    const dropDowns = document.querySelectorAll('.vf-dropdown')

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const mutationTarget = mutation.target
        const { classList } = mutationTarget

        if (classList.contains('vf-dropdown') && classList.contains('on')) {
          const otherDropDowns = [...dropDowns].filter(
            el => el !== mutationTarget
          )
          otherDropDowns.forEach(el => isOpen(el) && closeDropdown(el))
        }
      })
    })

    observer.observe(parentNode, {
      attributes: true,
      attributeFilter: ['class'],
      subtree: true
    })

    return () => {
      observer.disconnect()
    }
  }, [parentSelector])

  useEffect(() => {
    const closeAllDropdowns = event => {
      const dropDowns = document.querySelectorAll('.vf-dropdown')

      const clickedOutsideAnyDropdown = ![...dropDowns].find(el =>
        el.contains(event.target)
      )

      dropDowns.forEach(el => {
        if (clickedOutsideAnyDropdown && isOpen(el)) {
          closeDropdown(el)
        }
      })
    }

    document.addEventListener('click', closeAllDropdowns)

    return () => {
      document.addEventListener('click', closeAllDropdowns)
    }
  }, [])
}

export default useDropDownFix
