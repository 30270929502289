// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useProfile } from '../../hooks'
import { VfLinkButton } from '../DesignSystem/Buttons'
import { Loading } from '../Common'

const Settings = ({
  user: { subscriptionsConfiguration, notificationsConfiguration }
}) => {
  const { t } = useTranslation()
  const activeSubscriptions = subscriptionsConfiguration.filter(
    sub => sub.activeSubscription
  )

  const activeNotifications = notificationsConfiguration
    ? Object.keys(notificationsConfiguration).filter(
      notification => notificationsConfiguration[notification]
    )
    : []

  return (
    <>
      <h2>{t('titles.settings')}</h2>

      <h4>{t('titles.subscriptions')}</h4>

      <ul className='vf-ul pl-3'>
        {activeSubscriptions.length ? (
          activeSubscriptions.map((sub, idx) => (
            <li key={idx}>{sub.challangeTitle}</li>
          ))
        ) : (
          <p>{t('user-page.noSubscriptions')}</p>
        )}
      </ul>

      <h4>{t('titles.myNotifications')}</h4>

      <ul className='vf-ul pl-3'>
        {activeNotifications.length ? (
          activeNotifications.map((notification, idx) => (
            <li key={idx}>{t(`forms.editUser.group2.${notification}`)}</li>
          ))
        ) : (
          <p>{t('user-page.noNotifications')}</p>
        )}
      </ul>

      <VfLinkButton
        to='/settings'
        color='secondary'
        text={t('common.editSettings')}
      />
    </>
  )
}

Settings.propTypes = {
  user: PropTypes.object
}

const SettingsContainer = ({ userId }) => {
  const { user, isFetching } = useProfile(userId)

  if (!!user) {
    return <Settings user={user} />
  } else if (isFetching) {
    return <Loading />
  }
  return null
}

SettingsContainer.propTypes = {
  userId: PropTypes.string
}

export default SettingsContainer
