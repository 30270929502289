// @ts-check

import React from 'react';
import { EditForm } from '../Components/Ideas';
import { useParams } from 'react-router-dom';

function IdeaEditPage() {
  const { ideaId } = useParams();

  return <EditForm ideaId={ideaId} />;
}

export default IdeaEditPage;
