// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import NavSwitch from './NavSwitch'
import Navigation from './Navigation'
import logo from '../../assets/images/vf-logo.svg'
import './header.scss'

const Header = ({ pageId }) => (
  <header>
    <NavSwitch />
    <Link to='/' className='main-header__logo'>
      <img src={logo} alt='Logo' />
    </Link>
    <Navigation pageId={pageId} />
  </header>
)

Header.propTypes = {
  // own props
  pageId: PropTypes.number
}

export default Header
