// @ts-check
import { combineReducers } from 'redux'
import { actionTypes } from '../actions/data'
import { dataTypes } from '../../configurations'

const getDetails = dataType => {
  const payload = (state = {}, action) => {
    if (action.dataType !== dataType) {
      return state
    }
    switch (action.type) {
      case actionTypes.FETCH_DATA_SUCCEEDED:
        return { ...state, [action.context]: action.payload }
      case actionTypes.DELETE_DATA_SUCCEEDED:
        return {}
      default:
        return state
    }
  }

  const all = (state = [], action) => {
    if (action.dataType !== dataType) {
      return state
    }
    switch (action.type) {
      case actionTypes.FETCH_DATA_SUCCEEDED:
        return [...state, action.context]
      case actionTypes.INVALIDATE_DATA:
        return state.filter(item => item !== action.context)
      default:
        return state
    }
  }

  const isFetching = (state = false, action) => {
    if (action.dataType !== dataType) {
      return state
    }
    switch (action.type) {
      case actionTypes.FETCH_DATA_INIT:
        return true
      case actionTypes.FETCH_DATA_SUCCEEDED:
      case actionTypes.FETCH_DATA_FAILED:
        return false

      default:
        return state
    }
  }

  return combineReducers({ payload, all, isFetching })
}

const allReducers = {
  [dataTypes.challengeDetails.name]: getDetails(
    dataTypes.challengeDetails.name
  ),
  [dataTypes.ideaDetails.name]: getDetails(dataTypes.ideaDetails.name),
  [dataTypes.pageDetails.name]: getDetails(dataTypes.pageDetails.name),
  [dataTypes.forms.name]: getDetails(dataTypes.forms.name)
}

export default combineReducers(allReducers)
