// @ts-check

import readActionCreators, { actionTypes as readActionTypes } from './read'
import createActionCreators, {
  actionTypes as createActionTypes
} from './create'
import deleteActionCreators, { actionTypes as deleteActionTypes } from './delete'
import updateActionCreators, {
  actionTypes as updateActionTypes
} from './update'

export const actionTypes = {
  ...readActionTypes,
  ...createActionTypes,
  ...deleteActionTypes,
  ...updateActionTypes
}

export default {
  ...readActionCreators,
  ...createActionCreators,
  ...deleteActionCreators,
  ...updateActionCreators
}
