// @ts-check
import React from 'react';
import { ChallengeDetails } from '../Components/Challenges';
import { useParams } from 'react-router-dom';

function ChallengeDetailsPage() {
  const { challengeId } = useParams();

  return <ChallengeDetails challengeId={challengeId} />;
}

export default ChallengeDetailsPage;
