// @ts-check

import React from "react";
import PropTypes from "prop-types";
import VfHero from "../DesignSystem/Hero";
import { VfContainer } from "../DesignSystem/Utils";
import LinkToEditPage from "../cms/page/LinkToEditPage";
import Contacts from '../Contacts'

const InnerTemplate = ({ title, children, ...other }) => {
  const { draftId, description } = children.props;

  return (
    <>
      <VfContainer>
        <LinkToEditPage pageId={draftId} />
        {!!description ? (
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="my-4"
          />
        ) : null}
        {children}
      </VfContainer>
    </>
  );
};

InnerTemplate.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

InnerTemplate.defaultProps = {
  title: "",
  heroImg: "",
};

export const InnerTemplateWithHeaderImg = ({ heroImg, ...other }) => {
  return (
    <>
      <VfHero imageUrl={heroImg} headline={other.title} />
      <InnerTemplate {...other} />
    </>
  );
};

InnerTemplateWithHeaderImg.propTypes = {
  heroImg: PropTypes.string,
};

export const InnerTemplateWithHeaderImgAndContactSection = ({
  heroImg,
  ...other
}) => {
  return (
    <>
      <VfHero imageUrl={heroImg} headline={other.title} />
      <InnerTemplate {...other} />
      <VfContainer>
        <div className="vf-row mt-2">
          <div className="vf-col-lg-12 vf-col-md-12 vf-col-sm-12 mt-2">
            <Contacts title="Meet the Team" />
          </div>
        </div>
      </VfContainer>
    </>
  )
}

InnerTemplateWithHeaderImgAndContactSection.propTypes = {
  heroImg: PropTypes.string,
}

InnerTemplateWithHeaderImgAndContactSection.defaultProps = {
  heroImg: '',
}


export default InnerTemplate
