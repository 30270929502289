// @ts-check
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import IdeaItemTemplate from './ItemTemplates'
import actions from '../../state/actions/data'
import {
  getIdeasListUrl,
  getIdeasList,
  getIsIdeasFetching,
  getIdeasPagePrevUrl,
  getIdeasPageNextUrl,
  getIdeasPaginationSetup,
  getIdeasAdditionalInfo
} from '../../selectors'
import { useData, useLanguage } from '../../hooks'
import { Loading, Pagination } from '../Common'
import Header from './Header'

import './ideas.scss'

const List = ({ items, layoutType, sortedByTrendiest }) =>
  items.map(item => (
    <IdeaItemTemplate
      key={item.id}
      type={layoutType}
      item={item}
      showViews={sortedByTrendiest}
    />
  ))

const ListContainer = ({
  urlParams,
  layoutType,
  setLayoutType,
  sortFunc,
  limit,
  showHeader,
  setIsSearching,
  filtered,
  sortedByTrendiest,
  showTopPagination,
  showBottomPagination
}) => {
  const { t } = useTranslation()
  const lng = useLanguage()
  const [pageUrl, setPageUrl] = useState(null)
  const { data: items, url, isFetching } = useData(
    'ideas',
    { ...urlParams, lng },
    getIdeasListUrl,
    getIdeasList,
    actions.requestPreInit,
    getIsIdeasFetching,
    pageUrl
  )
  const sortedItems = items.sort(sortFunc)
  const finalItems = limit ? sortedItems.slice(0, limit) : sortedItems

  const prevUrl = useSelector(getIdeasPagePrevUrl(url))
  const nextUrl = useSelector(getIdeasPageNextUrl(url))
  const paginationSetup = useSelector(getIdeasPaginationSetup(url))

  const ideasInfo = useSelector(getIdeasAdditionalInfo(url))

  const handlePageClick = url => setPageUrl(url)

  useEffect(() => {
    setPageUrl(null)
  }, [urlParams])

  useEffect(() => {
    setIsSearching(isFetching)
  }, [isFetching, setIsSearching])

  if (!finalItems.length && !isFetching) {
    return <p>{t('common.sorryNoItems')}</p>
  } else if (isFetching) {
    return <Loading />
  }
  return (
    <>
      {showHeader && (
        <Header
          changeLayout={setLayoutType}
          activeLayout={layoutType}
          ideasInfo={ideasInfo}
          filtered={filtered}
        />
      )}

      {showTopPagination && (
        <div className='vf-row'>
          <Pagination
            totalPages={paginationSetup.totalPages}
            currentPage={paginationSetup.currentPage + 1}
            isFirst={!prevUrl}
            isLast={!nextUrl}
            handlePrevClick={() => handlePageClick(prevUrl)}
            handleNextClick={() => handlePageClick(nextUrl)}
          />
        </div>
      )}

      <div className='vf-row'>
        <List
          items={finalItems}
          layoutType={layoutType}
          sortedByTrendiest={sortedByTrendiest}
        />
      </div>

      {showBottomPagination && (
        <div className='vf-row'>
          <Pagination
            totalPages={paginationSetup.totalPages}
            currentPage={paginationSetup.currentPage + 1}
            isFirst={!prevUrl}
            isLast={!nextUrl}
            handlePrevClick={() => handlePageClick(prevUrl)}
            handleNextClick={() => handlePageClick(nextUrl)}
          />
        </div>
      )}
    </>
  )
}

ListContainer.propTypes = {
  urlParams: PropTypes.object,
  layoutType: PropTypes.string,
  setLayoutType: PropTypes.func,
  limit: PropTypes.number,
  sortFunc: PropTypes.func,
  showHeader: PropTypes.bool,
  setIsSearching: PropTypes.func,
  filtered: PropTypes.bool,
  sortedByTrendiest: PropTypes.bool,
  showTopPagination: PropTypes.bool,
  showBottomPagination: PropTypes.bool
}

ListContainer.defaultProps = {
  urlParams: {},
  limit: null,
  sortFunc: () => {},
  setLayoutType: () => {},
  showHeader: false,
  setIsSearching: () => {},
  filtered: false,
  sortedByTrendiest: false,
  showTopPagination: false,
  showBottomPagination: false
}

export default ListContainer
