export const challengeTemplate = {
  '00128bcc-10c2-4ef4-87d2-1247e3e3067c': {
    challengeId: '00128bcc-10c2-4ef4-87d2-1247e3e3067c',
    theme: 'darkGreen'
  },
  '0649b376-36c0-48bd-9747-7078765e45bb': {
    challengeId: '0649b376-36c0-48bd-9747-7078765e45bb',
    theme: 'red'
  },
  '4d8acb36-779e-4faa-84c7-07804298d280': {
    challengeId: '4d8acb36-779e-4faa-84c7-07804298d280',
    theme: 'green'
  },
  '670c36ba-0129-4791-90f0-f36079b5a19e': {
    challengeId: '670c36ba-0129-4791-90f0-f36079b5a19e',
    theme: 'darkPurple'
  },
  '9ac16160-9ba7-4f31-af13-eb94cf833116': {
    challengeId: '9ac16160-9ba7-4f31-af13-eb94cf833116',
    theme: 'darkBlue'
  },
  'af6cc3de-34b2-4b28-9396-63a2e271a1eb': {
    challengeId: 'af6cc3de-34b2-4b28-9396-63a2e271a1eb',
    theme: 'purple'
  },
  'ff4787b4-8126-49cc-a7ee-a7d0f505b3ce': {
    challengeId: 'ff4787b4-8126-49cc-a7ee-a7d0f505b3ce',
    theme: 'pink'
  }
}

export const themes = {
  darkGreen: {
    mainColor: 'rgba(0, 92, 99, .1)',
    bar: 'rgba(0, 92, 99, .2)'
  },
  darkBlue: {
    mainColor: 'rgba( 30, 50, 79, .1)',
    bar: 'rgba( 30, 50, 79, .2)'
  },
  pink: {
    mainColor: 'rgba(209, 38, 107, .1)',
    bar: 'rgba(209, 38, 107, .2)'
  },
  green: {
    mainColor: 'rgba(61, 192, 124, .1)',
    bar: 'rgba(61, 192, 124, .2)'
  },
  red: {
    mainColor: 'rgba(249, 59, 24, .1)',
    bar: 'rgba(249, 59, 24, .2)'
  },
  purple: {
    mainColor: 'rgba( 155, 98, 195, .1)',
    bar: 'rgba( 155, 98, 195, .2)'
  },
  darkPurple: {
    mainColor: 'rgba(133, 37, 75, .1)',
    bar: 'rgba(133, 37, 75, .2)'
  }
}
