import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getLanguages } from '../../selectors'
import { VfTextButton } from '../DesignSystem/Buttons'
import { VfContainer } from '../DesignSystem/Utils'
import logo from '../../assets/footer_logo.png'
import './footer.scss'

const LanguagesList = ({ items }) => {
  const { i18n, t } = useTranslation()

  return items.map(item => (
    <Fragment key={item}>
      <button
        onClick={() => i18n.changeLanguage(item)}
        className='footer__languages__button'
      >
        {t(`languages.${item}`)}
      </button>
      <br />
    </Fragment>
  ))
}

const Footer = () => {
  const { t } = useTranslation()
  const languages = useSelector(getLanguages)

  return (
    <footer className='vf-footer mt-4'>
      <VfContainer>
        <div className='vf-row'>
          <div className='mt-4 vf-col-lg-4 vf-col-md-6 vf-col-sm-12'>
            <Link to='/'>
              <img src={logo} alt='Logo' />
            </Link>
          </div>
          <div className='mt-4 vf-col-lg-4 vf-col-md-6 vf-col-sm-12'>
            <h4>{t('titles.languages')}</h4>
            <LanguagesList items={languages} />
          </div>

          <div className='mt-4 vf-col-lg-4 vf-col-md-6 vf-col-sm-12'>
            <nav className='vf-footer__nav'>
              <h4>{t('footer.help')}</h4>
              <VfTextButton
                className='mt-1'
                to='/vattenfall-innovation'
                text={t('footer.vtfInnovation')}
              />
              <br />
              <VfTextButton
                className='mt-1'
                to='/terms-of-agreement'
                text={t('footer.terms')}
              />
            </nav>
          </div>

        </div>
      </VfContainer>
    </footer>
  )
}

export default Footer
