// @ts-check
import React from 'react';

import { IdeaDetails } from '../Components/Ideas';
import { useParams } from 'react-router-dom';

function IdeaDetailsPage() {
  const { ideaId } = useParams();

  return <IdeaDetails ideaId={ideaId} />;
}

export default IdeaDetailsPage;
