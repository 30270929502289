import React from 'react'
import MarkdownView from 'react-showdown'

import { VfCard, bodyDefaultProps, bodyPropTypes } from './VfCard'

const Cart = ({ bodyText, ...props }) => {
  return (
    <VfCard {...props}>
      <MarkdownView
        className='vf-card__text with-markdown'
        markdown={bodyText}
      />
    </VfCard>
  )
}

Cart.propTypes = bodyPropTypes
Cart.defaultProps = bodyDefaultProps

export default Cart
