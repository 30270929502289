// @ts-check
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserNotificationsUrl,
  getNotifications,
  getIsFetchingNotifications
} from '../selectors'
import { requestPreInit } from '../state/actions/notifications'

const useNotifications = () => {
  const dispatch = useDispatch()
  const url = useSelector(getUserNotificationsUrl)
  const data = useSelector(getNotifications)
  const isFetching = useSelector(getIsFetchingNotifications)

  useEffect(() => {
    !!url && dispatch(requestPreInit(url))
  }, [dispatch, url])

  return { data, isFetching }
}

export default useNotifications
