// @ts-check

import { all, call, put } from 'redux-saga/effects'
import actions from '../../actions/users'
import api, { apiPost } from '../../api'

function* update ({ urls, data, userUrl, userId, callback }) {
  try {
    const apiCalls = urls.map((url, idx) => call(api, url, apiPost(data[idx])))
    const response = yield all(apiCalls)

    if (response) {
      yield put(actions.updateSettingsSucceeded())
      yield put(actions.invalidateUser(userId))
      yield put(actions.requestPreInit(userUrl, userId, callback))
    }
  } catch (err) {
    // yield put(actions.updateSettingsFailed(err))
    console.log('error', err)
  }
}

export default update
