import { combineReducers } from 'redux'
import { actionTypes } from '../actions/users'

const payload = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCEEDED:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const all = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCEEDED:
      return [...state, ...action.allIds]
    default:
      return state
  }
}

const invalidated = (state = [], action) => {
  switch (action.type) {
    case actionTypes.INVALIDATE_USER:
      return [...state, action.id]
    case actionTypes.FETCH_USER_SUCCEEDED:
      return state.filter(item => item !== payload.id)
    default:
      return state
  }
}

const isFetching = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INIT:
      return [...state, action.id]
    case actionTypes.FETCH_USER_SUCCEEDED:
    case actionTypes.FETCH_USER_FAILED:
      return state.filter(item => item !== payload.id)
    default:
      return state
  }
}

const users = combineReducers({
  payload,
  all,
  isFetching,
  invalidated
})

export default users
