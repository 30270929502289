// @ts-check
import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useData, useLanguage } from '../../../hooks';
import {
  getCommentsListUrl,
  getCommentsList,
  getIsCommentsFetching,
} from '../../../selectors';
import actions from '../../../state/actions/data';
import { Item, EditForm } from './';
import Modal from '../../DesignSystem/Modal';
import { VfButton } from '../../DesignSystem/Buttons';
import { deleteDataPreInit } from '../../../state/actions/data/delete';
import { Loading } from '../../Common';
import { CommentsCountContext } from '../Details';
import { mergeUrlWithParams } from '../../../helpers/url';

export const List = ({ items, itemToEdit, ...other }) =>
  items.map((item) => {
    return (
      <Fragment key={item.id}>
        {!!itemToEdit && item.id === itemToEdit.id ? (
          <EditForm item={item} {...other} />
        ) : (
          <Item item={item} {...other} />
        )}
      </Fragment>
    );
  });

List.propTypes = {
  items: PropTypes.array,
  itemToEdit: PropTypes.object,
  idea: PropTypes.object,
};

const ListContainer = ({ idea, childrens, ...other }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lng = useLanguage();
  const urlParams = { ideaId: idea.id };
  const { data: apiItems, isFetching } = useData(
    'comments',
    urlParams,
    getCommentsListUrl,
    getCommentsList,
    actions.requestPreInit,
    getIsCommentsFetching
  );
  const items = !!childrens.length ? childrens : apiItems;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const switchModal = () => setDeleteModalOpen(!deleteModalOpen);
  const closeEditForm = () => setItemToEdit(null);

  const { decreaseCounter } = useContext(CommentsCountContext);

  const handleDeleteInit = (item) => {
    setItemToDelete(item);
    switchModal();
    decreaseCounter();
  };

  const handleDelete = () => {
    const url = itemToDelete._links['comment-delete'].href;
    const refreshUrl = idea._links['comment-idea'].href;
    const ideaUrl = idea._links.self.href;
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl);

    const dataToInvalidate = [
      { dataType: 'comments' },
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext },
    ];
    dispatch(
      deleteDataPreInit(url, null, 'comments', dataToInvalidate, refreshUrl)
    );
    switchModal();
  };

  const handleEdit = (item) => {
    setItemToEdit(item);
  };

  if (isFetching) {
    return <Loading />;
  } else if (!isFetching && !!items.length) {
    return (
      <>
        <List
          items={items}
          idea={idea}
          itemToEdit={itemToEdit}
          handleDelete={handleDeleteInit}
          handleEdit={handleEdit}
          handleCancelClick={closeEditForm}
          editFormCallBack={closeEditForm}
          {...other}
        />

        <Modal hidden={!deleteModalOpen} closeModal={switchModal}>
          <p>{t('common.deleteConfirm')}</p>

          <div className="vf-row mb-2">
            <VfButton
              text={t('common.no')}
              color="secondary"
              onClick={switchModal}
              className="vf-col m-1"
            />

            <VfButton
              text={t('common.yes')}
              color="primary"
              onClick={handleDelete}
              className="vf-col m-1"
            />
          </div>
        </Modal>
      </>
    );
  }
  return null;
};

ListContainer.propTypes = {
  idea: PropTypes.object,
  childrens: PropTypes.array,
};

ListContainer.defaultProps = {
  childrens: [],
};

export default ListContainer;
