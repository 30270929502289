import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { List, ListProperties } from '@ckeditor/ckeditor5-list';
import { FontFamily, FontSize, FontColor, FontBackgroundColor } from '@ckeditor/ckeditor5-font';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import { Link } from '@ckeditor/ckeditor5-link';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';
import { Emoji, EmojiActivity, EmojiFlags, EmojiFood, EmojiNature, EmojiObjects, EmojiPeople, EmojiPlaces, EmojiSymbols } from '@phudak/ckeditor5-emoji/src';

import './editor.scss';

const RichTextEditor = ({
  label,
  field: { value, name },
  form: { touched, errors, validateField, setFieldValue },
  ...props
}) => {
  const hasError = !!errors[name] && touched[name];
  return (
    <div className="my-2" style={{ display: 'inline-block' }}>
      {label && <label className="my-2">{label}</label>}
      <CKEditor
        editor={ClassicEditor}
        config={{
          plugins: [
            Essentials,
            Heading,
            FontFamily,
            FontSize,
            FontColor,
            FontBackgroundColor,
            List,
            ListProperties,
            Link,
            BlockQuote,
            Alignment,
            Emoji,
            EmojiActivity,
            EmojiFlags,
            EmojiFood,
            EmojiNature,
            EmojiObjects,
            EmojiPeople,
            EmojiPlaces,
            EmojiSymbols,
            Bold,
            Italic,
            PasteFromOffice,
            Clipboard,
          ],
          toolbar: [
            'heading',
            '|',
            'fontFamily',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'link',
            'blockQuote',
            'alignment',
            '|',
            'undo',
            'redo',
            '|',
            'emoji',
          ],
          fontFamily: {
            options: [
              'default',
              '"Vattenfall Hall", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;',
              'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;',
            ],
            supportAllValues: true,
          },
          fontSize: {
            options: [9, 11, 13, 'default', 17, 19, 21],
            supportAllValues: true,
          },
        }}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
          setFieldValue(name, data);
        }}
        onBlur={(event, editor) => validateField(name)}
      />
      {hasError && (
        <div className="vf-utility-typo--error mt-1">{errors[name]}</div>
      )}
    </div>
  );
};

RichTextEditor.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default RichTextEditor;
