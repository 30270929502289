// @ts-check
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ideaLayoutTypes } from "../../configurations";
import IdeasList from "../Ideas";
import VfHero from "../DesignSystem/Hero";
import ActivityFeed from "../ActivityFeed";
import { VfContainer } from "../DesignSystem/Utils";
import TabBar from "../DesignSystem/TabBar";
import { VfTextButton } from "../DesignSystem/Buttons";
import { ContactSection } from "../Common/Contact";
import { useVfAlchemyScript } from "../../hooks";
import SubscribeChallengeBtn from "./SubscribeChallengeBtn";
import { AvatarMedium } from "../User/Avatar";

const Details = ({ challenge, ContextMenuSection }) => {
  useVfAlchemyScript();
  const { t } = useTranslation();

  const image =
    challenge._links && challenge._links.heroImage
      ? challenge._links.heroImage.href
      : "";
  const headers = [
    t("challenge-details-page.tab1"),
    t("challenge-details-page.tab2"),
  ];
  const { author, videoFrame, state } = challenge;

  const modifiedVideoFrame = videoFrame
    ? videoFrame
        .replace(/width="\d+"/, 'width="100%"')
        .replace(/height="\d+"/, 'height="340"')
        .replace(/title="[^"]*"/, "")
    : null;

  return (
    <>
      <VfHero imageUrl={image} videoFrame={modifiedVideoFrame} />
      <VfContainer>
        <h1 className="my-4">{challenge.title}</h1>

        {state === "ACTIVE" && (
          <p>
            <VfTextButton
              styles={{ width: "100%" }}
              size="large"
              text={t("challenge-details-page.createIdea")}
              to={`/create-idea/${challenge.id}`}
            />
          </p>
        )}

        <div className="vf-row">
          <div className="vf-col-md-8">
            <TabBar tabs={challenge.tabs} headers={headers} />

            <h2>{t("titles.allIdeas")}</h2>
            <div className="vf-col-md-10">
              <IdeasList
                layoutType={ideaLayoutTypes.large_item.type}
                urlParams={{
                  challengeId: challenge.id,
                  sort: "ALPHABETICALLY",
                }}
                showBottomPagination
              />
            </div>
          </div>

          <div className="vf-col-md-4">
            <div style={{ marginBottom: "10px" }}>
              <SubscribeChallengeBtn challengeId={challenge.id} />
            </div>

            {ContextMenuSection}

            {!!author && (
              <div className="vf-row my-2">
                <ContactSection
                  author={author}
                  avatar={<AvatarMedium author={author} />}
                  t={t}
                />
              </div>
            )}

            <h3>{t("titles.activityFeed")}</h3>
            <ActivityFeed context="CHALLENGE" contextId={challenge.id} hideDetails />
          </div>
        </div>
      </VfContainer>
    </>
  );
};

Details.propTypes = {
  challenge: PropTypes.object,
  ContextMenuSection: PropTypes.object,
};

export default Details;
