import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import { useVfAlchemyScript, useLanguage } from '../../../hooks'
import { DropDown, SubmitButton } from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'

import { scoreRequestPreInit } from '../../../state/actions/other'
import { decisionTypesArr as decisionOptions } from '../../../configurations'
import { mergeUrlWithParams } from '../../../helpers/url'

const DecisionForm = ({
  t,
  validationSchema,
  handleCancel,
  handleSubmit,
  handleTypeChange
}) => {
  useVfAlchemyScript()

  return (
    <Formik
      initialValues={{ type: '', description: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={({ isSubmitting, dirty }) => (
        <Form>
          <Field
            name='type'
            label={`${t('common.decision')} *`}
            component={DropDown}
            options={decisionOptions}
            onChange={handleTypeChange}
          />

          <div className='mt-2'>
            <label htmlFor='description'>{t('common.message')}</label>
            <Field name='description' component='textarea' rows={10} />
          </div>

          <div className='vf-row mt-3'>
            <SubmitButton
              button={
                <VfButton className='m-1' text={t('common.saveChanges')} />
              }
              inProgress={isSubmitting}
              disabled={!dirty || isSubmitting}
            />

            <VfButton
              text={t('common.cancel')}
              color='secondary'
              className='m-1'
              disabled={isSubmitting}
              onClick={handleCancel}
            />
          </div>
        </Form>
      )}
    />
  )
}

DecisionForm.propTypes = {
  t: PropTypes.func,
  validationSchema: PropTypes.object,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleTypeChange: PropTypes.func
}

const FormContainer = ({ idea, ...other }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const validationSchema = yup.object().shape({
    type: yup
      .string()
      .oneOf(
        decisionOptions.map(item => item.value),
        'You need to select a decision.'
      )
      .required('required'),
    message: yup.string()
  })
  const lng = useLanguage()

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const url = idea._links.decide.href
    const refreshUrlRaw = idea._links.self.href

    const refreshUrl = mergeUrlWithParams({ lng })(refreshUrlRaw)
    const callback = () => {
      setSubmitting(false)
      resetForm()
    }
    dispatch(scoreRequestPreInit(url, refreshUrl, idea.id, values, callback))
  }

  const handleTypeChange = ({ setFieldValue }, value) => {
    const description = decisionOptions.find(item => item.value === value)
      .defaultDescription
    setFieldValue('description', description)
  }

  return (
    <DecisionForm
      t={t}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      handleTypeChange={handleTypeChange}
      {...other}
    />
  )
}

FormContainer.propTypes = {
  idea: PropTypes.object
}

export default FormContainer
