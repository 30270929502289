// @ts-check
import React from 'react'
import PropTypes from 'prop-types'

// TO-DO add another options:
// https://digitaldesign.vattenfall.com/grid#different-containers

const VfContainer = ({ children, className }) => (
  <div className={`vf-container ${className}`} style={{ position: 'relative' }}>
    {children}
  </div>
)

VfContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  className: PropTypes.string
}

VfContainer.defaultProps = {
  className: ''
}

export default VfContainer
