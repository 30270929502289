// @ts-check

import React from 'react'
import PropTypes from 'prop-types'

const colors = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary'
}

const sizes = {
  regular: 'regular',
  large: 'large'
}

const basicCssClasses = {
  color: {
    primary: 'vf-btn--primary',
    secondary: 'vf-btn--secondary',
    tertiary: 'vf-btn--tertiary'
  }
}

const outlineCssClasses = {
  color: {
    primary: 'vf-btn--outline-dark',
    secondary: 'vf-btn--outline-secondary',
    tertiary: ''
  }
}

const VfButton = ({
  outlined,
  text,
  color,
  size,
  onClick,
  disabled,
  className,
  ...other
}) => {
  const cssClasses = outlined ? outlineCssClasses : basicCssClasses
  const allClassNames = `vf-btn ${className}
  ${color === colors.primary ? cssClasses.color.primary : ''}
  ${color === colors.secondary ? cssClasses.color.secondary : ''}
  ${color === colors.tertiary ? cssClasses.color.tertiary : ''}
  ${size === sizes.large ? 'vf-btn--lg' : ''}`
  return (
    <button
      type='button'
      className={allClassNames.replace(/\s+/g, ' ').trim()}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      {text}
    </button>
  )
}

VfButton.propTypes = {
  outlined: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
}

VfButton.defaultProps = {
  outlined: false,
  text: '',
  onClick: () => {},
  disabled: false,
  color: colors.primary,
  size: sizes.regular,
  className: ''
}

export default VfButton
