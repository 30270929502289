// @ts-check
import React from "react";
import { VfContainer } from "../Components/DesignSystem/Utils";

import "./all-about-innovation.scss";
import IntranetNews from "../Components/IntranetNews/IntranetNews";

const yammerStyles = {
  border: "0px",
  overflow: "hidden",
  width: "100%",
  height: "1000px",
  minHeight: "400px",
};

const AllAboutInnovationPage = () => {
  return (
    <VfContainer>
      <div className="vf-row all-about-page">
        <div className="vf-col-lg-8 my-2">
          <IntranetNews />
        </div>
        <div className="vf-col-lg-4 my-2">
          <iframe
            name="embed-feed"
            title="Viva Engage"
            style={yammerStyles}
            src="https://web.yammer.com/embed/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMTQzNzM0MjczIn0?header=true&footer=true&hideNetworkName=false&theme=light&includeFeedInformation=true"
          />
        </div>
      </div>
    </VfContainer>
  );
};

export default AllAboutInnovationPage;
