// @ts-check

export const UPDATE_USER_SETTINGS_INIT = 'USERS/UPDATE_SETTINGS_INIT'
/**
 * @param {string[]} [urls]
 * @param {Object} [data]
 * @param {string} userUrl
 * @param {function} [callback]
 */
export const updateSettingsInit = (urls, data, userUrl, userId, callback) => {
  return {
    type: UPDATE_USER_SETTINGS_INIT,
    urls,
    data,
    userUrl,
    userId,
    callback
  }
}

export const UPDATE_USER_SETTINGS_SUCCEEDED = 'USERS/UPDATE_SETTINGS_SUCCEEDED'

export const updateSettingsSucceeded = () => {
  return {
    type: UPDATE_USER_SETTINGS_SUCCEEDED
  }
}

export const UPDATE_USER_SETTINGS_FAILED = 'USERS/UPDATE_SETTINGS_FAILED'
/**
 * @param {Object} payload
 */
export const updateSettingsFailed = payload => {
  return {
    type: UPDATE_USER_SETTINGS_FAILED,
    payload
  }
}

export const SET_CURRENT_USER = 'USERS/SET_CURRENT_USER'
/**
 * @param {string} id
 */
export const setCurrentUser = id => {
  return {
    type: SET_CURRENT_USER,
    payload: id
  }
}

export const VALIDATE_CURRENT_USER = 'USERS/VALIDATE_CURRENT_USER'

export const validateCurrentUser = () => {
  return {
    type: VALIDATE_CURRENT_USER
  }
}

export const INVALIDATE_USER = 'USERS/INVALIDATE_USER'

export const invalidateUser = (id) => {
  return {
    type: INVALIDATE_USER,
    id
  }
}

export const actionTypes = {
  UPDATE_USER_SETTINGS_INIT,
  UPDATE_USER_SETTINGS_SUCCEEDED,
  UPDATE_USER_SETTINGS_FAILED,
  SET_CURRENT_USER,
  VALIDATE_CURRENT_USER,
  INVALIDATE_USER
}

const actionCreators = {
  updateSettingsInit,
  updateSettingsSucceeded,
  updateSettingsFailed,
  setCurrentUser,
  validateCurrentUser,
  invalidateUser
}

export default actionCreators
