// @ts-check

import React from 'react';

import UserComponent from '../Components/User';
import { getCurrentUserId } from '../utils/azure';
import { useParams } from 'react-router-dom';

const User = () => {
  const { userId: paramUserId } = useParams();
  const userId = paramUserId || getCurrentUserId();

  return <UserComponent userId={userId} />;
};

export default User;
