import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import translationEN from "./assets/translations/en.json";
import translationDE from "./assets/translations/de.json";
import translationSV from "./assets/translations/sv.json";
import translationNL from "./assets/translations/nl.json";

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  sv: {
    translation: translationSV,
  },
  nl: {
    translation: translationNL,
  },
};

// import LanguageDetector from 'i18next-browser-languagedetector'
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "en",
    fallbackLng: "en",
    saveMissing: false,
    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    react: {
      useSuspense: true,
    },
  });

export default i18n;
