// @ts-check
import React from 'react'
import PropTypes from 'prop-types'

import './ideas.scss'

const SwitchLayout = ({ layouts, changeLayout, activeLayout }) => (
  <div className='switch-layout__container'>
    {layouts.map((layout, idx) => (
      <button
        key={idx}
        onClick={() => changeLayout(layout.type)}
        className={`switch-layout__button ${activeLayout === layout.type &&
          'switch-layout__button--active'}`}
      >
        <img src={layout.icon} alt={layout.type} />
      </button>
    ))}
  </div>
)

SwitchLayout.propTypes = {
  layouts: PropTypes.array,
  changeLayout: PropTypes.func,
  activeLayout: PropTypes.string
}

export default SwitchLayout
