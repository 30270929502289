// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Pagination = ({
  totalPages,
  currentPage,
  isLast,
  isFirst,
  handleNextClick,
  handlePrevClick
}) => {
  const { t } = useTranslation()
  return (
    totalPages > 1 && (
      <ul
        className='vf-pagination mt-2'
        style={{ alignItems: 'center', width: '100%' }}
      >
        <li className={`vf-page-item ${isFirst && 'disabled'}`}>
          <button
            className='vf-page-link'
            aria-disabled={isFirst}
            onClick={handlePrevClick}
          >
            {t('common.next')}
          </button>
        </li>
        <li className={`vf-page-item ${isLast && 'disabled'}`}>
          <button
            className='vf-page-link'
            aria-disabled={isLast}
            onClick={handleNextClick}
          >
            {t('common.prev')}
          </button>
        </li>
        <li className='vf-page-item' style={{ marginLeft: 'auto' }}>
          {t('common.page')} {currentPage} {t('common.of')} {totalPages}
        </li>
      </ul>
    )
  )
}

Pagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  handleNextClick: PropTypes.func,
  handlePrevClick: PropTypes.func
}

export default Pagination
