import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getIdeaDetailsById, getIdeaDetailsUrl } from '../../selectors'
import actions from '../../state/actions/data'
import { useData, useLanguage } from '../../hooks'
import { dataTypes } from '../../configurations'
import { IdeaLink, ChallengeLink } from '../Common'
import { EditForm } from './IdeaForm'

const Edit = ({ ideaId }) => {
  const { t } = useTranslation()
  const lng = useLanguage()
  const params = { ideaId, lng }
  const { data: idea } = useData(
    dataTypes.ideaDetails.name,
    params,
    getIdeaDetailsUrl,
    getIdeaDetailsById,
    actions.requestPreInit
  )

  const getEditIdeaUrl = idea => idea._links.update.href

  const [formVisible, setFormVisible] = useState(true)
  const [message, setMessage] = useState(false)

  return !idea ? null : (
    <>
      <h1 className='my-4'>{t('titles.editIdea')}</h1>
      <div className='vf-row'>
        <div className='vf-col-lg-8'>
          {formVisible && (
            <EditForm
              idea={idea}
              url={getEditIdeaUrl(idea)}
              notify={() => setMessage(true)}
              hideForm={() => setFormVisible(false)}
            />
          )}

          {message && (
            <p className='vf-utility-typo--success mt-3'>
              {t('messages.success')}
              {'.'} {t('messages.redirect')}{' '}
              <IdeaLink id={idea.id}>{t('common.idea')}</IdeaLink>.
            </p>
          )}
        </div>

        <div className='vf-col-lg-4'>
          <h3>{t('common.challenge')}</h3>
          <p>
            <ChallengeLink
              title={
                idea.localizedChallengeInfo.title ||
                idea.localizedChallengeInfo.titles.EN
              }
              id={idea.challenge.id}
            />
          </p>
        </div>
      </div>
    </>
  )
}

Edit.propTypes = {
  ideaId: PropTypes.string
}

export default Edit
