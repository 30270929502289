// @ts-check

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const sizes = {
  small: 'small',
  regular: 'regular'
}

const VfTextButton = ({ to, text, size, className, ...other }) => {
  const allClassNames = `vf-link-with-arrow ${className}
  ${size === sizes.small ? 'vf-link-with-arrow--small' : ''}`
  return (
    <Link
      className={allClassNames.replace(/\s+/g, ' ').trim()}
      to={to}
      {...other}
    >
      {text}
    </Link>
  )
}

VfTextButton.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string
}

VfTextButton.defaultProps = {
  text: '',
  size: sizes.regular,
  className: '',
  to: '#'
}

export default VfTextButton
