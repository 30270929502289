import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'

const DateRangePickerField = ({
  field,
  label,
  form: { setFieldValue, setFieldTouched },
  language
}) => {
  const handleChange = value => {
    setFieldValue(field.name, value)
  }

  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }

  return (
    <>
      {label && (
        <label htmlFor={field.name} className='my-2'>
          {label}
        </label>
      )}

      <DateRangePicker
        id={field.name}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        dayPlaceholder='dd'
        monthPlaceholder='mm'
        yearPlaceholder='yyyy'
        locale={language}
        calendarIcon={null}
        maxDate={new Date()}
        showLeadingZeros
      />
    </>
  )
}

DateRangePickerField.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  language: PropTypes.string
}

DateRangePickerField.defaultProps = {
  language: 'en'
}

export default DateRangePickerField
