// @ts-check

export const FETCH_INFOBOXES_INIT = "INFOBOXES/FETCH_INIT";
export const requestInit = () => ({
  type: FETCH_INFOBOXES_INIT,
});

export const FETCH_INFOBOXES_PRE_INIT = "INFOBOXES/FETCH_PRE_INIT";
/**
 * @param {string} [url]
 */
export const requestPreInitInfoBoxes = (url) => ({
  type: FETCH_INFOBOXES_PRE_INIT,
  url,
});

export const FETCH_INFOBOXES_SUCCEEDED = "INFOBOXES/FETCH_SUCCEEDED";
/**
 * @param {Object} payload
 */
export const fetchSucceeded = (payload) => ({
  type: FETCH_INFOBOXES_SUCCEEDED,
  payload,
});

export const FETCH_INFOBOXES_FAILED = "INFOBOXES/FETCH_FAILED";
/**
 * @param {Object} payload
 */
export const fetchFailed = (payload) => ({
  type: FETCH_INFOBOXES_FAILED,
  payload,
});

export const UPDATE_INFOBOX_INIT = "INFOBOX/UPDATE_INFOBOX_INIT";
export const updateInfoBoxInit = () => {
  return {
    type: UPDATE_INFOBOX_INIT,
  };
};

export const UPDATE_INFOBOX_PRE_INIT = "INFOBOX/UPDATE_INFOBOX_PRE_INIT";
/**
 * @param {string} url
 * @param {Object} payload
 */
export const updateInfoBoxPreInit = (url, payload, lang) => {
  return {
    type: UPDATE_INFOBOX_PRE_INIT,
    url,
    payload,
    lang,
  };
};

export const UPDATE_INFOBOX_SUCCESS = "INFOBOX/UPDATE_INFOBOX_SUCCESS";
/**
 * @param {Object} payload
 */
export const updateInfoBoxSuccess = (payload) => {
  return {
    type: UPDATE_INFOBOX_SUCCESS,
    payload,
  };
};

export const UPDATE_INFOBOX_FAILED = "INFOBOX/UPDATE_INFOBOX_FAILED";
/**
 * @param {Object} payload
 */
export const updateInfoBoxFailed = (payload) => {
  return {
    type: UPDATE_INFOBOX_FAILED,
    payload,
  };
};

export const actionTypes = {
  FETCH_INFOBOXES_FAILED,
  FETCH_INFOBOXES_INIT,
  FETCH_INFOBOXES_PRE_INIT,
  FETCH_INFOBOXES_SUCCEEDED,
  UPDATE_INFOBOX_FAILED,
  UPDATE_INFOBOX_INIT,
  UPDATE_INFOBOX_PRE_INIT,
  UPDATE_INFOBOX_SUCCESS,
};

const actionCreators = {
  fetchFailed,
  fetchSucceeded,
  requestInit,
  requestPreInitInfoBoxes,
  updateInfoBoxFailed,
  updateInfoBoxInit,
  updateInfoBoxPreInit,
  updateInfoBoxSuccess,
};

export default actionCreators;
