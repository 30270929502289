import { EventType, PublicClientApplication } from '@azure/msal-browser';
import callApi from '../state/api';

const MSAL_CLIENT_ID = 'cbf76c50-6c53-4fb3-9422-dfa8d395c3d8';
const MSAL_TENANT_ID = 'f8be18a6-f648-4a47-be73-86d6c5c6604d';
const MSAL_SCOPES = `api://${MSAL_CLIENT_ID}/update`;

const msalConfig = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${MSAL_TENANT_ID}`,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const loginRequest = {
  scopes: ['openid', MSAL_SCOPES],
};

const silentRequest = {
  ...loginRequest,
};

const MAX_REFRESH_ATTEMPTS = 5;

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance
  .handleRedirectPromise()
  .then(() => {
    // Check if user signed in
    const account = msalInstance.getActiveAccount();
    if (!account) {
      // redirect anonymous user to login page
      msalInstance.loginRedirect();
    }
  })
  .catch((err) => {
    // TODO: Handle errors
    console.log(err);
  });

const getToken = async () => {
  try {
    const account = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!account && accounts?.length === 0) {
      await msalInstance.loginRedirect();
    }
    const token = await msalInstance.acquireTokenSilent({
      ...silentRequest,
      account: account || accounts[0],
    });

    localStorage.setItem('smip-fe-login-attempt', 0);

    return token.accessToken;
  } catch (err) {
    console.log('AUTH ERROR: ', err);

    const refreshAttempt =
      parseInt(localStorage.getItem('smip-fe-login-attempt')) || 0;

    if (err?.message?.includes('no_account_error')) {
      return;
    } else if (err?.message?.includes('post_request_failed')) {
      callApi();
    } else if (refreshAttempt < MAX_REFRESH_ATTEMPTS) {
      localStorage.setItem('smip-fe-login-attempt', refreshAttempt + 1);

      setTimeout(() => window.location.reload(), 500);
    }
  }
};

const login = () => {
  try {
    msalInstance.loginRedirect(loginRequest);
  } catch (err) {
    console.error(err);
  }
};

const logout = () => {
  try {
    msalInstance.logoutRedirect(loginRequest);
  } catch (err) {
    console.error(err);
  }
};

const getCurrentUserId = () => {
  const user = msalInstance.getActiveAccount();

  return user?.localAccountId || null;
};

export {
  getCurrentUserId,
  msalInstance,
  msalConfig,
  getToken,
  login,
  logout,
  loginRequest,
  silentRequest,
};
