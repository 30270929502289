const decisionTypes = {
  SEMIFINALIST: {
    value: 'SEMIFINALIST',
    label: 'Semifinalist',
    defaultDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, recusandae. Sapiente et sit assumenda quas esse magni, reiciendis fugit optio inventore quasi mollitia, laudantium molestias laboriosam praesentium, recusandae quibusdam blanditiis!'
  },
  CONTINUE_OUTSIDE_THE_COMPETITION: {
    value: 'CONTINUE_OUTSIDE_THE_COMPETITION',
    label: 'Continue outside the competition',
    defaultDescription: 'Modi temporibus tenetur animi porro corrupti a. \n\nTempore, asperiores voluptas harum reiciendis maxime, odit voluptatem provident ullam repellendus eligendi aperiam ab beatae aliquid laborum aspernatur tempora pariatur facilis dolore sequi.'
  },
  QUALIFIED_FOR_INNOVATION_LAB: {
    value: 'QUALIFIED_FOR_INNOVATION_LAB',
    label: 'Qualified for Innovation Lab',
    defaultDescription: 'Ex mollitia, quisquam labore maxime repellat minima incidunt quod facilis, earum libero a eveniet possimus numquam sed error, doloremque necessitatibus esse nulla in voluptas commodi iste perspiciatis. Eligendi, error obcaecati?'
  }
}

export const decisionTypesArr = Object.keys(decisionTypes)
  .map(item => decisionTypes[item])

export const getDecisionTypeLabel = (type) =>
  decisionTypes[type].label

export default decisionTypes
