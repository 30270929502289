import { combineReducers } from "redux";
import { actionTypes } from "../actions/events";

const payload = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENTS_SUCCEEDED:
      return action.payload;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENTS_INIT:
    case actionTypes.FETCH_EVENTS_PRE_INIT:
      return true;
    case actionTypes.FETCH_EVENTS_FAILED:
    case actionTypes.FETCH_EVENTS_SUCCEEDED:
      return false;

    default:
      return state;
  }
};

const isError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENTS_FAILED:
      return true;
    case actionTypes.FETCH_EVENTS_SUCCEEDED:
      return false;

    default:
      return state;
  }
};

const isMutating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.CREATE_EVENT_INIT:
    case actionTypes.CREATE_EVENT_PRE_INIT:
    case actionTypes.REMOVE_EVENT_INIT:
    case actionTypes.REMOVE_EVENT_PRE_INIT:
    case actionTypes.UPDATE_EVENT_INIT:
    case actionTypes.UPDATE_EVENT_PRE_INIT:
      return true;
    case actionTypes.CREATE_EVENT_FAILED:
    case actionTypes.CREATE_EVENT_SUCCESS:
    case actionTypes.REMOVE_EVENT_FAILED:
    case actionTypes.REMOVE_EVENT_SUCCESS:
    case actionTypes.UPDATE_EVENT_FAILED:
    case actionTypes.UPDATE_EVENT_SUCCESS:
      return false;

    default:
      return state;
  }
};

const events = combineReducers({ payload, isFetching, isError, isMutating });

export default events;
