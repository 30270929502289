// @ts-check
import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getIdeaDetailsById, getIdeaDetailsUrl } from '../../selectors'
import { VfContainer } from '../DesignSystem/Utils'
import { VfButton, VfLinkButton } from '../DesignSystem/Buttons'
import { ChallengeLink, UserLink, Message } from '../Common'
import { ContactSection, UserInfo } from '../Common/Contact'
import IdeaRates from './Details/IdeaRates'
import Decisions from './Details/Decisions'
import actions from '../../state/actions/data'
import { publishIdeaPreInit } from '../../state/actions/other'

import { useData, useLanguage } from '../../hooks'
import { dataTypes } from '../../configurations'
import { TabContent, PublishDraft } from './Details/'
import Modal from '../DesignSystem/Modal'
import { getAuthorFullName } from '../../helpers/author'

import './details.scss'
import { mergeUrlWithParams } from '../../helpers/url'
import { dateLocal } from '../../helpers/date'
import { AvatarMedium } from '../User/Avatar'

export const CommentsCountContext = createContext({
  increaseCounter: () => {},
  decreaseCounter: () => {},
  counter: 0
})

const Details = ({ idea }) => {
  const { t } = useTranslation()
  const lng = useLanguage()
  const { author, coCreators } = idea
  const { localizedChallengeInfo: parentChallenge } = idea
  const { decisions } = idea
  const dispatch = useDispatch()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const openModal = () => setDeleteModalOpen(true)
  const closeModal = () => setDeleteModalOpen(false)

  const [message, setMessage] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [publishing, setPublishing] = useState(false)

  const handleDeleteIdea = () => {
    setDeleting(true)
    const url = idea._links.delete.href
    const ideaUrl = idea._links.self.href
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)

    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext }
    ]

    const callback = () => {
      setMessage(t('messages.deleteIdeaSuccess'))
      closeModal()
    }

    dispatch(
      actions.deleteDataPreInit(
        url,
        null,
        'ideas',
        dataToInvalidate,
        null,
        callback
      )
    )
    dispatch(actions.deleteDataSucceeded('idea-details'))
  }

  const handlePublish = () => {
    setPublishing(true)
    const publishUrl = idea._links.publish.href
    const ideaUrl = idea._links.self.href
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)

    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext }
    ]

    const callback = () => {
      setMessage(t('messages.success'))
      setPublishing(false)
    }

    dispatch(publishIdeaPreInit(publishUrl, dataToInvalidate, callback))
  }

  const isPublished = !!idea.published
  const canEdit = !!idea.userDecisions.editor

  return (
    <VfContainer>
      <h1 className='my-4'>{idea.title}</h1>

      {message ? (
        <Message type='success'>
          {message} {t('messages.redirect')}{' '}
          <ChallengeLink
            title={parentChallenge.title}
            id={parentChallenge.id}
          />{' '}
          {t('common.challenge')}.
        </Message>
      ) : (
        <>
          <div className='vf-row idea-details__top'>
            <div className='vf-col-lg-8 my-2'>
              {isPublished ? (
                <>
                  {author && (
                    <p className='mb-0'>
                      {t('common.published')}: {dateLocal(idea.published)}{' '}
                      {t('common.by')}{' '}
                      <UserLink
                        id={author.id}
                        name={getAuthorFullName(author)}
                      />
                    </p>
                  )}
                  <IdeaRates item={idea} t={t} />
                </>
              ) : (
                <PublishDraft
                  t={t}
                  handlePublish={handlePublish}
                  inProgress={publishing}
                />
              )}
            </div>

            {canEdit && (
              <div className='vf-col-lg-4 idea-details__top__buttons my-2'>
                <VfLinkButton
                  to={`/idea-details/${idea.id}/edit`}
                  text={t('common.edit')}
                  color='secondary'
                  className='mr-1'
                  style={{ minWidth: 'auto' }}
                />

                <VfButton
                  text={t('common.delete')}
                  color='secondary'
                  onClick={openModal}
                  className=''
                  style={{ minWidth: 'auto' }}
                />
              </div>
            )}
          </div>

          <div className='vf-row'>
            <div className='vf-col-lg-8 my-2'>
              <TabContent idea={idea} t={t} />
            </div>

            <div className='vf-col-lg-4 my-2'>
              <h3>{t('common.challenge')}</h3>
              <p>
                <ChallengeLink
                  title={parentChallenge.title}
                  id={parentChallenge.id}
                />
              </p>

              {author && (
                <ContactSection
                  author={author}
                  t={t}
                  avatar={<AvatarMedium author={author} />}
                />
              )}

              {!!coCreators.length && (
                <>
                  <h3>{t('titles.coCreators')}</h3>
                  {coCreators.map(item => (
                    <UserInfo key={item.id} t={t} user={item} isLink />
                  ))}
                </>
              )}

              {idea.country && (
                <>
                  <h3>{t('titles.authorCountry')}</h3>
                  <p>{t(`countries.${idea.country.toLowerCase()}`)}</p>
                </>
              )}

              {!!decisions.length ? (
                <>
                  <h3>{t('titles.decisionStatus')}</h3>
                  <Decisions items={decisions} />
                </>
              ) : null}
            </div>
          </div>
        </>
      )}

      <Modal hidden={!deleteModalOpen} closeModal={closeModal}>
        <p>{t('common.deleteConfirm')}</p>

        <div className='vf-row mb-2'>
          <VfButton
            text={t('common.no')}
            color='secondary'
            disabled={deleting}
            onClick={closeModal}
            className='vf-col m-1'
          />

          <VfButton
            text={deleting ? t('common.deleting') : t('common.yes')}
            disabled={deleting}
            color='primary'
            onClick={handleDeleteIdea}
            className='vf-col m-1'
          />
        </div>
      </Modal>
    </VfContainer>
  )
}

Details.propTypes = {
  // own props
  idea: PropTypes.object
}

const DetailsContainer = ({ ideaId }) => {
  const lng = useLanguage()
  const params = { ideaId, lng }
  const { data: item } = useData(
    dataTypes.ideaDetails.name,
    params,
    getIdeaDetailsUrl,
    getIdeaDetailsById,
    actions.requestPreInit
  )

  const [commentsCounter, setCommentsCounter] = useState(0)

  useEffect(() => {
    if (!!item) {
      setCommentsCounter(item.commentsCount)
    }
  }, [item])

  const increaseCounter = () => setCommentsCounter(commentsCounter + 1)
  const decreaseCounter = () => setCommentsCounter(commentsCounter - 1)

  return item ? (
    <CommentsCountContext.Provider
      value={{ increaseCounter, decreaseCounter, counter: commentsCounter }}>
      <Details idea={item} />
    </CommentsCountContext.Provider>
  ) : null
}

DetailsContainer.propTypes = {
  // own props
  ideaId: PropTypes.string
}

export default DetailsContainer
