// @ts-check
import { put, call, takeEvery, select } from 'redux-saga/effects'
import actionCreators from '../actions/peopleGroups'
import { getFetchPeopleGroupsIfNeeded } from '../../selectors'
import api, { apiDelete, apiPost, apiPut } from '../api'

function * fetchPeopleGroups({ url }) {
  try {
    const fetchNeeded = yield select(getFetchPeopleGroupsIfNeeded)
    if (fetchNeeded) {
      const peopleGroups = yield call(api, url)
      yield put(actionCreators.fetchSucceeded(peopleGroups))
    }
  } catch (error) {
    console.error('Error fetching people groups:', error)
    yield put(actionCreators.fetchFailed(error))
  }
}

function * createPeopleGroups({
  url,
  payload,
}) {
  try {
    yield put(actionCreators.createPeopleGroupsInit())

    const response = yield call(api, url, apiPost(payload))

    if (response) {
      yield put(actionCreators.createPeopleGroupsSuccess(response.payload))
      yield put(actionCreators.requestPreInitPeopleGroups(url))
    } else {
      console.warn('No response received from API for creating group.')
    }
  } catch (error) {
    console.error('Error creating people group:', error)
    yield put(actionCreators.createPeopleGroupsFailed(error))
  }
}

function * updatePeopleGroups({
  url,
  payload,
  lang,
}) {
  try {
    yield put(actionCreators.updatePeopleGroupsInit())

    const response = yield call(
      api,
      `${url}/${payload.id}?lng=${lang}`,
      apiPut(payload),
    )

    if (response) {
      yield put(actionCreators.updatePeopleGroupsSuccess(response))
      yield put(actionCreators.requestPreInitPeopleGroups(`${url}?lng=${lang}`))
    }
  } catch (error) {
    console.error('Error updating people group:', error)
    yield put(actionCreators.updatePeopleGroupsFailed(error))
  }
}

function * removePeopleGroups({
  url,
  peopleGroupsId,
  lang,
}) {
  try {
    yield put(actionCreators.removePeopleGroupsInit())

    if (!peopleGroupsId) {
      const error = new Error('peopleGroupsId is undefined')
      console.error(error.message)
      yield put(actionCreators.removePeopleGroupsFailed(error))
      return
    }

    const response = yield call(api, `${url}/${peopleGroupsId}`, apiDelete())

    if (response) {
      yield put(actionCreators.removePeopleGroupsSuccess(peopleGroupsId))
      yield put(actionCreators.requestPreInitPeopleGroups(`${url}?lng=${lang}`))
    }
  } catch (error) {
    console.error('Error removing people group:', error)
    yield put(actionCreators.removePeopleGroupsFailed(error))
  }
}

export default function * peopleGroupsSaga() {
  yield takeEvery('PEOPLE_GROUPS/FETCH_PRE_INIT', fetchPeopleGroups)
  yield takeEvery('PEOPLE_GROUPS/CREATE_PEOPLE_GROUPS_PRE_INIT', createPeopleGroups)
  yield takeEvery('PEOPLE_GROUPS/UPDATE_PEOPLE_GROUPS_PRE_INIT', updatePeopleGroups)
  yield takeEvery('PEOPLE_GROUPS/REMOVE_PEOPLE_GROUPS_PRE_INIT', removePeopleGroups)
}
