// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { getAuthorFullName } from '../../../helpers/author'

const ExpertSummary = ({ t, categories, rows, avgRates }) => {
  return (
    <div className='mt-4 expert-view__table__container'>
      <table className='vf-table'>
        <thead>
          <tr>
            <th>{t('expert-view.expert')}</th>
            {categories.map(({ label }, idx) => (
              <th key={idx}>{label}</th>
            ))}

            <th>{t('expert-view.average')}</th>
          </tr>
        </thead>

        <tbody>
          {/* Experts' ratings */}
          {rows.map((row, idx) => (
            <tr key={idx}>
              <th>{getAuthorFullName(row.expert)}</th>
              {categories.map(({ id }) => (
                <td key={`${id}-${idx}`}>{Math.round(row.rates[id])}</td>
              ))}
              <td>{row.rates.average}</td>
            </tr>
          ))}

          {/* All experts */}
          <tr>
            <th>{t('expert-view.total')}</th>
            {categories.map(({ id }) => (
              <td key={`${id}-summary`}>{Math.round(avgRates[id])}</td>
            ))}
            <td>{avgRates.average}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

ExpertSummary.propTypes = {
  t: PropTypes.func,
  categories: PropTypes.array,
  rows: PropTypes.array,
  avgRates: PropTypes.object
}

ExpertSummary.defaultProps = {
  categories: [],
  rows: [],
  avgRates: {}
}

const ExpertSummaryContainer = ({ t, idea, categories }) => {
  const categoriesArr = Object.keys(categories).map(item => categories[item])

  return (
    <ExpertSummary
      t={t}
      categories={categoriesArr}
      rows={idea.scores}
      avgRates={idea.avgScore}
    />
  )
}

ExpertSummaryContainer.propTypes = {
  t: PropTypes.func,
  idea: PropTypes.object,
  categories: PropTypes.object
}

export default ExpertSummaryContainer
