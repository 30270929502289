// @ts-check

import readActionCreators, { actionTypes as readActionTypes } from './read'
import updateActionCreators, {
  actionTypes as updateActionTypes
} from './update'

export const actionTypes = {
  ...readActionTypes,
  ...updateActionTypes
}

export default {
  ...readActionCreators,
  ...updateActionCreators
}
