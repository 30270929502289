import React, { useState } from 'react';

const CustomTooltip = ({ title, children, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      style={{ position: 'relative', display: 'inline-block' }}
      {...props}
    >
      {children}
      {isVisible && (
        <div
          style={{
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '5px',
            backgroundColor: 'rgba(97, 97, 97, 0.9)',
            color: 'white',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            fontSize: '0.6rem',
            fontColor: 'white',
          }}
        >
          {title}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
