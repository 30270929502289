import React from 'react'
import PropTypes from 'prop-types'

const PublishDraft = ({ t, handlePublish, inProgress = false }) => (
  <>
    <span className='vf-intro-in-caps mr-2'>
      {t('idea-details.savedAsDraft')}
    </span>
    <button
      style={{
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        borderBottom: '1px solid #000'
      }}
      onClick={handlePublish}
      disabled={inProgress}
    >
      {!inProgress ? t('common.publish') : t('common.publishing')}
    </button>
  </>
)

PublishDraft.propTypes = {
  t: PropTypes.func,
  handlePublish: PropTypes.func,
  inProgress: PropTypes.bool
}

export default PublishDraft
