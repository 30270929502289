import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, ErrorMessage } from 'formik';

import { VfButton } from '../DesignSystem/Buttons';
import { DropDown, SubmitButton, TextInput } from '../Common';
import { useVfAlchemyScript } from '../../hooks';
import { icons } from '../../assets/icons';
import { languages } from '../cms/challenge';

import './Contacts.scss';
import { getContactsState, getPeopleGroupsState } from '../../selectors';
import {
  createContactPreInit,
  removeContactPreInit,
  updateContactPreInit,
} from '../../state/actions/contacts';
import { getEnvVariable } from '../../.env.custom';
import { ImageInput } from '../Common/Attachments';
import { convertFileForApi } from '../../helpers/forms';
import { fetchImage } from '../../utils';

const createInitialContact = () => ({
  email: '',
  id: null,
  image: [],
  jobPosition: '',
  name: '',
  peopleGroupId: '',
});

const validationSchema = Yup.object().shape({
  contact: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    image: Yup.array().min(1, 'Image is required').nullable(),
    jobPosition: Yup.string().required('Job position is required'),
    name: Yup.string().required('Name is required'),
    peopleGroupId: Yup.string().required('Group is required'),
  }),
});

const ContactsFieldsRow = React.memo(
  ({ remove, contact, isEditMode, isSubmitting, dirty }) => {
    useVfAlchemyScript();
    const { t } = useTranslation();

    const peopleGroups = useSelector(getPeopleGroupsState);
    const [imageSrc, setImageSrc] = useState('');

    const groupOptions = useMemo(
      () =>
        peopleGroups?.map((group) => ({
          label: group?.name,
          value: group?.id,
        })) || [],
      [peopleGroups]
    );

    const handleRemove = useCallback(() => {
      if (window.confirm(t('common.deleteConfirm'))) {
        remove(contact.id);
      }
    }, [remove, contact.id, t]);

    const renderErrorMessage = useCallback(
      (field) => (
        <ErrorMessage
          name={field}
          component="div"
          className="vf-utility-typo--error"
          style={{ textAlign: 'center' }}
        />
      ),
      []
    );

    const basePath =
      contact?.image &&
      `${getEnvVariable('REACT_APP_API_ROOT')}images/content?imageId=`;
    const imageUrl = contact?.image && `${basePath}${contact.image.id}`;
    const imagePreview =
      contact?.image &&
      !contact.image.id &&
      contact?.image[0] &&
      URL.createObjectURL(contact.image[0]);

    useEffect(() => {
      const handleImageSrc = async () => {
        const imageObjectURL = await fetchImage(imageUrl);
        setImageSrc(imageObjectURL);
      };

      if (imageUrl && !imagePreview) {
        handleImageSrc();
      }
    }, [imageUrl, imagePreview]);

    return (
      <div className="vf-border-bottom-gray-200">
        {!isEditMode && <p className="mt-2 mb-1">Add new contact </p>}
        <div className="contact-fields">
          <div className="image-upload-wrapper">
            <div className="image-upload">
              <Field
                name="contact.image"
                label="Profile image"
                component={ImageInput}
              />
            </div>
            {contact?.image?.id ||
            contact?.image?.contentBase64 ||
            imagePreview ? (
              <div className="contact-details__image-wrapper">
                <img src={imagePreview || imageSrc} alt={contact.name} />
              </div>
            ) : (
              <div className="contact-details__image-wrapper no-image" />
            )}
          </div>
          <div className="contact-row">
            <div className="contact-row__name">
              <Field
                name="contact.name"
                placeholder="Name"
                component={TextInput}
              />
            </div>
            <div className="contact-row__job">
              <Field
                name="contact.jobPosition"
                placeholder="Job position"
                component={TextInput}
              />
            </div>
            <div className="contact-row__email">
              <Field
                name="contact.email"
                placeholder="Email"
                component={TextInput}
              />
            </div>
            <div className="contact-row__group">
              <Field
                name="contact.peopleGroupId"
                component={DropDown}
                options={groupOptions}
              />
            </div>
            {isEditMode && (
              <img
                alt="Delete contact"
                src={icons.Delete}
                onClick={handleRemove}
                width={24}
                height={24}
                className="contact-row__remove"
              />
            )}
            {!isEditMode && <div className="contact-row__remove" />}

            <SubmitButton
              button={
                <VfButton
                  className="contact-row__submit"
                  text={isEditMode ? 'Update' : 'Add'}
                  size="small"
                />
              }
              inProgress={isSubmitting}
              inProgressText={t('common.submitting')}
              disabled={!dirty || isSubmitting}
            />
          </div>
        </div>
        {renderErrorMessage('contact.name')}
        {renderErrorMessage('contact.jobPosition')}
        {renderErrorMessage('contact.email')}
        {renderErrorMessage('contact.peopleGroupId')}
        {renderErrorMessage('contact.image')}
      </div>
    );
  }
);

ContactsFieldsRow.propTypes = {
  remove: PropTypes.func,
  contact: PropTypes.object,
  isEditMode: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
};

ContactsFieldsRow.defaultProps = {
  remove: () => {},
  isEditMode: false,
  isSubmitting: false,
  dirty: false,
};

const ContactForm = ({ contact, onClose, isEditMode, setLang, lang }) => {
  const dispatch = useDispatch();
  const { isMutating } = useSelector(getContactsState);

  const initialValues = useMemo(
    () => ({
      contact: isEditMode ? contact : createInitialContact(),
      language: lang,
    }),
    [isEditMode, contact, lang]
  );

  const url = `${getEnvVariable('REACT_APP_API_ROOT')}people`;

  const formatData = useCallback(
    async (values) => {
      let imageToUpload = null;

      //same image
      if (values.contact.image === contact.image && values.contact.image.id) {
        const { image, ...contactWithoutImage } = values.contact; // Destructure to remove image field

        return {
          ...values,
          contact: {
            ...contactWithoutImage,
            imageId: contact.image.id,
          },
        };
      }

      //different image
      if (values.contact.image && values.contact.image.length > 0) {
        const imageFile = values.contact.image[0];
        imageToUpload = await convertFileForApi(imageFile);

        return {
          ...values,
          contact: {
            ...values.contact,
            imageContent: imageToUpload,
          },
        };
      }

      return values;
    },
    [contact]
  );

  const sendDataToApi = useCallback(
    (data, resetForm) => {
      if (isEditMode) {
        dispatch(updateContactPreInit(url, data.contact, data.language));
      } else {
        dispatch(
          createContactPreInit(`${url}?lng=${data.language}`, data.contact)
        );
      }
      resetForm();
      onClose();
    },
    [dispatch, isEditMode, url, onClose]
  );

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      try {
        const formattedData = await formatData(values);
        const data = { ...formattedData };
        sendDataToApi(data, resetForm);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
    [formatData, sendDataToApi]
  );

  const handleRemove = useCallback(
    (contactId) => {
      dispatch(removeContactPreInit(url, contactId, lang));
      onClose();
    },
    [dispatch, url, lang, onClose]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, dirty, setFieldValue, values }) => {
        const handleLanguageChange = useCallback(
          (name, value) => {
            setFieldValue(name, value);
            setLang(value);
          },
          [setFieldValue, setLang]
        );

        return (
          <form onSubmit={handleSubmit}>
            {!isEditMode && (
              <div className="vf-border-bottom-gray-200 pb-2">
                <Field
                  name="language"
                  label="Language"
                  component={DropDown}
                  onChange={handleLanguageChange}
                  options={languages}
                />
              </div>
            )}
            <ContactsFieldsRow
              remove={handleRemove}
              contact={values.contact}
              isEditMode={isEditMode}
              isSubmitting={isSubmitting || isMutating}
              dirty={dirty}
            />
          </form>
        );
      }}
    </Formik>
  );
};

ContactForm.propTypes = {
  contact: PropTypes.object,
  onClose: PropTypes.func,
  isEditMode: PropTypes.bool,
  setLang: PropTypes.func,
  lang: PropTypes.string,
};

ContactForm.defaultProps = {
  contact: {},
  onClose: () => {},
  isEditMode: false,
  setLang: () => {},
};

export default ContactForm;
