const activityTypes = {
  publish: { headlineTkey: 'activity.create.headline' },
  like: { headlineTkey: 'activity.like.headline' },
  decision: { headlineTkey: 'activity.decision.headline' },
  comment: { headlineTkey: 'activity.comment.headline' },
  rank: { headlineTkey: 'activity.rank.headline' }
}

export const getActivityTKey = type =>
  activityTypes[type] ? activityTypes[type].headlineTkey : ''

export default activityTypes
