// @ts-check

import { getEnvVariable } from '../../.env.custom';
import { getToken } from '../../utils/azure';

const defaultUrl = getEnvVariable('REACT_APP_API_ROOT');

/**
 * @param {object} response
 */
const validateResponse = (response) => {
  if (response.status === 204) {
    // No Content
    console.warn('NO CONTENT from', response.url);
    return { json: () => null };
  }

  if (response.status === 500) {
    console.warn('Internal server error', response.url);
    return { json: () => null };
  }

  return response;
};

/**
 * @param {{ json: () => void; }} response
 */
const readResponseAsJSON = (response) => {
  return response.json();
};

// /**
//  * @param {string} error
//  */
// const logError = error => console.log('error in api', error)

/**
 * @param {string} token
 */
const optionsGet = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

/**
 * @param {string} token
 * @param {object} data
 * @param {string} method
 */
const optionsOther = (token, data, method) => ({
  method: method,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  },
  body: JSON.stringify(data),
});

const optionsUpload = (token, data, method) => ({
  method,
  headers: {
    Authorization: 'Bearer ' + token,
  },
  body: data,
});

/**
 * @param {string} method
 * @param {object} [data]
 */
const apiRequest =
  (method, data, type = '') =>
  (url, token) => {
    let options;
    if (type === 'UPLOAD') {
      options = optionsUpload;
    } else {
      options = method === 'GET' ? optionsGet : optionsOther;
    }

    return fetch(url, options(token, data, method))
      .then(validateResponse)
      .then(readResponseAsJSON);
    // .catch(logError)
  };

export const apiGet = apiRequest('GET');
export const apiPut = (data = {}) => apiRequest('PUT', data);
export const apiPost = (data = {}, type) => apiRequest('POST', data, type);
export const apiDelete = (data = {}) => apiRequest('DELETE', data);

/**
 * @param {string} url,
 * @param {function} api
 */
export const callGraphApi = async (url = defaultUrl, api = apiGet) => {
  try {
    const token = await getToken();

    return api(url, token);
  } catch (error) {
    throw new Error('Authentication error when calling api: ' + error);
  }
};

const callApi = async (url = defaultUrl, api = apiGet) => {
  try {
    const token = await getToken();

    return api(url, token);
  } catch (error) {
    throw new Error('Authentication error when calling api: ' + error);
  }
};

export default callApi;
