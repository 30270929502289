// @ts-check
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import actions from '../../../state/actions/data'
import { Form, languages } from '.'
import { dataTypes } from '../../../configurations'
import {
  getChallengeDetailsById,
  getChallengeDetailsUrl
} from '../../../selectors'
import { useData, useLanguage } from '../../../hooks'
import {
  handleDraftSubmitTemplate,
  convertFileForApi,
  handleSubmitTemplate
} from '../../../helpers/forms'

import { mergeUrlWithParams } from '../../../helpers/url'

const EditForm = ({ challengeId, notificationsTrigger }) => {
  const dispatch = useDispatch()
  const defaultLng = useLanguage()
  const [lng, setLng] = useState(defaultLng)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const params = { lng, challengeId }

  const { data: item } = useData(
    dataTypes.challengeDetails.name,
    params,
    getChallengeDetailsUrl,
    getChallengeDetailsById,
    actions.requestPreInit
  )

  const rawUrl = useSelector(getChallengeDetailsUrl)
  const parentParams = { lng, challengeId: item?.masterVersionId }
  const parentUrl = mergeUrlWithParams(parentParams)(rawUrl)

  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    subTitle: Yup.string(),
    tab1: Yup.string().required('Required'),
    tab2: Yup.string(),
    language: Yup.string()
      .oneOf(languages.map(({ value }) => value))
      .required('required'),
    smallImage: Yup.array().required('Required'),
    videoFrame: Yup.string(),
    largeImage: Yup.array().required('Required'),
    order: Yup.number().typeError('Number needed'),
    author: Yup.object().required('Required')
  })

  const initialValues = {
    title: item?.title || '',
    subTitle: item?.subTitle || '',
    tab1: item?.tabs[0] || '',
    tab2: item?.tabs[1] || '',
    smallImage: [item?.smallImage].filter(item => item),
    largeImage: [item?.largeImage].filter(item => item),
    videoFrame: item?.videoFrame || '',
    language: item?.language || 'EN',
    order: item?.order || '',
    author: {
      value: item?.author?.id,
      label: item?.author?.displayName
    }
  }

  const handleCountryChange = (_, value) => setLng(value)

  const sendDataToApi = data => {
    setIsSubmitting(true)
    const url = item._links.update.href
    const parentContext = parentUrl
    const draftContext = item._links.self.href
    const dataToInvalidate = [
      { dataType: 'challenges' },
      { dataType: 'challenge-details', context: draftContext },
      { dataType: 'challenge-details', context: parentContext }
    ]

    const handleNotificationTrigger = res => {
      const itemId = res.state === 'HIBERNATED' ? res.masterVersionId : res.id
      const returnUrl = `/challenge-details/${itemId}`

      notificationsTrigger({ id: itemId, url: returnUrl })
      setIsSubmitting(false)
    }

    dispatch(
      actions.updateDataPreInit(
        url,
        data,
        'challenges',
        dataToInvalidate,
        handleNotificationTrigger
      )
    )
  }

  const isNewFile = file => file.constructor.name === 'File'

  async function formatData(values) {
    let smallImage = null
    let largeImage = null
    let smallImageId = null
    let largeImageId = null

    if (!!values.smallImage.length) {
      if (isNewFile(values.smallImage[0])) {
        const smallImageFile = values.smallImage[0]
        smallImage = await convertFileForApi(smallImageFile)
      } else {
        smallImageId = values.smallImage[0].id
      }
    }

    if (!!values.largeImage.length) {
      if (isNewFile(values.largeImage[0])) {
        const largeImageFile = values.largeImage[0]
        largeImage = await convertFileForApi(largeImageFile)
      } else {
        largeImageId = values.largeImage[0].id
      }
    }

    const authorId = values.author?.value

    return {
      ...values,
      largeImage,
      smallImage,
      smallImageId,
      largeImageId,
      authorId
    }
  }

  const handleDraftSubmit = handleDraftSubmitTemplate(formatData, sendDataToApi)
  const handleSubmit = handleSubmitTemplate(formatData, sendDataToApi)

  return item ? (
    <Form
      validationSchema={validationSchema}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleDraftSubmit={handleDraftSubmit}
      handleCountryChange={handleCountryChange}
      showLanguageDdl
      inProgress={isSubmitting}
    />
  ) : null
}

EditForm.defaultProps = {
  notificationsTrigger: () => {}
}

EditForm.propTypes = {
  challengeId: PropTypes.string,
  notificationsTrigger: PropTypes.func
}

export default EditForm
