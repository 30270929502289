// @ts-check

import { put, call, takeEvery, select } from 'redux-saga/effects'
import actionCreators from '../actions/notifications'
import { getFetchNotificationsIfNeeded } from '../../selectors'
import api from '../api'

function* fetchNotifications ({ url }) {
  try {
    const fetchNeeded = yield select(getFetchNotificationsIfNeeded)
    if (fetchNeeded) {
      yield put(actionCreators.requestInit())
      const notifications = yield call(api, url)

      yield put(actionCreators.fetchSucceeded(notifications))
    }
  } catch (error) {
    console.log('notifications error', error)

    yield put(actionCreators.fetchFailed(error))
  }
}

export default function* notificationsSaga () {
  yield takeEvery('NOTIFICATIONS/FETCH_PRE_INIT', fetchNotifications)
}
