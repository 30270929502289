import React from "react";
import PropTypes from "prop-types";

import "react-datetime-picker/dist/DateTimePicker.css";
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import DateTimePicker from 'react-datetime-picker'

const CalendarField = ({
  field,
  label,
  form: { errors, touched, setFieldValue, setFieldTouched },
  language,
}) => {
  const handleChange = (value) => {
    setFieldValue(field.name, value);
  };

  const handleBlur = () => {
    setFieldTouched(field.name, true);
  };

  const hasError = !!errors[field.name] && touched[field.name];

  return (
    <>
      {label && (
        <label htmlFor={field.name} className="my-2">
          {label}
        </label>
      )}

      <DateTimePicker
        id={field.name}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        hourPlaceholder="hh"
        minutePlaceholder="mm"
        disableClock={true}
        locale={language}
        calendarIcon={null}
        showLeadingZeros
      />

      {hasError && (
        <div className="vf-utility-typo--error mt-2">{errors[field.name]}</div>
      )}
    </>
  );
};

CalendarField.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  language: PropTypes.string,
};

CalendarField.defaultProps = {
  language: "en",
};

export default CalendarField;
