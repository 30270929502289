// @ts-check
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { createDataPreInit } from '../../../state/actions/data/create'
import CommentForm from './CommentForm'
import { CommentsCountContext } from '../Details'
import { useLanguage } from '../../../hooks'
import { mergeUrlWithParams } from '../../../helpers/url'

const CreateForm = ({ idea, parent, to, resetAfterSubmit, ...other }) => {
  const dispatch = useDispatch()
  const lng = useLanguage()

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const dataType = 'comments'
    const url = parent
      ? parent._links['comment-comment'].href
      : idea._links['comment-idea'].href
    const callback = () => {
      if (resetAfterSubmit) {
        resetForm()
        setSubmitting(false)
      }
      increaseCounter()
    }
    const refreshUrl = idea._links.comments.href
    const ideaUrl = idea._links.self.href
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)

    const dataToInvalidate = [
      { dataType: 'comments' },
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext }
    ]

    dispatch(
      createDataPreInit(
        url,
        values,
        dataType,
        dataToInvalidate,
        callback,
        refreshUrl
      )
    )
  }
  const { increaseCounter } = useContext(CommentsCountContext)

  return (
    <CommentForm
      {...other}
      handleSubmit={handleSubmit}
      initialValues={{ content: !!to ? `@${to} ` : '' }}
    />
  )
}

CreateForm.propTypes = {
  idea: PropTypes.object,
  parent: PropTypes.object,
  to: PropTypes.string,
  resetAfterSubmit: PropTypes.bool
}

CreateForm.defaultProps = {
  parent: null,
  to: '',
  resetAfterSubmit: false
}

export default CreateForm
