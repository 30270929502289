// @ts-check

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Pagination, UserLink } from '../../Common';
import { getAuthorFullName } from '../../../helpers/author';
import { dateLocal } from '../../../helpers/date';
import { getDecisionTypeLabel } from '../../../configurations';

const Decision = ({
  t,
  item: { type, decidedOn: published, description, decidedBy: author },
  isFirst,
}) => (
  <div className="idea-details__decision">
    <p className="mb-1">
      {isFirst
        ? t('idea-details.decisions.current')
        : t('idea-details.decisions.past')}
    </p>

    <p className="vf-intro-in-caps mb-0">{getDecisionTypeLabel(type)}</p>
    <p className="mb-1">{dateLocal(published)}</p>
    <p className="vf-text--black-50" style={{ whiteSpace: 'pre-wrap' }}>
      {description}
    </p>
    {author && (
      <p className="mb-0">
        <strong>{t('idea-details.decisions.takenBy')}</strong>
        <br />
        <UserLink id={author.id} name={getAuthorFullName(author)} />
      </p>
    )}
  </div>
);

Decision.propTypes = {
  t: PropTypes.func,
  item: PropTypes.object,
  isFirst: PropTypes.bool,
};

const Decisions = ({ items }) => {
  const { t } = useTranslation();
  const [decisionId, setDecisionId] = React.useState(0);

  const isFirst = decisionId === 0;
  const isLast = decisionId === items.length - 1;

  return (
    <div>
      <Pagination
        totalPages={items.length}
        currentPage={decisionId + 1}
        isFirst={isFirst}
        isLast={isLast}
        handlePrevClick={() => setDecisionId(decisionId - 1)}
        handleNextClick={() => setDecisionId(decisionId + 1)}
      />

      <Decision t={t} item={items[decisionId]} isFirst={isFirst} />
    </div>
  );
};

Decisions.propTypes = {
  items: PropTypes.array,
};

export default Decisions;
