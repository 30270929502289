// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { VfCardAsLink } from '../DesignSystem/Cards'
import { getChallengeTheme } from '../../helpers/color'

const Item = ({ item: { id, title, subTitle, description, ideasCount: count, videoFrame, _links } }) => {
  const { t } = useTranslation()
  const image = !!_links.image ? _links.image.href : ''
  const colors = getChallengeTheme(id)

  const modifiedVideoFrame = videoFrame ? videoFrame
    .replace(/width="\d+"/, 'width="360"')
    .replace(/height="\d+"/, 'height="165"')
    .replace(/title="[^"]*"/, '') : null;

  return (
    <div className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
      <VfCardAsLink
        to={`/challenge-details/${id}`}
        imageUrl={image}
        videoFrame={modifiedVideoFrame || ''}
        title={title}
        subTitle={subTitle}
        bodyText={description}
        barColor={colors.bar}
        withBorderBottom
      >
        <p className='vf-card__text vf-card__text-accented '>{`${count} ${t('common.idea', { count })}`}</p>
      </VfCardAsLink>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    ideasCount: PropTypes.number,
    videoFrame: PropTypes.string,
    _links: PropTypes.object
  })
}

export default Item
