// @ts-check

export const UPDATE_DATA_INIT = 'DATA/UPDATE_DATA_INIT'
export const updateDataInit = () => {
  return {
    type: UPDATE_DATA_INIT
  }
}

export const UPDATE_DATA_PRE_INIT = 'DATA/UPDATE_DATA_PRE_INIT'
/**
 * @param {string} url
 * @param {Object} data
 * @param {string} [dataType]
 * @param {Array} [dataToInvalidate]
 * @param {function} [callback]
 * @param {string} [refreshUrl]
 */
export const updateDataPreInit = (
  url,
  data,
  dataType,
  dataToInvalidate,
  callback,
  refreshUrl
) => {
  return {
    type: UPDATE_DATA_PRE_INIT,
    url,
    data,
    dataType,
    dataToInvalidate,
    callback,
    refreshUrl
  }
}

export const UPDATE_DATA_SUCCESS = 'DATA/UPDATE_DATA_SUCCESS'
/**
 * @param {Object} payload
 */
export const updateDataSuccess = payload => {
  return {
    type: UPDATE_DATA_SUCCESS,
    payload
  }
}

export const UPDATE_DATA_FAILED = 'DATA/UPDATE_DATA_FAILED'
/**
 * @param {Object} payload
 */
export const updateDataFailed = payload => {
  return {
    type: UPDATE_DATA_FAILED,
    payload
  }
}

export const actionTypes = {
  UPDATE_DATA_INIT,
  UPDATE_DATA_PRE_INIT,
  UPDATE_DATA_SUCCESS,
  UPDATE_DATA_FAILED
}

const actionCreators = {
  updateDataInit,
  updateDataPreInit,
  updateDataSuccess,
  updateDataFailed
}

export default actionCreators
