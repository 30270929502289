import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useNotifications } from '../../hooks'
import { Loading, IdeaLink } from '../Common'
import NotificationsCountContext from '../Template/NotificationCountContext'
import { dateLocal } from '../../helpers/date'

const Item = ({ id, readBefore, occuredOn, content, contextId }) => (
  <div className='py-2'>
    <p
      className={`vf-intro-in-caps mb-0 ${
        !readBefore ? 'vf-text--secondary' : ''
      }`}
    >
      {dateLocal(occuredOn)}
    </p>
    <IdeaLink id={contextId}>
      <p className='vf-text--body mb-0' style={{ fontSize: 18 }}>
        {content}
      </p>
    </IdeaLink>
  </div>
)

Item.propTypes = {
  id: PropTypes.string,
  readBefore: PropTypes.bool,
  occuredOn: PropTypes.string,
  content: PropTypes.string,
  contextId: PropTypes.string
}

const List = ({ t, items }) => {
  const readed = items.filter(item => item.readBefore)
  const notReaded = items.filter(item => !item.readBefore)

  const numberOfItemsToRender = 6
  const numberOfReadedToRender =
    notReaded.length >= numberOfItemsToRender
      ? 0
      : numberOfItemsToRender - notReaded.length

  return !!items.length ? (
    <div className='mt-2'>
      {notReaded.map(item => (
        <Item key={item.id} {...item} />
      ))}

      {readed.slice(0, numberOfReadedToRender).map(item => (
        <Item key={item.id} {...item} />
      ))}
    </div>
  ) : (
    <p>{t('common.sorryNoItems')}</p>
  )
}

List.propTypes = {
  t: PropTypes.func,
  items: PropTypes.array
}

const Notifications = () => {
  const { data: items, isFetching } = useNotifications()
  const { t } = useTranslation()

  const { resetCounter } = useContext(NotificationsCountContext)

  useEffect(() => {
    if (!!items.length) {
      resetCounter()
    }
  }, [items, resetCounter])

  if (isFetching) {
    return <Loading />
  }
  return <List t={t} items={items} />
}

export default Notifications
