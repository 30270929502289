import { combineReducers } from "redux";
import config from "./config";
import contacts from "./contacts";
import data from "./data";
import dataCommon from "./dataCommon";
import events from "./events";
import infoboxes from "./infoboxes";
import intranetNews from "./intranetNews";
import notifications from "./notifications";
import peopleGroups from "./peopleGroups";
import users from "./users";

const appReducer = combineReducers({
  config,
  contacts,
  data,
  dataCommon,
  events,
  infoboxes,
  intranetNews,
  notifications,
  peopleGroups,
  users,
});

export default appReducer;
