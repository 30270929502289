import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VfButton } from '../DesignSystem/Buttons';
import { getCurrentUserId } from '../../utils/azure';
import {
  getUserSubscriptionsIds,
  getSubscriptionsUrl,
  getUserUrl,
} from '../../selectors';
import actions from '../../state/actions/users';
import { mergeUrlWithParams } from '../../helpers/url';

const SubscribeBtn = ({ handleClick, t, inProgress }) => {
  return (
    <VfButton
      text={!inProgress ? t('common.subscribe') : t('common.submitting')}
      color="primary"
      onClick={handleClick}
      disabled={inProgress}
    />
  );
};
SubscribeBtn.propTypes = {
  handleClick: PropTypes.func,
  t: PropTypes.func,
  inProgress: PropTypes.bool,
};

const UnSubscribeBtn = ({ handleClick, t, inProgress }) => {
  return (
    <button
      onClick={handleClick}
      style={{
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        borderBottom: '1px solid #000',
        marginLeft: 10,
      }}
      disabled={inProgress}
    >
      {!inProgress ? t('common.unsubscribe') : t('common.submitting')}
    </button>
  );
};
UnSubscribeBtn.propTypes = {
  handleClick: PropTypes.func,
  t: PropTypes.func,
  inProgress: PropTypes.bool,
};

const SubscribeChallengeBtn = ({ challengeId }) => {
  const { t } = useTranslation();
  const userSubscriptionsIds = useSelector(getUserSubscriptionsIds);
  const subscriptionsUrl = useSelector(getSubscriptionsUrl);
  const requestedProfileId = getCurrentUserId();
  const userRawUrl = useSelector(getUserUrl);
  const urlUser = mergeUrlWithParams({ requestedProfileId })(userRawUrl);

  const dispatch = useDispatch();

  const [isSubscribing, setIsSubscribing] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    const isSubscribing = userSubscriptionsIds.includes(challengeId);

    setIsSubscribing(isSubscribing);
  }, [challengeId, userSubscriptionsIds]);

  const subscribe = () => [...userSubscriptionsIds, challengeId];
  const unsubscribe = () =>
    userSubscriptionsIds.filter((subId) => subId !== challengeId);

  const handleSubscription = () => {
    setInProgress(true);

    const newSubscriptions = isSubscribing
      ? unsubscribe(challengeId)
      : subscribe(challengeId);

    dispatch(
      actions.updateSettingsInit(
        [subscriptionsUrl],
        [newSubscriptions],
        urlUser,
        null,
        () => {
          setInProgress(false);
        }
      )
    );
  };

  if (isSubscribing) {
    return (
      <>
        <p className="vf-utility-typo--metadata vf-text--secondary">
          {t('challenge-details-page.subscribing')}

          <UnSubscribeBtn
            handleClick={handleSubscription}
            t={t}
            inProgress={inProgress}
          />
        </p>
      </>
    );
  } else {
    return (
      <div className="vf-buttons-container">
        <SubscribeBtn
          handleClick={handleSubscription}
          t={t}
          inProgress={inProgress}
        />
      </div>
    );
  }
};

SubscribeChallengeBtn.propTypes = {
  challengeId: PropTypes.string,
};

export default SubscribeChallengeBtn;
