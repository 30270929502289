// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import Attachments from '../../Common/Attachments/AttachmentsInput';
import IdeaSearchField from './IdeaSearchField';
import TabBar from '../../DesignSystem/TabBar';
import { TextArea, DropDown, MSGraphSearch, Checkbox } from '../../Common';
import { getEnvVariable } from '../../../.env.custom';
import { getUrlFromConfig } from '../../../selectors';
import { useVfAlchemyScript } from '../../../hooks';

import './form.scss';

const countryOptions = [
  { label: 'uk', value: 'UK' },
  { label: 'de', value: 'DE' },
  { label: 'nl', value: 'NL' },
  { label: 'se', value: 'SE' },
  { label: 'pl', value: 'PL' },
  { label: 'fi', value: 'FI' },
  { label: 'dk', value: 'DK' },
  { label: 'no', value: 'NO' },
];

export const validationSchema = (t, edit) =>
  yup.lazy(() =>
    yup.object().shape({
      title: yup
        .string()
        .required(t('idea-details.form.validation.titleRequired')),
      description: yup
        .string()
        .required(t('idea-details.form.validation.descriptionRequired')),
      ideaValue: yup.string(),
      targetGroup: yup.string(),
      requiredResources: yup.string(),
      summaryInEnglish: yup.string(),
      realization: yup.string(),
      cost: yup.string(),
      coCreators: yup.array().of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      ),
      country: yup
        .string()
        .oneOf(countryOptions.map((c) => c.value))
        .required(t('idea-details.form.validation.countryRequired')),
      consent: edit
        ? yup.bool()
        : yup.bool().required('idea-details.form.validation.consent'),
    })
  );

const getIdeaSearchUrl = () => {
  return `${getEnvVariable(
    'REACT_APP_API_ROOT'
  )}ideas/{?lng,sort,containsText}`;
};

export const IdeaForm = ({ t, edit }) => {
  useVfAlchemyScript();

  const ideaSearchUrl = getIdeaSearchUrl();

  const getLabel = (label, required = false) => {
    const translatedLabel = t(`idea-details.form.labels.${label}`);
    return required ? translatedLabel + ' *' : translatedLabel;
  };

  return (
    <>
      <Field
        name="title"
        label={getLabel('title', true)}
        component={edit ? TextArea : IdeaSearchField}
        rawUrl={ideaSearchUrl}
      />
      <Field
        name="description"
        label={getLabel('description', true)}
        component={TextArea}
      />
      <Field
        name="country"
        label={getLabel('country', true)}
        component={DropDown}
        options={countryOptions.map((c) => ({
          ...c,
          label: t(`countries.${c.label}`),
        }))}
      />
      {!edit && (
        <div className="mt-4 with-custom-label--inline">
          <Field name="consent" component={Checkbox} />
          <label htmlFor="consent">
            {getLabel('consentRoot')}{' '}
            <a target="_blank" href="/terms-of-agreement">
              {t('footer.terms')}
            </a>
          </label>
        </div>
      )}
    </>
  );
};

const Form = ({ t, edit }) => {
  useVfAlchemyScript();

  const getLabel = (label, required = false) => {
    const translatedLabel = t(`idea-details.form.labels.${label}`);
    return required ? translatedLabel + ' *' : translatedLabel;
  };

  const profileSearchUrl = useSelector(getUrlFromConfig('profile-search'));

  const ideaSearchUrl = getIdeaSearchUrl();
  const tabHeaders = [t('idea-details.tab1'), t('idea-details.tab2')];
  const infoText = t('idea-details.ideaDescription');

  const formTabs = (t, edit, ideaSearchUrl, profileSearchUrl) => [
    <IdeaForm key="idea" t={t} edit={edit} />,
    <>
      <Field
        name="summaryInEnglish"
        label={getLabel('summaryInEnglish')}
        component={TextArea}
      />
      <Field
        name="ideaValue"
        label={getLabel('ideaValue')}
        component={TextArea}
      />
      <Field
        name="targetGroup"
        label={getLabel('targetGroup')}
        component={TextArea}
      />
      <Field
        name="requiredResources"
        label={getLabel('requiredResources')}
        component={TextArea}
      />
      <Field
        name="realization"
        label={getLabel('realization')}
        component={TextArea}
      />
      <Field
        name="cost"
        label={getLabel('cost')}
        component={TextArea}
      />
      <Field
        name="coCreators"
        label={getLabel('coCreators')}
        component={MSGraphSearch}
        rawUrl={profileSearchUrl}
      />
      <Field
        name="attachments"
        label={getLabel('attachments')}
        component={Attachments}
      />
    </>,
  ];

  return (
    <TabBar
      tabs={formTabs(t, edit, ideaSearchUrl, profileSearchUrl)}
      headers={tabHeaders}
      infoText={infoText}
    />
  );
};

Form.propTypes = {
  t: PropTypes.func,
  edit: PropTypes.bool,
};

export default Form;
