import { combineReducers } from 'redux'
import { actionTypes } from '../actions/config'

const payload = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONFIG_SUCCEEDED:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONFIG_INIT:
      return true
    case actionTypes.FETCH_CONFIG_SUCCEEDED:
      return false

    default:
      return state
  }
}

const isError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONFIG_FAILED:
      return true
    case actionTypes.FETCH_CONFIG_SUCCEEDED:
      return false

    default:
      return state
  }
}

const config = combineReducers({ payload, isFetching, isError })

export default config
