// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const IdeaLink = ({ id, children, ...other }) => (
  <Link to={`/idea-details/${id}`} {...other}>
    {children}
  </Link>
)

IdeaLink.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
}

export default IdeaLink
