import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { UserLink, Loading } from './'
import { useProfile } from '../../hooks'
import { getAuthorFullName } from '../../helpers/author'

export const UserInfo = ({
  t,
  user: { id, email, mobilePhone },
  user,
  isLink
}) => {
  const fullName = getAuthorFullName(user)

  return (
    <p>
      {isLink ? <UserLink id={id} name={fullName} /> : fullName}
      {email && (
        <>
          <br />
          {t('common.email')}: <a href={`mailto:${email}`}>{email}</a>
        </>
      )}
      {mobilePhone && (
        <>
          <br />
          {t('common.phone')}: <a href={`tel:${mobilePhone}`}>{mobilePhone}</a>
        </>
      )}
    </p>
  )
}

UserInfo.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
  isLink: PropTypes.bool
}

export const ContactSection = ({ author, t, isLink, avatar }) => {
  const authorPhoto = author._links ? author._links.photo.href : ''
  return (
    <>
      <h3>{t('titles.contact')}</h3>
      {authorPhoto && (
        <div
          className='mt-n2 mb-2'
          style={{
            textAlign: 'center',
            borderBottom: '1px solid #ccc',
            display: 'inline-block',
            padding: '20px 40px'
          }}>
          {avatar}
        </div>
      )}
      <UserInfo t={t} user={author} isLink={isLink} />
    </>
  )
}

ContactSection.propTypes = {
  author: PropTypes.object,
  t: PropTypes.func,
  isLink: PropTypes.bool,
  avatar: PropTypes.element
}

ContactSection.defaultProps = {
  isLink: true,
  author: {}
}

const ContactSectionContainer = ({ authorId, isLink, avatar: Avatar }) => {
  const { t } = useTranslation()
  const { user, isFetching } = useProfile(authorId)

  if (user) {
    return (
      <div>
        <ContactSection
          author={user}
          t={t}
          isLink={isLink}
          avatar={<Avatar author={user} />}
        />
        {user.city && user.country && (
          <p className='mt-n3 '>
            {user.city}, {user.country}
          </p>
        )}
      </div>
    )
  } else if (isFetching) {
    return <Loading />
  }
  return null
}

ContactSectionContainer.propTypes = {
  authorId: PropTypes.string,
  isLink: PropTypes.bool
}

export default ContactSectionContainer
