// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ChallengeLink = ({ title, id }) =>
  <Link to={`/challenge-details/${id}`}>{title}</Link>

ChallengeLink.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string
}

export default ChallengeLink
