// @ts-check

import React from 'react'
import PropTypes from 'prop-types'

import './checkbox.scss'

const Checkbox = ({ field, label, form: { touched, errors } }) => {
  const hasError = !!errors[field.name] && touched[field.name]

  return (
    <>
      <div className='my-2 checkbox__container'>
        <input
          id={field.name}
          type='checkbox'
          checked={field.value}
          {...field}
        />
        {!!label && <label htmlFor={field.name}>{label}</label>}
      </div>

      {hasError && (
        <div className='vf-utility-typo--error mt-1'>{errors[field.name]}</div>
      )}
    </>
  )
}

Checkbox.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object
}

export default Checkbox
