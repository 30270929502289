// @ts-check

export const FETCH_PEOPLE_GROUPS_INIT = 'PEOPLE_GROUPS/FETCH_INIT'
export const FETCH_PEOPLE_GROUPS_PRE_INIT = 'PEOPLE_GROUPS/FETCH_PRE_INIT'
export const FETCH_PEOPLE_GROUPS_SUCCEEDED = 'PEOPLE_GROUPS/FETCH_SUCCEEDED'
export const FETCH_PEOPLE_GROUPS_FAILED = 'PEOPLE_GROUPS/FETCH_FAILED'

export const CREATE_PEOPLE_GROUPS_INIT = 'PEOPLE_GROUPS/CREATE_PEOPLE_GROUPS_INIT'
export const CREATE_PEOPLE_GROUPS_PRE_INIT = 'PEOPLE_GROUPS/CREATE_PEOPLE_GROUPS_PRE_INIT'
export const CREATE_PEOPLE_GROUPS_SUCCESS = 'PEOPLE_GROUPS/CREATE_PEOPLE_GROUPS_SUCCESS'
export const CREATE_PEOPLE_GROUPS_FAILED = 'PEOPLE_GROUPS/CREATE_PEOPLE_GROUPS_FAILED'

export const UPDATE_PEOPLE_GROUPS_INIT = 'PEOPLE_GROUPS/UPDATE_PEOPLE_GROUPS_INIT'
export const UPDATE_PEOPLE_GROUPS_PRE_INIT = 'PEOPLE_GROUPS/UPDATE_PEOPLE_GROUPS_PRE_INIT'
export const UPDATE_PEOPLE_GROUPS_SUCCESS = 'PEOPLE_GROUPS/UPDATE_PEOPLE_GROUPS_SUCCESS'
export const UPDATE_PEOPLE_GROUPS_FAILED = 'PEOPLE_GROUPS/UPDATE_PEOPLE_GROUPS_FAILED'

export const REMOVE_PEOPLE_GROUPS_INIT = 'PEOPLE_GROUPS/REMOVE_PEOPLE_GROUPS_INIT'
export const REMOVE_PEOPLE_GROUPS_PRE_INIT = 'PEOPLE_GROUPS/REMOVE_PEOPLE_GROUPS_PRE_INIT'
export const REMOVE_PEOPLE_GROUPS_SUCCESS = 'PEOPLE_GROUPS/REMOVE_PEOPLE_GROUPS_SUCCESS'
export const REMOVE_PEOPLE_GROUPS_FAILED = 'PEOPLE_GROUPS/REMOVE_PEOPLE_GROUPS_FAILED'

export const requestInit = () => ({
  type: FETCH_PEOPLE_GROUPS_INIT,
})

/**
 * @param {string} [url]
 */
export const requestPreInitPeopleGroups = (url) => ({
  type: FETCH_PEOPLE_GROUPS_PRE_INIT,
  url,
})

/**
 * @param {Object} payload
 */
export const fetchSucceeded = (payload) => ({
  type: FETCH_PEOPLE_GROUPS_SUCCEEDED,
  payload,
})

/**
 * @param {Object} payload
 */
export const fetchFailed = (payload) => ({
  type: FETCH_PEOPLE_GROUPS_FAILED,
  payload,
})

export const createPeopleGroupsInit = () => ({
  type: CREATE_PEOPLE_GROUPS_INIT,
})

/**
 * @param {string} url
 * @param {Object} payload
 */
export const createPeopleGroupsPreInit = (url, payload) => ({
  type: CREATE_PEOPLE_GROUPS_PRE_INIT,
  url,
  payload,
})

/**
 * @param {Object} payload
 */
export const createPeopleGroupsSuccess = (payload) => ({
  type: CREATE_PEOPLE_GROUPS_SUCCESS,
  payload,
})

/**
 * @param {Object} payload
 */
export const createPeopleGroupsFailed = (payload) => ({
  type: CREATE_PEOPLE_GROUPS_FAILED,
  payload,
})

export const updatePeopleGroupsInit = () => ({
  type: UPDATE_PEOPLE_GROUPS_INIT,
})

/**
 * @param {string} url
 * @param {Object} payload
 * @param {string} lang
 */
export const updatePeopleGroupsPreInit = (url, payload, lang) => ({
  type: UPDATE_PEOPLE_GROUPS_PRE_INIT,
  url,
  payload,
  lang,
})

/**
 * @param {Object} payload
 */
export const updatePeopleGroupsSuccess = (payload) => ({
  type: UPDATE_PEOPLE_GROUPS_SUCCESS,
  payload,
})

/**
 * @param {Object} payload
 */
export const updatePeopleGroupsFailed = (payload) => ({
  type: UPDATE_PEOPLE_GROUPS_FAILED,
  payload,
})

export const removePeopleGroupsInit = () => ({
  type: REMOVE_PEOPLE_GROUPS_INIT,
})

/**
 * @param {string} url
 * @param {string} peopleGroupsId
 * @param {string} lang
 */
export const removePeopleGroupsPreInit = (url, peopleGroupsId, lang) => ({
  type: REMOVE_PEOPLE_GROUPS_PRE_INIT,
  url,
  peopleGroupsId,
  lang,
})

/**
 * @param {string} groupId
 */
export const removePeopleGroupsSuccess = (groupId) => ({
  type: REMOVE_PEOPLE_GROUPS_SUCCESS,
  payload: { id: groupId },
})

/**
 * @param {Object} payload
 */
export const removePeopleGroupsFailed = (payload) => ({
  type: REMOVE_PEOPLE_GROUPS_FAILED,
  payload,
})

export const actionTypes = {
  CREATE_PEOPLE_GROUPS_FAILED,
  CREATE_PEOPLE_GROUPS_INIT,
  CREATE_PEOPLE_GROUPS_PRE_INIT,
  CREATE_PEOPLE_GROUPS_SUCCESS,
  FETCH_PEOPLE_GROUPS_FAILED,
  FETCH_PEOPLE_GROUPS_INIT,
  FETCH_PEOPLE_GROUPS_PRE_INIT,
  FETCH_PEOPLE_GROUPS_SUCCEEDED,
  REMOVE_PEOPLE_GROUPS_FAILED,
  REMOVE_PEOPLE_GROUPS_INIT,
  REMOVE_PEOPLE_GROUPS_PRE_INIT,
  REMOVE_PEOPLE_GROUPS_SUCCESS,
  UPDATE_PEOPLE_GROUPS_FAILED,
  UPDATE_PEOPLE_GROUPS_INIT,
  UPDATE_PEOPLE_GROUPS_PRE_INIT,
  UPDATE_PEOPLE_GROUPS_SUCCESS,
}

const actionCreators = {
  createPeopleGroupsFailed,
  createPeopleGroupsInit,
  createPeopleGroupsPreInit,
  createPeopleGroupsSuccess,
  fetchFailed,
  fetchSucceeded,
  removePeopleGroupsFailed,
  removePeopleGroupsInit,
  removePeopleGroupsPreInit,
  removePeopleGroupsSuccess,
  requestInit,
  requestPreInitPeopleGroups,
  updatePeopleGroupsFailed,
  updatePeopleGroupsInit,
  updatePeopleGroupsPreInit,
  updatePeopleGroupsSuccess,
}

export default actionCreators
