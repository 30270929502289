// @ts-check
import { call, put, select, takeEvery } from 'redux-saga/effects'
import actionCreators from '../actions/infoboxes'
import { getFetchInfoBoxesIfNeeded } from '../../selectors'
import api, { apiPut } from '../api'

function* fetchInfoBoxes({ url }) {
  try {
    const fetchNeeded = yield select(getFetchInfoBoxesIfNeeded)

    if (fetchNeeded) {
      const boxes = yield call(api, url)
      yield put(actionCreators.fetchSucceeded(boxes))
    }
  } catch (error) {
    console.log('Infoboxes error', error)

    yield put(actionCreators.fetchFailed(error))
  }
}

function* updateInfoBox({
  url,
  payload,
  lang,
}) {
  try {
    yield put(actionCreators.updateInfoBoxInit())

    const response = yield call(
      api,
      `${url}/${payload.id}?lng=${lang}`,
      apiPut(payload),
    )

    if (response) {
      yield put(actionCreators.updateInfoBoxSuccess(response))
      yield put(actionCreators.requestPreInitInfoBoxes(`${url}?lng=${lang}`))
    }
  } catch (err) {
    yield put(actionCreators.updateInfoBoxFailed(err))
    console.log('update info box error', err)
  }
}

export default function* infoBoxesSaga() {
  yield takeEvery('INFOBOXES/FETCH_PRE_INIT', fetchInfoBoxes)
  yield takeEvery('INFOBOX/UPDATE_INFOBOX_PRE_INIT', updateInfoBox)
}
