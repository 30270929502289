// @ts-check

export const FETCH_INTRANET_NEWS_INIT = "INTRANET_NEWS/FETCH_INIT";
export const requestInit = () => ({
  type: FETCH_INTRANET_NEWS_INIT,
});

export const FETCH_INTRANET_NEWS_PRE_INIT = "INTRANET_NEWS/FETCH_PRE_INIT";
/**
 * @param {string} [url]
 */
export const requestPreInitIntranetNews = (url) => ({
  type: FETCH_INTRANET_NEWS_PRE_INIT,
  url,
});

export const FETCH_INTRANET_NEWS_SUCCEEDED = "INTRANET_NEWS/FETCH_SUCCEEDED";
/**
 * @param {Object} payload
 */
export const fetchSucceeded = (payload) => ({
  type: FETCH_INTRANET_NEWS_SUCCEEDED,
  payload,
});

export const FETCH_INTRANET_NEWS_FAILED = "INTRANET_NEWS/FETCH_FAILED";
/**
 * @param {Object} payload
 */
export const fetchFailed = (payload) => ({
  type: FETCH_INTRANET_NEWS_FAILED,
  payload,
});

export const UPDATE_INTRANET_NEWS_PAGE = "INTRANET_NEWS/UPDATE_PAGE";
/**
 * @param {number} page
 */
export const updateIntranetNewsPage = (page) => ({
  type: UPDATE_INTRANET_NEWS_PAGE,
  page,
});
