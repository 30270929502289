// @ts-check

export const CREATE_DATA_INIT = 'DATA/CREATE_DATA_INIT'
export const createDataInit = () => {
  return {
    type: CREATE_DATA_INIT
  }
}

export const CREATE_DATA_PRE_INIT = 'DATA/CREATE_DATA_PRE_INIT'
/**
 * @param {string} url
 * @param {Object} data
 * @param {string} dataType
 * @param {Array} dataToInvalidate
 * @param {function} [callback]
 * @param {string} [refreshUrl]
 */
export const createDataPreInit = (
  url,
  data,
  dataType,
  dataToInvalidate,
  callback,
  refreshUrl
) => {
  return {
    type: CREATE_DATA_PRE_INIT,
    url,
    data,
    dataType,
    dataToInvalidate,
    callback,
    refreshUrl
  }
}

export const CREATE_DATA_SUCCESS = 'DATA/CREATE_DATA_SUCCESS'
/**
 * @param {Object} payload
 */
export const createDataSuccess = payload => {
  return {
    type: CREATE_DATA_SUCCESS,
    payload
  }
}

export const CREATE_DATA_FAILED = 'DATA/CREATE_DATA_FAILED'
/**
 * @param {Object} payload
 */
export const createDataFailed = payload => {
  return {
    type: CREATE_DATA_FAILED,
    payload
  }
}

export const actionTypes = {
  CREATE_DATA_INIT,
  CREATE_DATA_PRE_INIT,
  CREATE_DATA_SUCCESS,
  CREATE_DATA_FAILED
}

const actionCreators = {
  createDataInit,
  createDataPreInit,
  createDataSuccess,
  createDataFailed
}

export default actionCreators
