// @ts-check
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getActivityListUrl,
  getActivitiesList,
  getActivitiesPagePrevUrl,
  getActivitiesPageNextUrl,
  getActivitiesPaginationSetup,
  getIsActivityFetching,
} from '../../selectors'
import actions from '../../state/actions/data'
import { UserLink, IdeaLink, Pagination } from '../Common'
import { getActivityTKey } from '../../configurations'
import { useData, useLanguage } from '../../hooks'
import { dateLocal } from '../../helpers/date'

import './activity-feed.scss'
import { getAuthorFullName } from '../../helpers/author'

const Item = ({
  item: {
    author,
    type,
    name,
    published,
    description,
    context,
  },
  hideDetails,
  isOverviewPage,
}) => {
  const { t } = useTranslation()

  if (isOverviewPage) {
    return (
      <div className="vf-border-bottom-gray-200 py-2 activity-feed__item">
        <p className="my-0">
          <UserLink id={author.id} name={getAuthorFullName(author)} />
        </p>
        <IdeaLink id={context.id}>
          <h4 className="my-0">{context.name}</h4>
        </IdeaLink>
        <p className="my-0">{dateLocal(published)}</p>
      </div>
    )
  }
  return (
    <div className="vf-border-bottom-gray-200 py-2 activity-feed__item">
      <p className="my-0 vf-intro-in-caps">
        {t(getActivityTKey(type.toLowerCase()))}
      </p>
      <p className="my-0">
        <UserLink id={author.id} name={getAuthorFullName(author)} />
      </p>
      <p className="my-0">{dateLocal(published)}</p>
      {!!context && hideDetails &&
        <IdeaLink id={context.id}>
          <h4 className="my-0">{context.name}</h4>
        </IdeaLink>}

      {!!context && !hideDetails && (
        <IdeaLink id={context.id}>
          <h4 className="my-1">{context.name}</h4>
          <p className="vf-text--black-50 my-0">{description}</p>
        </IdeaLink>
      )}
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    author: PropTypes.object,
    context: PropTypes.object,
    type: PropTypes.string,
    details: PropTypes.string,
    published: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    hideDetails: PropTypes.bool,
    isOverviewPage: PropTypes.bool,
  }),
}

const List = ({
  items,
  hideDetails,
  isOverviewPage,
}) => items.map((item, i) => <Item key={i} item={item} hideDetails={hideDetails} isOverviewPage={isOverviewPage} />)

List.propTypes = {
  items: PropTypes.array,
  hideDetails: PropTypes.bool,
  isOverviewPage: PropTypes.bool,
}

const ListContainer = ({
  context,
  contextId,
  hideDetails,
  isOverviewPage,
}) => {
  const { t } = useTranslation()
  const [pageUrl, setPageUrl] = useState(null)
  const initialPageSettings = {
    page: 0,
    pageSize: 5,
  }
  const params = contextId
    ? {
      context,
      contextId, ...initialPageSettings,
    }
    : initialPageSettings
  const lng = useLanguage()

  const {
    data,
    url,
    isFetching,
  } = useData(
    'activities',
    {
      ...params,
      lng,
    },
    getActivityListUrl,
    getActivitiesList,
    actions.requestPreInit,
    getIsActivityFetching,
    pageUrl,
  )

  // @ts-ignore
  const items = data.sort(
    (a, b) => new Date(b.published) - new Date(a.published),
  )
  const prevUrl = useSelector(getActivitiesPagePrevUrl(url))
  const nextUrl = useSelector(getActivitiesPageNextUrl(url))
  const paginationSetup = useSelector(getActivitiesPaginationSetup(url))

  const handlePageClick = url => setPageUrl(url)

  return (
    <>
      {!!items.length ? <List items={items} hideDetails={hideDetails} isOverviewPage={isOverviewPage} /> : null}
      {!items.length && !isFetching && <p>{t('activity.noActivity')}</p>}
      {!hideDetails && <Pagination
        totalPages={paginationSetup.totalPages}
        currentPage={paginationSetup.currentPage + 1}
        isFirst={!prevUrl}
        isLast={!nextUrl}
        handlePrevClick={() => handlePageClick(prevUrl)}
        handleNextClick={() => handlePageClick(nextUrl)}
      />}
    </>
  )
}

ListContainer.propTypes = {
  contextId: PropTypes.string,
  context: PropTypes.string,
  hideDetails: PropTypes.bool,
  isOverviewPage: PropTypes.bool,
}

ListContainer.defaultProps = {
  contextId: null,
  context: '',
  title: null,
}

export default ListContainer
