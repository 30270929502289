import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './card.scss';
import { fetchImage, truncateTextAtWord } from '../../../utils';

const CardHeader = ({ bgColor, category, title, barColor }) => (
  <div
    className="vf-card__body card-header__container"
    style={{
      backgroundColor: bgColor,
      color: '#eee',
    }}
  >
    {category && (
      <div
        className="card-header__category"
        style={{ backgroundColor: barColor }}
      >
        {category}
      </div>
    )}
    {title && <h4 className="mb-0 p-1 card-header__title">{title}</h4>}
  </div>
);

CardHeader.propTypes = {
  bgColor: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  barColor: PropTypes.string,
};

const VfCardBody = ({
  imageUrl,
  videoFrame,
  headerBgColor,
  barColor,
  withBorderBottom,
  headerTitle,
  headerCategory,
  caption,
  subTitle,
  title,
  bodyText,
  children,
}) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const handleImageSrc = async () => {
      const imageObjectURL = await fetchImage(imageUrl);

      //@ts-ignore
      setImageSrc(imageObjectURL);
    };

    if (imageUrl) {
      handleImageSrc();
    }
  }, [imageUrl]);

  return (
    <>
      <div
        className="vf-card__img-top"
        style={{
          borderBottom: withBorderBottom ? `6px solid ${barColor}` : 'none',
        }}
      >
        {videoFrame ? (
          <div
            className="embedded-video-wrapper"
            dangerouslySetInnerHTML={{ __html: videoFrame }}
            style={{
              width: '100%',
            }}
          />
        ) : imageUrl ? (
          <img src={imageSrc} alt={title} />
        ) : (
          <CardHeader
            bgColor={headerBgColor}
            category={headerCategory}
            title={headerTitle}
            barColor={barColor}
          />
        )}
      </div>

      <div className="vf-card__body">
        {caption && (
          <p className="vf-card__text mb-1 card-content__caption">{caption}</p>
        )}
        <h3 className="vf-card__subtitle">{subTitle ? subTitle : ' '}</h3>
        <p className="vf-card__title">{title}</p>
        {!!bodyText && (
          <p className="vf-card__text vf-card__bodytext">
            {truncateTextAtWord(bodyText, 100)}
          </p>
        )}
        {children}
      </div>
    </>
  );
};

export const bodyPropTypes = {
  /** Header image url */
  imageUrl: PropTypes.string,
  /** Header video url */
  videoFrame: PropTypes.string,
  /** Fallback if no image available */
  headerBgColor: PropTypes.string,
  /** Show title in header area, if no image */
  headerTitle: PropTypes.string,
  /** Bar at the top of header, if no image */
  headerCategory: PropTypes.string,
  /** Collored line just below image, if needed */
  barColor: PropTypes.string,
  /** Show/hide bar below image */
  withBorderBottom: PropTypes.bool,
  /** Custom, extra info below image */
  caption: PropTypes.string,
  /** Card title below image */
  title: PropTypes.string,
  /** Card subtitle above title */
  subTitle: PropTypes.string,
  /** Main Card content */
  bodyText: PropTypes.string,
  /** Custom, extra bit just below 'body text' */
  children: PropTypes.node,
};

export const bodyDefaultProps = {
  headerBgColor: '#eee',
  barColor: '#ddd',
  withBorderBottom: false,
};

VfCardBody.propTypes = bodyPropTypes;
VfCardBody.defaultProps = bodyDefaultProps;

export const VfCard = (props) => {
  return (
    <article className="vf-card reveal">
      <VfCardBody {...props} />
    </article>
  );
};

VfCard.propTypes = bodyPropTypes;
VfCard.defaultProps = bodyDefaultProps;

const VfCardAsLink = ({ to, isDisabled = false, ...other }) => {
  const isExternal = to.startsWith('http://') || to.startsWith('https://');
  if (isExternal) {
    return (
      <a href={to} className={`vf-card reveal ${isDisabled && 'disabled'}`}>
        <VfCardBody {...other} />
      </a>
    );
  } else {
    return (
      <Link to={to} className={`vf-card reveal ${isDisabled && 'disabled'}`}>
        <VfCardBody {...other} />
      </Link>
    );
  }
};

VfCardAsLink.propTypes = {
  to: PropTypes.string,
  ...bodyPropTypes,
};

VfCardAsLink.defaultProps = {
  to: '#',
  ...bodyDefaultProps,
};

export default VfCardAsLink;
