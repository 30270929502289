// @ts-check

import React, { useEffect } from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { login } from '../utils/azure';

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
};

const withAuthentication = (Component) => (props) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (!isAuthenticated && !inProgress) {
      login();
    }
  }, []);

  return isAuthenticated ? (
    <Component {...props} />
  ) : (
    <div style={loadingStyle}>Loading...</div>
  );
};

export default withAuthentication;
