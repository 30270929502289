// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

import actions from '../../../state/actions/data'
import filesActions from '../../../state/actions/files'
import { assignDocumentsPreInit } from '../../../state/actions/other'
import { SubmitButton } from '../../Common'
import { VfButton } from '../../DesignSystem/Buttons'
import Form, { IdeaForm, validationSchema } from './Form'
import { useLanguage } from '../../../hooks'
import { mergeUrlWithParams } from '../../../helpers/url'

const CreateForm = ({ t, challengeId, url, notify, hideForm }) => {
  const dispatch = useDispatch()
  const lng = useLanguage()

  const initialValues = {
    asDraft: false,
    attachments: [],
    coCreators: [],
    consent: false,
    cost: '',
    country: '',
    description: '',
    ideaValue: '',
    realization: '',
    requiredResources: '',
    summaryInEnglish: '',
    targetGroup: '',
    title: '',
  }

  const createIdea = values => {
    const data = {
      ...values,
      coCreators: values.coCreators.map(item => item.value),
      challengeId: challengeId,
      createPublished: !values.asDraft,
      profitRejectionAgreement: values.consent
    }

    delete data.asDraft
    delete data.attachments

    const dataToInvalidate = [{ dataType: 'ideas' }]

    const callback = () => {
      notify()
      hideForm()
    }

    const assignDocuments = (documents, idea) => {
      const url = idea._links['assign-document'].href
      const data = documents.map(item => item.file)
      dispatch(assignDocumentsPreInit(url, data, callback))
    }

    const uploadFiles = idea => {
      if (values.attachments.length > 0) {
        const data = values.attachments.map(item => ({
          file: item,
          client: idea.id,
          contract: 'innovation-portal'
        }))
        dispatch(
          filesActions.uploadRequestPreInit(data, response =>
            assignDocuments(response, idea)
          )
        )
      } else {
        callback()
      }
    }

    dispatch(
      actions.createDataPreInit(
        mergeUrlWithParams({ lng })(url),
        data,
        'ideas',
        dataToInvalidate,
        uploadFiles
      )
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createIdea}
      validationSchema={() => validationSchema(t)}
      render={({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        dirty,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <IdeaForm t={t} edit={false} />

          <div className='vf-row my-3'>
            <VfButton
              color='tertiary'
              text={
                !isSubmitting ? t('common.saveAsDraft') : t('common.submitting')
              }
              size='large'
              className='vf-col m-1'
              disabled={!dirty || isSubmitting || !values.consent}
              onClick={() => {
                setFieldValue('asDraft', true, false)
                handleSubmit()
              }}
            />

            <SubmitButton
              button={
                <VfButton
                  className='vf-col m-1'
                  text={t('common.submit')}
                  size='large'
                />
              }
              inProgress={isSubmitting}
              inProgressText={t('common.submitting')}
              disabled={!dirty || isSubmitting || !values.consent}
              onClick={handleSubmit}
            />
          </div>
        </form>
      )}
    />
  )
}

CreateForm.propTypes = {
  t: PropTypes.func,
  challengeId: PropTypes.string,
  url: PropTypes.string,
  notify: PropTypes.func,
  hideForm: PropTypes.func
}

export default CreateForm
