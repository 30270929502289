// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import { useVfAlchemyScript, useDropDownFix } from '../../hooks'

import DateRangePicker from './SearchIdeas/DateRangePicker'
import { TextInput, DropDown } from '../Common'

import './search-form.scss'

const SearchFormContent = ({ formElements, language = 'en' }) => {
  useVfAlchemyScript()
  useDropDownFix('.search-form__container')

  const fieldComponents = {
    SELECTOR: DropDown,
    DATE: DateRangePicker,
    TEXT: TextInput
  }

  const formatFields = fields =>
    fields.reduce((acc, next) => {
      const isGroup = !!next.group
      const key = next.group || next.parameterName
      const label = acc[key] ? acc[key].label : next.label
      const newField = {
        parameterName: next.type !== 'DATE' ? next.parameterName : 'dateRange',
        component: fieldComponents[next.type],
        options: next.options
      }
      const elements = acc[key]
        ? next.type !== 'DATE'
          ? [...acc[key].elements, newField]
          : [newField]
        : [newField]

      return {
        ...acc,
        [key]: {
          label,
          isGroup,
          elements
        }
      }
    }, {})

  const formattedFormElements = formatFields(formElements)
  const fields = Object.keys(formattedFormElements).map(
    key => formattedFormElements[key]
  )

  return (
    <div className='vf-row search-form__container'>
      {fields.map((field, idx) => (
        <div key={idx} className='vf-col-sm-12 vf-col-md-6 vf-col-lg-4'>
          <label className='my-2'>{field.label}</label>

          {field.isGroup ? (
            <div className='vf-row search-form__field-group'>
              {field.elements.map((el, i, arr) => (
                <div key={i} className={`vf-col-md-${12 / arr.length}`}>
                  <Field
                    name={el.parameterName}
                    component={el.component}
                    options={el.options}
                    language={language}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Field
              name={field.elements[0].parameterName}
              component={field.elements[0].component}
              options={field.elements[0].options}
              language={language}
            />
          )}
        </div>
      ))}
    </div>
  )
}

SearchFormContent.propTypes = {
  formElements: PropTypes.array,
  language: PropTypes.string
}

export default SearchFormContent
