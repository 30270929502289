// @ts-check

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes, { getNavigationItemsByParentId } from '../../routes';
import { useTranslation } from 'react-i18next';
import './navigation.scss';
import { logout } from '../../utils/azure';

export const LogoutMenuItem = () => {
  const { t } = useTranslation();
  return (
    <button className="vf-navbar__nav-item" onClick={logout}>
      {t('mainNav.logout')}
    </button>
  );
};

const NavigationLink = ({
  t,
  path,
  titleTkey,
  navIcon: NavIcon,
  className,
}) => (
  <Link
    className={`vf-navbar__nav-item ${className}`}
    to={path}
    title={t(titleTkey)}
  >
    {NavIcon ? (
      <>
        {/* on mobile */}
        <div className="nav-icon__text">{t(titleTkey)}</div>
        {/* on all */}
        <NavIcon alt={t(titleTkey)} />
      </>
    ) : (
      <>{t(titleTkey)}</>
    )}
  </Link>
);

NavigationLink.propTypes = {
  t: PropTypes.func,
  path: PropTypes.string,
  titleTkey: PropTypes.string,
  navIcon: PropTypes.func,
  className: PropTypes.string,
};

const NavigationItem = ({
  item: {
    nav: { templatedItem: TemplatedItem },
  },
  item,
  className,
}) => {
  const { t } = useTranslation();

  if (TemplatedItem) {
    className += ' with-badge';
  }

  return TemplatedItem ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <NavigationLink t={t} className={className} {...item} />
      {TemplatedItem && <TemplatedItem />}
    </div>
  ) : (
    <NavigationLink t={t} className={className} {...item} />
  );
};

NavigationItem.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
};

const NavigationList = ({ items, className, activeItem }) => {
  return (
    <nav className={`vf-navbar__nav ${className}`}>
      {items.map((item, i) => {
        const className =
          activeItem === item.id ? 'vf-navbar__nav-item--active' : '';

        return <NavigationItem key={i} item={item} className={className} />;
      })}
    </nav>
  );
};

NavigationList.propTypes = {
  // own props
  items: PropTypes.array,
  className: PropTypes.string,
  activeItem: PropTypes.number,
};

NavigationList.defaultProps = {
  // own props
  items: [],
  className: '',
};

const Navigation = ({ pageId }) => {
  const rootItems = getNavigationItemsByParentId();
  const currentItem = routes.find((item) => item.id === pageId);

  return <NavigationList items={rootItems} activeItem={currentItem.id} />;
};

Navigation.propTypes = {
  pageId: PropTypes.number,
};

export default Navigation;
