import React, { useState, useContext } from 'react'

import NotificationModule from './NotificationModule'

import NotificationsCountContext from './NotificationCountContext'

const NotificationBadge = () => {
  const [moduleOpen, setModuleOpen] = useState(false)

  const closeModule = () => setModuleOpen(false)

  const { counter } = useContext(NotificationsCountContext)

  return (
    <div style={{ position: 'relative' }}>
      <span
        className='vf-badge nav-badge'
        style={{
          background: counter === 0 && '#333',
          cursor: 'pointer',
          userSelect: 'none'
        }}
        onClick={() => setModuleOpen(!moduleOpen)}
      >
        {counter}
      </span>

      {moduleOpen && <NotificationModule closeModule={closeModule} />}
    </div>
  )
}

export default NotificationBadge
