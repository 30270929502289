// @ts-check

import React from 'react'
import './nav-switch.scss'

const NavSwitch = () => {
  const [open, setOpen] = React.useState(false)

  return (
    <button
      className={`nav-switch ${open && 'nav-switch--open'}`}
      onClick={() => setOpen(!open)}
    >
      <span className='nav-switch__bar' />
      <span className='nav-switch__bar' />
      <span className='nav-switch__bar' />
    </button>
  )
}

export default NavSwitch
