// @ts-check

import {
  getPayloadFormatter,
  allIdsFormatter
} from '../../../configurations/dataTypes'

export const FETCH_DATA_INIT = 'DATA/FETCH_INIT'
export /**
 * @param {string} url
 * @param {string} dataType
 */
const requestInit = (url, dataType) => ({
  type: FETCH_DATA_INIT,
  url,
  dataType
})

export const FETCH_DATA_PRE_INIT = 'DATA/FETCH_PRE_INIT'
/**
 * @param {string} [url]
 * @param {string} [dataType]
 * @param {function} [callback]
 */
export const requestPreInit = (url, dataType, callback) => {
  return {
    type: FETCH_DATA_PRE_INIT,
    url,
    dataType,
    callback
  }
}

export const FETCH_DATA_SUCCEEDED = 'DATA/FETCH_SUCCEEDED'
/**
 * @param {Object} payload
 * @param {string} dataType
 */
export const fetchSucceeded = (payload, dataType, url) => {
  return {
    type: FETCH_DATA_SUCCEEDED,
    payload: getPayloadFormatter(dataType, payload),
    allIds: allIdsFormatter(dataType, payload),
    dataType,
    context: url
  }
}

export const FETCH_DATA_FAILED = 'DATA/FETCH_FAILED'
/**
 * @param {Object} context
 */
export const fetchFailed = (dataType, context) => ({
  type: FETCH_DATA_FAILED,
  dataType,
  context
})

export const INVALIDATE_DATA = 'DATA/INVALIDATE_DATA'
/**
 * @param {string} dataType
 * @param {string} [context]
 */
export const invalidateData = (dataType, context) => ({
  type: INVALIDATE_DATA,
  dataType,
  context
})

export const actionTypes = {
  FETCH_DATA_PRE_INIT,
  FETCH_DATA_INIT,
  FETCH_DATA_SUCCEEDED,
  FETCH_DATA_FAILED,
  INVALIDATE_DATA
}

const actionCreators = {
  requestPreInit,
  requestInit,
  fetchSucceeded,
  fetchFailed,
  invalidateData
}

export default actionCreators
