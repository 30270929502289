// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const UserLink = ({ id, name, ...other }) => (
  <Link to={`/user/${id}`} {...other}>
    {name}
  </Link>
)

UserLink.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string
}

export default UserLink
