// @ts-check
import { put, call, takeEvery, select } from 'redux-saga/effects'
import actionCreators from '../actions/config'
import { getFetchConfigIfNeeded } from '../../selectors'
import api from '../api'

function* fetchConfig ({ url }) {
  try {
    const fetchNeeded = yield select(getFetchConfigIfNeeded)
    if (fetchNeeded) {
      const config = yield call(api, url)

      yield put(actionCreators.fetchSucceeded(config))
    }
  } catch (error) {
    console.log('config error', error)

    yield put(actionCreators.fetchFailed(error))
  }
}

export default function* configSaga () {
  yield takeEvery('CONFIG/FETCH_PRE_INIT', fetchConfig)
}
