// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import actions from '../../state/actions/data'
import {
  getChallengesListUrl,
  getChallengesList,
  getIsChallengesFetching
} from '../../selectors'
import { useData, useLanguage } from '../../hooks'
import Item from './Item'
import { Loading } from '../Common'

export const List = ({ items }) => {
  return (
    <>
      <div className='vf-row'>
        {items.map((item, idx) => (
          <Item key={idx} item={item} />
        ))}
      </div>
    </>
  )
}

List.propTypes = {
  items: PropTypes.array
}

List.defaultProps = {
  items: []
}

const ListContainer = ({ state = 'ACTIVE', titleKey = '' }) => {
  const lng = useLanguage()
  const { t } = useTranslation()
  const params = { state, lng }
  const { data: items, isFetching } = useData(
    'challenges',
    params,
    getChallengesListUrl,
    getChallengesList,
    actions.requestPreInit,
    getIsChallengesFetching
  )

  if (isFetching) {
    return <Loading />
  }

  return (
    <>
      {!!titleKey && !!items.length && <h2>{t(titleKey)}</h2>}
      <List items={items} />
    </>
  )
}

ListContainer.propTypes = {
  state: PropTypes.string,
  titleKey: PropTypes.string
}

export default ListContainer
