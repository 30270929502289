export const DELETE_DATA_PRE_INIT = 'DATA/DELETE_DATA_PRE_INIT'
/**
 * @param {string} url
 * @param {Object} data
 * @param {string} dataType
 * @param {Array} dataToInvalidate
 * @param {string} [refreshUrl]
 * @param {function} [callback]
 */
export const deleteDataPreInit = (
  url,
  data,
  dataType,
  dataToInvalidate,
  refreshUrl,
  callback
) => {
  return {
    type: DELETE_DATA_PRE_INIT,
    url,
    data,
    dataType,
    dataToInvalidate,
    refreshUrl,
    callback
  }
}

export const DELETE_DATA_SUCCEEDED = 'DATA/DELETE_DATA_SUCCEEDED'
export const deleteDataSucceeded = (dataType) => ({
  type: DELETE_DATA_SUCCEEDED,
  dataType
})

export const actionTypes = {
  DELETE_DATA_PRE_INIT,
  DELETE_DATA_SUCCEEDED
}

const actionCreators = {
  deleteDataPreInit,
  deleteDataSucceeded
}

export default actionCreators
