import { all, call } from "redux-saga/effects";

import config from "./config";
import contacts from "./contacts";
import data from "./data";
import events from "./events";
import files from "./files";
import infoboxes from "./infoboxes";
import intranetNews from "./intranetNews";
import notifications from "./notifications";
import other from "./other";
import peopleGroups from "./peopleGroups";
import users from "./users";

function* mainSaga(getState) {
  yield all([
    call(config),
    call(contacts),
    call(data),
    call(events),
    call(files),
    call(infoboxes),
    call(intranetNews),
    call(notifications),
    call(other),
    call(peopleGroups),
    call(users),
  ]);
}

export default mainSaga;
