// @ts-check

export const FETCH_EVENTS_INIT = "EVENTS/FETCH_INIT";
export const requestInit = () => ({
  type: FETCH_EVENTS_INIT,
});

export const FETCH_EVENTS_PRE_INIT = "EVENTS/FETCH_PRE_INIT";
/**
 * @param {string} [url]
 */
export const requestPreInitEvents = (url) => ({
  type: FETCH_EVENTS_PRE_INIT,
  url,
});

export const FETCH_EVENTS_SUCCEEDED = "EVENTS/FETCH_SUCCEEDED";
/**
 * @param {Object} payload
 */
export const fetchSucceeded = (payload) => ({
  type: FETCH_EVENTS_SUCCEEDED,
  payload,
});

export const FETCH_EVENTS_FAILED = "EVENTS/FETCH_FAILED";
/**
 * @param {Object} payload
 */
export const fetchFailed = (payload) => ({
  type: FETCH_EVENTS_FAILED,
  payload,
});

// @ts-check

export const CREATE_EVENT_INIT = "EVENT/CREATE_EVENT_INIT";
export const createEventInit = () => {
  return {
    type: CREATE_EVENT_INIT,
  };
};

export const CREATE_EVENT_PRE_INIT = "EVENT/CREATE_EVENT_PRE_INIT";
/**
 * @param {string} url
 * @param {Object} payload
 */
export const createEventPreInit = (url, payload) => {
  return {
    type: CREATE_EVENT_PRE_INIT,
    url,
    payload,
  };
};

export const CREATE_EVENT_SUCCESS = "EVENT/CREATE_EVENT_SUCCESS";
/**
 * @param {Object} payload
 */
export const createEventSuccess = (payload) => {
  return {
    type: CREATE_EVENT_SUCCESS,
    payload,
  };
};

export const CREATE_EVENT_FAILED = "EVENT/CREATE_EVENT_FAILED";
/**
 * @param {Object} payload
 */
export const createEventFailed = (payload) => {
  return {
    type: CREATE_EVENT_FAILED,
    payload,
  };
};

export const UPDATE_EVENT_INIT = "EVENT/UPDATE_EVENT_INIT";
export const updateEventInit = () => {
  return {
    type: UPDATE_EVENT_INIT,
  };
};

export const UPDATE_EVENT_PRE_INIT = "EVENT/UPDATE_EVENT_PRE_INIT";
/**
 * @param {string} url
 * @param {Object} payload
 */
export const updateEventPreInit = (url, payload, lang) => {
  return {
    type: UPDATE_EVENT_PRE_INIT,
    url,
    payload,
    lang,
  };
};

export const UPDATE_EVENT_SUCCESS = "EVENT/UPDATE_EVENT_SUCCESS";
/**
 * @param {Object} payload
 */
export const updateEventSuccess = (payload) => {
  return {
    type: UPDATE_EVENT_SUCCESS,
    payload,
  };
};

export const UPDATE_EVENT_FAILED = "EVENT/UPDATE_EVENT_FAILED";
/**
 * @param {Object} payload
 */
export const updateEventFailed = (payload) => {
  return {
    type: UPDATE_EVENT_FAILED,
    payload,
  };
};

export const REMOVE_EVENT_INIT = "EVENT/REMOVE_EVENT_INIT";
export const removeEventInit = () => {
  return {
    type: REMOVE_EVENT_INIT,
  };
};

export const REMOVE_EVENT_PRE_INIT = "EVENT/REMOVE_EVENT_PRE_INIT";
/**
 * @param {string} url
 * @param {Object} eventId
 */
export const removeEventPreInit = (url, eventId, lang) => {
  return {
    type: REMOVE_EVENT_PRE_INIT,
    url,
    eventId,
    lang,
  };
};

export const REMOVE_EVENT_SUCCESS = "EVENT/REMOVE_EVENT_SUCCESS";
/**
 * @param {Object} payload
 */
export const removeEventSuccess = (payload) => {
  return {
    type: REMOVE_EVENT_SUCCESS,
    payload,
  };
};

export const REMOVE_EVENT_FAILED = "EVENT/REMOVE_EVENT_FAILED";
/**
 * @param {Object} payload
 */
export const removeEventFailed = (payload) => {
  return {
    type: REMOVE_EVENT_FAILED,
    payload,
  };
};

export const actionTypes = {
  CREATE_EVENT_FAILED,
  CREATE_EVENT_INIT,
  CREATE_EVENT_PRE_INIT,
  CREATE_EVENT_SUCCESS,
  FETCH_EVENTS_FAILED,
  FETCH_EVENTS_INIT,
  FETCH_EVENTS_PRE_INIT,
  FETCH_EVENTS_SUCCEEDED,
  REMOVE_EVENT_FAILED,
  REMOVE_EVENT_INIT,
  REMOVE_EVENT_PRE_INIT,
  REMOVE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILED,
  UPDATE_EVENT_INIT,
  UPDATE_EVENT_PRE_INIT,
  UPDATE_EVENT_SUCCESS,
};

const actionCreators = {
  createEventFailed,
  createEventInit,
  createEventPreInit,
  createEventSuccess,
  fetchFailed,
  fetchSucceeded,
  removeEventFailed,
  removeEventInit,
  removeEventPreInit,
  removeEventSuccess,
  requestInit,
  requestPreInitEvents,
  updateEventFailed,
  updateEventInit,
  updateEventPreInit,
  updateEventSuccess,
};

export default actionCreators;
