import i18n from "../i18n";

const options = {
  dateStyle: "medium",
  timeStyle: "short",
};

export const dateLocal = (input) => {
  const { language } = i18n;
  return new Date(input).toLocaleString(language, options);
};

export const formatIsoDate = (isoDate) => {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
  return `${day}.${month}`;
};
export const formatTimeDifference = (isoDate, t) => {
  const date = new Date(isoDate);
  const now = new Date();

  const diffInMilliseconds = Math.abs(date - now);
  const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  const minutes = diffInMinutes % 60;
  const hours = diffInHours % 24;
  const days = diffInDays;

  return `${days} ${t("events.days")} ${hours} ${t(
    "events.hours"
  )} ${minutes} ${t("events.minutes")}`;
};

export const hasDatePassed = (isoDate) => {
  const date = new Date(isoDate);
  const now = new Date();
  return date < now;
};
