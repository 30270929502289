// @ts-check
import { call, takeEvery, put, all } from 'redux-saga/effects'
import api, { apiPost } from '../api'
import { invalidateData, requestPreInit } from '../actions/data/read'
import actionCreators from '../actions/data'
import {
  assignDocumentsSucceeded,
  removeDocumentsSucceeded
} from '../actions/other'
import { mergeUrlWithParams } from '../../helpers/url'

function* itemImpression ({ url, dataToInvalidate }) {
  try {
    yield call(api, url, apiPost())

    if (Array.isArray(dataToInvalidate)) {
      const invalidations = dataToInvalidate.map(item =>
        put(actionCreators.invalidateData(item.dataType, item.context))
      )

      yield all(invalidations)
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* expertAction ({ url, refreshUrl, id, data, callback }) {
  try {
    yield call(api, url, apiPost(data))
    yield put(invalidateData('idea-details', refreshUrl))
    yield put(requestPreInit(refreshUrl, 'idea-details', callback))
  } catch (error) {
    console.log('error', error)
  }
}

function* publishIdea ({ url, dataToInvalidate, callback }) {
  try {
    const response = yield call(api, url, apiPost())

    if (response) {
      if (Array.isArray(dataToInvalidate)) {
        const invalidations = dataToInvalidate.map(item =>
          put(actionCreators.invalidateData(item.dataType, item.context))
        )

        yield all(invalidations)
      }

      if (typeof callback === 'function') {
        yield call(callback)
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* assignExperts ({ url, data, refreshUrl, callback }) {
  try {
    const response = yield call(api, url, apiPost(data))

    if (response) {
      yield put(invalidateData('idea-details', refreshUrl))
      if (refreshUrl) {
        yield put(requestPreInit(refreshUrl, 'idea-details', callback))
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* assignDocuments ({ url, data, callback }) {
  try {
    const apiCalls = data.map(item =>
      call(
        api,
        url,
        apiPost({ fileName: item.originalFileName, fileUrl: item.url })
      )
    )
    const response = yield all(apiCalls)

    if (response) {
      yield put(assignDocumentsSucceeded(response))

      if (typeof callback === 'function') {
        yield call(callback)
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* removeDocuments ({ url, data, callback, refreshUrl = '' }) {
  try {
    const apiCalls = data.map(item => call(api, mergeUrlWithParams({ documentId: item.documentId })(url), apiPost()))

    const response = yield all(apiCalls)

    if (response) {
      yield put(removeDocumentsSucceeded(response))

      if (!!refreshUrl) {
        yield put(invalidateData('idea-details', refreshUrl))
        yield put(actionCreators.requestPreInit(refreshUrl, 'idea-details'))
      }

      if (typeof callback === 'function') {
        yield call(callback)
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* otherSaga () {
  yield takeEvery('ITEM/LIKE_PRE_INIT', itemImpression)
  yield takeEvery('ITEM/SCORE_PRE_INIT', expertAction)
  yield takeEvery('IDEA/PUBLISH_PRE_INIT', publishIdea)
  yield takeEvery('IDEA/ASSIGN_EXPERTS_PRE_INIT', assignExperts)
  yield takeEvery('IDEA/ASSIGN_DOCUMENTS_PRE_INIT', assignDocuments)
  yield takeEvery('IDEA/REMOVE_DOCUMENTS_PRE_INIT', removeDocuments)
}
