import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import NewsItem from "./newsItem";
import { useLanguage } from "../../hooks";
import { Loading } from "../Common";

import { getIntranetNewsListUrl, getIntranetNewsState } from "../../selectors";

import { mergeUrlWithParams } from "../../helpers/url";
import {
  requestPreInitIntranetNews,
  updateIntranetNewsPage,
} from "../../state/actions/intranetNews";
import { VfButton } from "../DesignSystem/Buttons";
import "./intranet-news.scss";

const IntranetNews = () => {
  const lng = useLanguage();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const linkUrl = useSelector(getIntranetNewsListUrl);
  const {
    isFetching,
    payload: { items, currentPage, maxPage },
  } = useSelector(getIntranetNewsState);

  useEffect(() => {
    if (linkUrl) {
      const url = mergeUrlWithParams({ lng, page: currentPage })(linkUrl);
      dispatch(requestPreInitIntranetNews(url));
    }
  }, [linkUrl, lng, dispatch, currentPage]);

  const loadMore = () => {
    dispatch(updateIntranetNewsPage(currentPage + 1));
  };

  const renderNews = (items || []).map((item) => (
    <NewsItem key={item.id} {...item} />
  ));

  return (
    <div>
      {renderNews}

      {isFetching && <Loading />}

      {maxPage !== currentPage && (
        <div className="load-more-wrapper">
          <VfButton
            text={t("common.viewMore")}
            color="secondary"
            outlined
            onClick={loadMore}
            disabled={isFetching}
          />
        </div>
      )}
    </div>
  );
};

export default IntranetNews;
