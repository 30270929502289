import React from "react";
import PropTypes from "prop-types";

import { formatDate, removeHtmlTagsAndEntities } from "../../utils/index";

const NewsItem = ({
  category,
  categoryUrl,
  heading,
  introduction,
  publishedOn,
  url,
}) => {
  const goToIntranetNews = () => {
    window.open(url, "_blank");
  };

  const goToIntranetCategory = () => {
    window.open(categoryUrl, "_blank");
  };

  const subheading = removeHtmlTagsAndEntities(introduction);

  return (
    <div className="news-item">
      <div className="vf-row news-item__content" onClick={goToIntranetNews}>
        <h3 className="item-heading">{heading}</h3>
        <p className="item-subheading">{subheading}</p>
      </div>
      <div className="vf-row news-item__metadata">
        <span className="item-published">{formatDate(publishedOn)}</span>
        <span className="item-category" onClick={goToIntranetCategory}>
          {category}
        </span>
      </div>
    </div>
  );
};

NewsItem.propTypes = {
  category: PropTypes.string,
  categoryUrl: PropTypes.string,
  heading: PropTypes.string,
  introduction: PropTypes.string,
  publishedOn: PropTypes.string,
  url: PropTypes.string,
};

export default NewsItem;
