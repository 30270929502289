// @ts-check
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ActivityFeed from '../Components/ActivityFeed'
import IdeasList from '../Components/Ideas'
import { ideaLayoutTypes } from '../configurations'
import LinkToEditPage from '../Components/cms/page/LinkToEditPage'
import { getInfoBoxesState } from '../selectors'
import { useLanguage } from '../hooks'
import { VfCardAsLink } from '../Components/DesignSystem/Cards'
import { Loading } from '../Components/Common'
import { getEnvVariable } from '../.env.custom'

import './overview.scss'
import arrowIcon from '../assets/icons/VF_arrow_right_rgb.svg'
import LinkToEditOverviewBoxPage from '../Components/cms/page/LinkToEditOverviewBoxPage'
import { useDispatch, useSelector } from 'react-redux'
import { requestPreInitInfoBoxes } from '../state/actions/infoboxes'
import { stripPTags } from '../utils'

function OverviewPage({
  description,
  draftId,
  ...props
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const lng = useLanguage()
  const {
    isFetching: areBoxesFetching,
    payload,
  } = useSelector(getInfoBoxesState)

  const boxes = payload?.boxes
  const isJoinOurInnovationJourneyVisible = !!boxes || areBoxesFetching

  useEffect(() => {
    const url = `${getEnvVariable('REACT_APP_API_ROOT')}info-boxes/overview/?lng=${lng}`
    dispatch(requestPreInitInfoBoxes(url))
  }, [dispatch, lng])

  const renderBoxes = () => (
    Array.isArray(boxes) ? boxes.map(box =>
      <div className="vf-col-sm-12 vf-col-md-6 vf-col-lg-4" key={box.id}>
        <LinkToEditOverviewBoxPage pageId={box.id} />
        <VfCardAsLink
          to={box.linkUrl}
          imageUrl={box.imageUrl}
          title={box.title}
          bodyText={stripPTags(box.description)}
          withBorderBottom
          isDisabled={!box.linkUrl}
        >
          {box.linkUrl && (
            <p className="vf-card__text vf-card__text-accented">
              Explore
              <img
                src={arrowIcon} alt="arrow" style={{
                width: 18,
                marginBottom: 2,
                marginLeft: 3,
              }} />
            </p>
          )}
        </VfCardAsLink>
      </div>,
    ) : null
  )

  return (
    <div className="overview">
      <LinkToEditPage pageId={draftId} />
      {/* Introduction section */}
      <section
        className="my-4 intro"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {/* Join our innovation journey */}
      {isJoinOurInnovationJourneyVisible && (
        <>
          <h3 style={{ textAlign: 'center' }} className="mb-2">Join our innovation journey</h3>
          <div className="vf-row justify-content-center">
            {areBoxesFetching ? (
              <Loading />
            ) : (
              renderBoxes()
            )}
          </div>
        </>
      )}

      <div className="vf-row">
        {/* Activity Feed */}
        <div className="vf-col-sm-12 vf-col-lg-8">
          <h2>{t('titles.activityFeed')}</h2>
          <ActivityFeed isOverviewPage/>
        </div>

        <div className="vf-col-sm-12 vf-col-lg-4">
          <h3>{t('titles.popularIdeas')}</h3>
          <IdeasList
            layoutType={ideaLayoutTypes.side_item.type}
            urlParams={{
              sort: 'MOST_LIKES',
              pageSize: 5,
              page: 0,
            }}
          />
          <h3>{t('titles.recentIdeas')}</h3>
          <IdeasList
            layoutType={ideaLayoutTypes.side_item.type}
            urlParams={{
              sort: 'LAST_PUBLISHED',
              pageSize: 5,
              page: 0,
            }}
          />
        </div>
      </div>
    </div>
  )
}

OverviewPage.propTypes = {
  description: PropTypes.string,
  draftId: PropTypes.string,
}

export default OverviewPage
