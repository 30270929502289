// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsCurrentUser, getIsUserAdmin } from '../../selectors';
import { VfButton } from '../DesignSystem/Buttons';
import ideaItemLayouts from '../../configurations/ideaLayoutTypes';
import IdeasList from '../Ideas';
import { Contact } from '../Common';
import Settings from './Settings';
import ActivityFeed from '../../Components/ActivityFeed';
import Notifications from './Notifications';
import { AvatarLarge } from '../User/Avatar';
import ExportToExcell from '../ExportToExcell';
import { logout } from '../../utils/azure';

const latesIdeasSortFunc = (a, b) =>
  // @ts-ignore
  new Date(b.created) - new Date(a.created);

const Details = ({ userId }) => {
  const isCurrentUser = getIsCurrentUser(userId);
  const isAdmin = useSelector(getIsUserAdmin(userId));
  const { t } = useTranslation();

  return (
    <div className="vf-row">
      <div className="vf-col-sm-12 vf-col-lg-8">
        <Contact authorId={userId} isLink={false} avatar={AvatarLarge} />
        <div className="vf-buttons-container mb-3">
          {isCurrentUser && (
            <VfButton
              className="mr-2"
              color="secondary"
              text={t('common.logout')}
              onClick={logout}
            />
          )}
          {isAdmin && isCurrentUser && <ExportToExcell />}
        </div>
        {isCurrentUser && (
          <>
            <h2>{t('titles.notifications')}</h2>

            <Notifications />
          </>
        )}

        <h2>{t('titles.myIdeas')}</h2>
        <IdeasList
          layoutType={ideaItemLayouts.large_item.type}
          urlParams={{ authorId: userId }}
          limit={3}
          sortFunc={latesIdeasSortFunc}
        />

        {isCurrentUser && (
          <>
            <h2>{t('titles.myDrafts')}</h2>

            <IdeasList
              layoutType={ideaItemLayouts.large_item.type}
              urlParams={{ authorId: userId, status: 'DRAFT' }}
              limit={2}
            />
          </>
        )}
        {/* My activities section */}
        <section>
          {isCurrentUser && <h2>{t('titles.myActivities')}</h2>}
          {!isCurrentUser && <h2>{t('titles.userActivities')}</h2>}

          <ActivityFeed context="USER" contextId={userId} />
        </section>
      </div>

      <div className="vf-col-sm-12 vf-col-lg-4">
        {isCurrentUser && userId && <Settings userId={userId} />}
      </div>
    </div>
  );
};

Details.propTypes = {
  userId: PropTypes.string,
};

export default Details;
