import { combineReducers } from "redux";
import * as actionTypes from "../actions/intranetNews";

const initialPayloadState = {
  maxPage: 0,
  currentPage: 1,
  items: [],
};

const payload = (state = initialPayloadState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INTRANET_NEWS_SUCCEEDED:
      return {
        ...state,
        maxPage: action.payload.maxPage,
        items: [...state.items, ...action.payload.items],
      };

    case actionTypes.UPDATE_INTRANET_NEWS_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INTRANET_NEWS_PRE_INIT:
    case actionTypes.FETCH_INTRANET_NEWS_INIT:
      return true;
    case actionTypes.FETCH_INTRANET_NEWS_FAILED:
    case actionTypes.FETCH_INTRANET_NEWS_SUCCEEDED:
      return false;

    default:
      return state;
  }
};

const isError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INTRANET_NEWS_FAILED:
      return true;
    case actionTypes.FETCH_INTRANET_NEWS_SUCCEEDED:
      return false;

    default:
      return state;
  }
};

const intranetNews = combineReducers({ payload, isFetching, isError });

export default intranetNews;
