// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Formik, Field } from 'formik'

import actions from '../../state/actions/data'
import { useData, useLanguage } from '../../hooks'
import { dataTypes } from '../../configurations'
import { getFormUrl, getFormDetails } from '../../selectors'

import SearchFormContent from './SearchFormContent'
import { VfButton } from '../DesignSystem/Buttons'
import { TextInput, SubmitButton } from '../Common'

import './search-form.scss'

const SearchForm = ({ handleSearch, isSearching }) => {
  const { t } = useTranslation()
  const lng = useLanguage()

  const { data: form } = useData(
    dataTypes.forms.name,
    { lng },
    getFormUrl('search'),
    getFormDetails,
    actions.requestPreInit
  )

  const [fullScreenForm, setFullScreenForm] = React.useState(false)

  const containsTextElement = elements =>
    elements.find(el => el.parameterName === 'containsText') || {}

  const getInitialValues = elements =>
    elements.reduce(
      (acc, next) => ({
        ...acc,
        [next.parameterName]:
          next.parameterName === 'sort' ? 'LAST_PUBLISHED' : ''
      }),
      {
        dateRange: null
      }
    )

  const handleSubmit = (values, { props }) => {
    const dateRange = values.dateRange || []
    const [dateFrom, dateTo] = dateRange

    const formatDate = date => (date ? new Date(date).toUTCString() : '')

    const params = {
      ...values,
      dateFrom: formatDate(dateFrom),
      dateTo: formatDate(dateTo)
    }

    delete params.dateRange

    handleSearch(params)
  }

  return !!form ? (
    <Formik
      initialValues={getInitialValues(form.elements)}
      onSubmit={handleSubmit}
      render={({ values, handleSubmit }) => (
        <>
          {/* Small screens */}
          <div className='search-form--md-hidden'>
            <VfButton
              text={t('common.changeFilter')}
              outlined
              color='secondary'
              className='w-100'
              onClick={() => setFullScreenForm(true)}
            />

            <div className='mt-3'>
              <Field
                name='containsText'
                placeholder={containsTextElement(form.elements).label}
                component={TextInput}
              />

              <SubmitButton
                button={
                  <VfButton
                    text={t('common.search')}
                    color='secondary'
                    className='my-3 w-100'
                    form='search-form'
                  />
                }
                inProgress={isSearching}
                inProgressText={t('common.searching')}
                disabled={isSearching}
              />
            </div>
          </div>

          <div
            className={`search-form ${
              fullScreenForm ? 'search-form--fullScreen' : ''
            }`}
          >
            <div className='search-form__top search-form--md-hidden'>
              <span>{t('forms.searchIdeas.filters')}</span>
              <button
                aria-label='close-form'
                onClick={() => setFullScreenForm(false)}
              >
                &times;
              </button>
            </div>

            <form onSubmit={handleSubmit} id='search-form'>
              <SearchFormContent formElements={form.elements} language={lng} />

              <div className='search-form__buttons'>
                <VfButton
                  text={t('common.applyFilter')}
                  outlined
                  color='secondary'
                  className='search-form--md-hidden mt-3'
                  onClick={() => setFullScreenForm(false)}
                />

                <SubmitButton
                  button={
                    <VfButton
                      text={t('common.search')}
                      color='secondary'
                      className='my-2'
                      onClick={() => setFullScreenForm(false)}
                    />
                  }
                  inProgress={isSearching}
                  inProgressText={t('common.searching')}
                  disabled={isSearching}
                />
              </div>
            </form>
          </div>
        </>
      )}
    />
  ) : null
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func,
  isSearching: PropTypes.bool
}

SearchForm.defaultProps = {
  isSearching: false
}

export default SearchForm
