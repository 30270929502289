// @ts-check

import { takeEvery } from 'redux-saga/effects'
import { actionTypes } from '../../actions/users'

import fetchUser from './read'
import update from './update'

export default function* UsersSaga () {
  yield takeEvery(actionTypes.FETCH_USER_PRE_INIT, fetchUser)
  yield takeEvery(actionTypes.UPDATE_USER_SETTINGS_INIT, update)
}
