// @ts-check

import React from 'react'
import { Edit as UserEditComponent } from '../Components/User'

const UserEdit = () => {
  return <UserEditComponent />
}

export default UserEdit
