// wp: custom validator for Draft option
export const validateDraft = (values, { setFieldError, setFieldTouched }) => {
  let isValid = true;
  // that works only for simple 'string' fields
  const requiredFields = ["title"];

  requiredFields.forEach((item) => {
    if (values[item] !== undefined && !values[item]) {
      isValid = false;
      setFieldError(item, "Required");
      setFieldTouched(item);
    }
  });
  return isValid;
};

const convertFileIntoBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export async function convertFileForApi(file) {
  const base64 = await convertFileIntoBase64(file);
  const contentBase64 = base64.split(",")[1];
  const { name: fileName, type: mimeType } = file;

  return {
    fileName,
    title: fileName,
    mimeType,
    contentBase64,
  };
}

export function handleDraftSubmitTemplate(formatData, sendDataToApi) {
  return async function ({ values, ...props }) {
    const isValid = validateDraft(values, props);
    if (!isValid) {
      return;
    }

    const state = { state: "DRAFT" };
    const formattedData = await formatData(values);
    const data = { ...formattedData, ...state };

    // console.log('submitting Draft', data)
    sendDataToApi(data);
  };
}

export function handleSubmitTemplate(formatData, sendDataToApi) {
  return async function (values) {
    const state = { state: "PUBLISHED" };
    const formattedData = await formatData(values);
    const data = { ...formattedData, ...state };

    // console.log('submitting', data)
    sendDataToApi(data);
  };
}
export const capitalizedWithDashesToFormatted = (s) => {
  if (s.includes("_")) {
    return (
      s.toLowerCase().charAt(0).toUpperCase() +
      s.toLowerCase().slice(1).replace(/_/gi, " ")
    );
  }

  return s.toLowerCase().charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};
