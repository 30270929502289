import React from 'react'
import PropTypes from 'prop-types'
import BoxItem from './BoxItem'
import { LargeItem, SmallItem } from './ListItems'
import SideItem from './SideItem'
import { ideaLayoutTypes as layout } from '../../../configurations'

const IdeaItemTemplate = ({ type, ...props }) => {
  switch (type) {
    case layout.box.type:
      return <BoxItem {...props} />
    case layout.large_item.type:
      return <LargeItem {...props} />
    case layout.small_item.type:
      return <SmallItem {...props} />
    case layout.side_item.type:
      return <SideItem {...props} />
    default:
      return null
  }
}

IdeaItemTemplate.propTypes = {
  type: PropTypes.string
}

export default IdeaItemTemplate
