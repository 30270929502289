import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";

import { VfButton } from "../DesignSystem/Buttons";

import { DropDown, SubmitButton, TextInput } from "../Common";
import { useVfAlchemyScript } from "../../hooks";
import { icons } from "../../assets/icons";
import CalendarField from "../Common/calendarField";
import { languages } from "../cms/challenge/Form";

import "./Events.scss";
import { getEventsState } from "../../selectors";
import {
  createEventPreInit,
  removeEventPreInit,
  updateEventPreInit,
} from "../../state/actions/events";
import { getEnvVariable } from "../../.env.custom";

const createInitialEvent = (type) => ({
  id: null,
  eventType: type,
  takesPlaceOn: new Date(),
  name: "",
});

const validationSchema = Yup.object().shape({
  event: Yup.object().shape({
    name: Yup.string().required("Required"),
    takesPlaceOn: Yup.string().nullable().required("Required"),
    eventType: Yup.string().required("Required"),
  }),
});

export const EventsFieldsRow = ({
  eventTypesOptions,
  remove,
  event,
  isEditMode,
  isSubmitting,
  dirty,
}) => {
  useVfAlchemyScript();
  const { t } = useTranslation();

  const handleRemove = () => {
    if (window.confirm(t("common.deleteConfirm"))) {
      remove(event.id);
    }
  };

  const hasError = event.takesPlaceOn === null || event.name === "";

  return (
    <div className="vf-border-bottom-gray-200">
      {!isEditMode && <p className="mt-2 mb-1">Add new event </p>}
      <div className="event-row">
        <div className="event-row__type">
          <Field
            name="event.eventType"
            component={DropDown}
            options={eventTypesOptions}
          />
        </div>
        <div className="event-row__description">
          <Field name={`event.name`} placeholder="name" component={TextInput} />
        </div>
        <div className="event-row__date">
          <Field
            name={`event.takesPlaceOn`}
            placeholder="Date"
            component={CalendarField}
          />
        </div>
        {isEditMode && (
          <img
            alt="Delete event"
            src={icons.Delete}
            onClick={handleRemove}
            width={24}
            height={24}
            className="event-row__remove"
          />
        )}
        {!isEditMode && <div className="event-row__remove" />}

        <SubmitButton
          button={
            <VfButton
              className="event_row__submit"
              text={isEditMode ? "Update" : "Add"}
              size="small"
            />
          }
          inProgress={isSubmitting}
          inProgressText={t("common.submitting")}
          disabled={!dirty || isSubmitting}
        />
      </div>
      {hasError && (
        <div className="vf-utility-typo--error" style={{ textAlign: "center" }}>
          All Fields need to be filled
        </div>
      )}
    </div>
  );
};

EventsFieldsRow.propTypes = {
  remove: PropTypes.func,
  eventTypesOptions: PropTypes.array,
  event: PropTypes.object,
  isEditMode: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
};

EventsFieldsRow.defaultProps = {
  remove: () => {},
  eventTypesOptions: [],
  isEditMode: false,
  isSubmitting: false,
  dirty: false,
};

const EventForm = ({
  event,
  eventTypesOptions,
  onClose,
  isEditMode,
  setLang,
  lang,
}) => {
  const dispatch = useDispatch();
  const { isMutating } = useSelector(getEventsState);
  const initialValues = {
    event: isEditMode ? event : createInitialEvent(eventTypesOptions[0]?.value),
    language: lang,
  };

  const url = `${getEnvVariable("REACT_APP_API_ROOT")}events`;

  const onSubmit = (values, { resetForm }) => {
    if (isEditMode) {
      dispatch(updateEventPreInit(url, values.event, values.language));
    } else {
      dispatch(
        createEventPreInit(`${url}?lng=${values.language}`, values.event)
      );
    }
    resetForm();
    onClose();
  };

  const handleRemove = (eventId) => {
    dispatch(removeEventPreInit(url, eventId, lang));
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      render={({
        handleSubmit,
        isSubmitting,
        dirty,
        setFieldValue,
        values,
      }) => {
        const handleLanguageChange = (name, value) => {
          setFieldValue(name, value);
          setLang(value);
        };

        return (
          <>
            <form onSubmit={handleSubmit}>
              {!isEditMode && (
                <div className="vf-border-bottom-gray-200 pb-2">
                  <Field
                    name="language"
                    label="Language"
                    component={DropDown}
                    onChange={handleLanguageChange}
                    options={languages}
                  />
                </div>
              )}
              <EventsFieldsRow
                remove={handleRemove}
                eventTypesOptions={eventTypesOptions}
                event={values.event}
                isEditMode={isEditMode}
                isSubmitting={isSubmitting || isMutating}
                dirty={dirty}
              />
            </form>
          </>
        );
      }}
    />
  );
};

EventForm.propTypes = {
  event: PropTypes.object,
  eventTypesOptions: PropTypes.array,
  onClose: PropTypes.func,
  isEditMode: PropTypes.bool,
  setLang: PropTypes.func,
  lang: PropTypes.string,
};

EventForm.defaultProps = {
  event: {},
  eventTypesOptions: [],
  onClose: () => {},
  isEditMode: false,
  setLang: () => {},
};

export default EventForm;
