// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import { components }  from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useTranslation } from 'react-i18next'
import api from '../../../state/api'
import { mergeUrlWithParams } from '../../../helpers/url'

const ControlComponent = props => (
  <div className='vf-input-container w-100 mb-2'>
    <div className='vf-input' style={{ padding: '8px 10px' }}>
      <components.Control {...props} />
    </div>
  </div>
)

const getProperFieldValue = field => {
  if (typeof field.value === "string" || field.value instanceof String)
    return {value: field.value, label: field.value}
  else
    return field.value
}

const IdeaSearchField = ({
  field,
  label,
  form: { setFieldValue, setFieldTouched, errors, touched },
  rawUrl
}) => {
  const { t } = useTranslation()
  const fieldValue = getProperFieldValue(field)
  field.value = fieldValue

  const getData = (items) =>  {
      let arr = []
      for (let key in items) {
        let title = items[key].title
        let elem = {  
          label: title, 
          value: title,
          isDisabled: true
        }
        arr.push(elem)
      }
      arr = arr.slice(0, 10)
      return [{ label: 'Similar ideas:', options: arr }]
  }

  const fetchIdeas = async (value, cbFunc) => {
    let params = {
      containsText: value,
      sort: 'LAST_PUBLISHED',
      lng: 'EN'
    }
    const url = mergeUrlWithParams(params)(rawUrl)

    try {
      const response = await api(url)
      cbFunc(getData(response.items))
    } catch (err) {
      cbFunc([
        {
          value: 'error',
          label: t('messages.error'),
          isDisabled: true
        }
      ])
    }
  }

  const throttledFetchIdeas = throttle(fetchIdeas, 1000)

  const loadOptions = (input, callback) => {
    if (!input) {
      return []
    }
    throttledFetchIdeas(input, callback)
  }

  const handleChange = value => {
    if (value !== undefined && value instanceof Object)
      setFieldValue(field.name, value.value || [])
    else
      setFieldValue(field.name, value || [])
  }

  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }

  return (
    <>
      <label htmlFor={field.name} className='mt-2'>
        {label}
      </label>
      
      <AsyncCreatableSelect
        openMenuOnClick={false}
        name={field.name}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        isMulti={false}
        cacheOptions
        loadOptions={loadOptions}
        noOptionsMessage={() => t('messages.noResults')}
        loadingMessage={() => t('common.loading')}
        placeholder=''
        components={{
          Control: ControlComponent,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null
        }}
        isClearable={true}
        styles={{
          control: base => ({
            ...base,
            border: '0',
            boxShadow: 'none'
          })
        }}
      />

      {!!errors[field.name] && touched[field.name] && (
        <div className='vf-utility-typo--error mt-2'>{errors[field.name]}</div>
      )}
    </>
  )
}

IdeaSearchField.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  rawUrl: PropTypes.string
}

export default IdeaSearchField
