// @ts-check
/**
 * @param {{ slice: (arg0: number, arg1: any) => void; lastIndexOf: (arg0: string) => void; }} url
 */
const clearUrl = (url) => {
  const index = url.lastIndexOf('{')
  const connector = url.charAt(index + 1)
  const urlNoParams = url.slice(0, index)

  return { connector, urlNoParams }
}

/**
 * @param {object} objParams
 */
const mapParamsToString = (objParams) =>
  Object.keys(objParams)
    .map(item => `${item}=${objParams[item]}`)
    .join('&')

/**
 * @param {object} params
 */

export const mergeUrlWithParams = (params) =>
/**
 * @param {string} rawUrl
 */
  (rawUrl) => {
    const { urlNoParams, connector } = clearUrl(rawUrl)
    const paramsStr = params ? `${connector}${mapParamsToString(params)}` : ''
    return `${urlNoParams}${paramsStr}`
  }
