import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useProfile, useLanguage } from "../hooks";
import { VfLinkButton, VfButton } from "../Components/DesignSystem/Buttons";
import { VfContainer } from "../Components/DesignSystem/Utils";
import { getLanguages } from "../selectors";
import logo from "../assets/images/vf-logo.svg";

const P = ({ children }) => <p style={{ fontSize: "24px" }}>{children}</p>;

const LOCAL_I18N = {
  EN: {
    title: "🌟 Welcome to the Innovation Portal! 🌟",
    message: (
      <>
        <P>
          We’re thrilled to announce that our innovation hub is launching soon.
          Mark your calendars for the grand release on 19.03.2024! 🚀
        </P>
        <P>
          Get ready to explore groundbreaking ideas, collaborate with
          visionaries, and drive positive change.
        </P>
        <P>Together, we’ll shape the future of innovation. Stay tuned! 🌱✨</P>
      </>
    ),
    addButton: "Add to calendar",
    meet: "Or meet with us in person at one of these locations:",
  },
  DE: {
    title: "🌟 Willkommen im Innovationsportal! 🌟",
    message: (
      <>
        <P>
          Wir freuen uns, dir mitteilen zu können, dass Vattenfall Innovation
          2024 bald an den Start gehen wird. Merke dir unbedingt den 19.03.2024
          für das große Kick-off vor! 🚀
        </P>
        <P>
          Mach dich bereit, bahnbrechende und visionäre Ideen einzubringen und
          dadurch positive Veränderungen voranzutreiben.
        </P>
        <P>
          Lass uns gemeinsam mit Innovationen die Zukunft gestalten 🌱✨
        </P>
      </>
    ),
    addButton: "Zum Kalender hinzufügen",
    meet: "Oder treffen Sie uns persönlich an einem dieser Standorte:",
  },
  NL: {
    title: "🌟 Innovatie Hub: werk samen en kom tot innovaties! 🌟",
    message: (
      <>
        <P>
          Zet dinsdag 19 maart in je agenda, want dan lanceert Vattenfall
          Innovation de Innovatie Hub! 🚀
        </P>
        <P>
          Hierin kun je met elkaar innovatieve ideeën delen, samenwerken met
          andere (innovatieve) collega’s en werken aan positieve verandering
          binnen Vattenfall.
        </P>
        <P>
          Samen werken we aan de toekomst van innovatie. 🌱✨
        </P>
      </>
    ),
    addButton: "Toevoegen aan kalender",
    meet: "Of ontmoet ons persoonlijk op een van deze locaties:",
  },
  SV: {
    title: "🌟 Välkommen till Innovationsportalen! 🌟",
    message: (
      <>
        <P>
          Vi är glada att kunna meddela att vår innovationshubb snart kommer
          att lanseras. Markera era kalendrar för den stora lanseringen den
          19.03.2024! 🚀
        </P>
        <P>
          Gör dig redo att utforska banbrytande idéer, samarbeta med visionärer
          samt driva positiv förändring.
        </P>
        <P> Tillsammans formar vi framtiden med innovation. Håll utkik! 🌱✨</P>
      </>
    ),
    addButton: "Lägga till i kalendern",
    meet: "Eller träffa oss personligen på någon av dessa platser:",
  },
};

export default function ReleaseIntroPage({ onHide }) {
  const { user } = useProfile();
  const isAdmin = !!user?.administrator;
  const { i18n, t } = useTranslation();
  const lng = useLanguage(lng);
  const languages = useSelector(getLanguages);
  const { title, message, addButton, meet } = LOCAL_I18N[lng] ?? {};

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <header
        style={{
          padding: "1rem 2rem",
        }}
      >
        <img src={logo} alt="Logo" width="250" />
      </header>
      <section
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          backgroundColor: "#000",
          backgroundImage: "url(/intro-bg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          textAlign: "center",
          fontSize: "1.2em",
          color: "#fff",
        }}
      >
        <h2>{title}</h2>
        <p>{message}</p>
        <div style={{ marginBottom: "20px" }}>
          <VfLinkButton to='launch-event.ics' text={addButton} external />
        </div>
        <div style={{ width: '90%' }}>
          <P>{meet}</P>
          <div className="vf-row">
            <div className='vf-col-lg-6'>
              <ul style={{ listStyle: 'none' }}>
                <li>NL, Amsterdam, I01.G08/Kamerlingh &  H01.G03/Faraday</li>
                <li>NL, Diemen, Diemen DM33 OH #24</li>
                <li>UK, London, Canteen/Kitchen area</li>
                <li>UK, Hexham, Room Sirocco</li>
                <li>UK, Bristol, Kitchen area</li>
                <li>UK, Edinburgh, Steall</li>
              </ul>
            </div>
            <div className='vf-col-lg-6'>
              <ul style={{ listStyle: 'none' }}>
                <li>Denmark, Kolding, Jupitervej /HR3 #9</li>
                <li>Denmark, CPH, Coffee Area 18th floor</li>
                <li>Germany, Hamburg, Room 2.02 (Ueberseering Office)</li>
                <li>Germany, Berlin, Room 0B05 Kanzleramt</li>
                <li>Sweden, Solna, Room Arenastaden F5/Light</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <footer
        style={{
          background: "#ebf2f3",
          padding: "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>{isAdmin && <VfButton onClick={onHide} text="Hide intro" />}</div>
        <div>
          {languages.map((lang, i) => (
            <Fragment key={lang}>
              {!!i && " | "}
              <button
                onClick={() => i18n.changeLanguage(lang)}
                style={{ border: "none" }}
              >
                {t(`languages.${lang}`)}
              </button>
            </Fragment>
          ))}
        </div>
      </footer>
    </div>
  );
}
