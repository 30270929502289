import { combineReducers } from 'redux'
import { actionTypes } from '../actions/infoboxes'

const payload = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_INFOBOXES_SUCCEEDED:
      return action.payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INFOBOXES_INIT:
    case actionTypes.FETCH_INFOBOXES_PRE_INIT:
      return true
    case actionTypes.FETCH_INFOBOXES_FAILED:
    case actionTypes.FETCH_INFOBOXES_SUCCEEDED:
      return false

    default:
      return state
  }
}

const isError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INFOBOXES_FAILED:
      return true
    case actionTypes.FETCH_INFOBOXES_SUCCEEDED:
      return false

    default:
      return state
  }
}

const isMutating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_INFOBOX_INIT:
    case actionTypes.UPDATE_INFOBOX_PRE_INIT:
      return true
    case actionTypes.UPDATE_INFOBOX_FAILED:
    case actionTypes.UPDATE_INFOBOX_SUCCESS:
      return false

    default:
      return state
  }
}

const infoboxes = combineReducers({
  payload,
  isFetching,
  isError,
  isMutating,
})

export default infoboxes
