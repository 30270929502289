// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import actions from '../../../state/actions/data'
import filesActions from '../../../state/actions/files'
import {
  assignDocumentsPreInit,
  removeDocumentsPreInit
} from '../../../state/actions/other'
import { SubmitButton } from '../../Common'
import { VfButton, VfLinkButton } from '../../DesignSystem/Buttons'
import Form, { validationSchema } from './Form'
import { mergeUrlWithParams } from '../../../helpers/url'
import { useLanguage } from '../../../hooks'

const EditForm = ({ idea, url, notify, hideForm }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const lng = useLanguage()

  const formatCoCreators = coCreators =>
    coCreators.map(item => ({
      value: item.id,
      label: item.displayName
    }))

  const initialValues = {
    attachments: idea.documents || [],
    coCreators: idea.coCreators ? formatCoCreators(idea.coCreators) : [],
    cost: idea.cost || '',
    country: idea.country || '',
    description: idea.description || '',
    ideaValue: idea.ideaValue || '',
    realization: idea.realization || '',
    requiredResources: idea.requiredResources || '',
    summaryInEnglish: idea.summaryInEnglish || '',
    targetGroup: idea.targetGroup || '',
    title: idea.title || '',
  }

  const updateIdea = values => {
    const data = {
      ...values,
      id: idea.id,
      coCreators: values.coCreators.map(item => item.value)
    }

    const attachmentsToUpload = values.attachments.filter(item => !item.fileUrl)
    const attachmentsToRemove = idea.documents.filter(
      item => !values.attachments.find(el => el.id === item.id)
    )

    delete data.attachments

    const ideaUrl = idea._links.self.href
    const ideaContext = mergeUrlWithParams({ lng })(ideaUrl)

    const dataToInvalidate = [
      { dataType: 'ideas' },
      { dataType: 'idea-details', context: ideaContext }
    ]

    const callback = () => {
      notify()
      hideForm()
    }

    const assignDocuments = documents => {
      const url = idea._links['assign-document'].href
      const data = documents.map(item => item.file)
      dispatch(assignDocumentsPreInit(url, data, removeFiles))
    }

    const removeDocuments = documents => {
      const rawUrl = idea._links['remove-document'].href
      const data = documents.map(item => ({ documentId: item.id }))
      dispatch(removeDocumentsPreInit(rawUrl, data, callback))
    }

    const removeFiles = () => {
      if (attachmentsToRemove.length > 0) {
        // TODO: remove files from des-uploader
        // dispatch(filesActions.removeRequestPreInit(attachmentsToRemove, removeDocuments))
        removeDocuments(attachmentsToRemove)
      } else {
        callback()
      }
    }

    const uploadFiles = () => {
      if (attachmentsToUpload.length > 0) {
        const data = attachmentsToUpload.map(item => ({
          file: item,
          client: idea.id,
          contract: 'innovation-portal'
        }))

        dispatch(filesActions.uploadRequestPreInit(data, assignDocuments))
      } else {
        removeFiles()
      }
    }

    // ab: update idea => if new files added -> upload files to des-uploader && save info about documents in the idea => if any of the files removed -> remove files from des-uploader && remove info about documents in the idea => hide form && inform about success

    dispatch(
      actions.updateDataPreInit(url, data, null, dataToInvalidate, uploadFiles)
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateIdea}
      validationSchema={() => validationSchema(t, true)}
      render={({ handleSubmit, isSubmitting, dirty, values }) => (
        <form onSubmit={handleSubmit}>
          <Form t={t} edit />

          <div className='vf-row my-3'>
            <VfLinkButton
              to={`/idea-details/${idea.id}`}
              text={t('common.cancel')}
              size='large'
              color='secondary'
              className='vf-col m-1'
            />

            <SubmitButton
              button={
                <VfButton
                  className='vf-col m-1'
                  text={t('common.submit')}
                  size='large'
                />
              }
              inProgress={isSubmitting}
              inProgressText={t('common.submitting')}
              disabled={!dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    />
  )
}

EditForm.propTypes = {
  idea: PropTypes.object,
  url: PropTypes.string,
  notify: PropTypes.func,
  hideForm: PropTypes.func
}

export default EditForm
