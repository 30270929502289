import React from 'react'
import PropTypes from 'prop-types'

const Message = ({ type = 'success', children }) => (
  <p className={`vf-utility-typo--${type} my-3`}>{children}</p>
)

Message.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node
}

export default Message
