// @ts-check

import { takeEvery } from 'redux-saga/effects'
import { actionTypes } from '../../actions/data'

import fetchData from './read'
import createData from './create'
import deleteData from './delete'
import updateData from './update'

export default function* DataSaga () {
  yield takeEvery(actionTypes.FETCH_DATA_PRE_INIT, fetchData)
  yield takeEvery(actionTypes.CREATE_DATA_PRE_INIT, createData)
  yield takeEvery(actionTypes.DELETE_DATA_PRE_INIT, deleteData)
  yield takeEvery(actionTypes.UPDATE_DATA_PRE_INIT, updateData)
}
