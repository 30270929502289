// @ts-check
import { put, call, all } from 'redux-saga/effects'
import actionCreators from '../../actions/data'
import api, { apiPut } from '../../api'

function* updateData ({
  url,
  data,
  dataType,
  dataToInvalidate,
  callback,
  refreshUrl
}) {
  try {
    yield put(actionCreators.updateDataInit())

    const response = yield call(api, url, apiPut(data))

    if (response) {
      yield put(actionCreators.updateDataSuccess(response))

      if (Array.isArray(dataToInvalidate)) {
        const invalidations = dataToInvalidate.map(item =>
          put(actionCreators.invalidateData(item.dataType, item.context))
        )

        yield all(invalidations)
      }

      if (typeof callback === 'function') {
        yield call(callback, response)
      }

      if (!!refreshUrl) {
        yield put(actionCreators.requestPreInit(refreshUrl, dataType))
      }
    }
  } catch (err) {
    // yield put(actionCreators.updateDataFailed(err))
    console.log('error', err)
  }
}

export default updateData
