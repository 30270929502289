import React from 'react';
import { VfContainer } from '../../Components/DesignSystem/Utils';
import { EditForm } from '../../Components/cms/page';
import { WithNotification } from '../../Components/cms/challenge/Notifications';
import { useParams } from 'react-router-dom';

const EditPage = () => {
  const { pageId } = useParams();

  const FormWithNotifications = (
    <WithNotification Component={EditForm} pageId={pageId} />
  );
  return (
    <VfContainer>
      <h1 className="my-4">Edit page</h1>
      {FormWithNotifications}
    </VfContainer>
  );
};

export default EditPage;
