import React from 'react'
import PropTypes from 'prop-types'
import { AttachmentsInput } from './'

const ImageInput = props => {
  const settings = {
    maxNumberOfFiles: 1,
    acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/png'],
    acceptedFileTypesInfo: 'jpg, jpeg, png'
  }
  return <AttachmentsInput {...props} settings={settings} />
}

ImageInput.propTypes = {
  imageSize: PropTypes.objectOf(PropTypes.number)
}

export default ImageInput
