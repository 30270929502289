import React from 'react'
import PropTypes from 'prop-types'

const AttachmentsList = ({
  t,
  items,
  canDownload,
  canDelete,
  handleDownload,
  handleRemove,
}) => {
  return (
    <div className="vf-file-list mt-2">
      {items && items.length > 0 && items?.map(el => (
        <div
          key={el.id}
          className="vf-file-list__item"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            className={`vf-file-list__link ${
              canDownload ? 'vf-file-list__link--type-download' : ''
            }`}
          >
            {el.fileName}
          </div>

          <div>
            {canDownload && (
              <button
                className="idea-details__action-button"
                onClick={() => handleDownload(el)}
              >
                {t('common.download')}
              </button>
            )}
            {canDelete && (
              <button
                className="idea-details__action-button"
                onClick={() => handleRemove(el)}
              >
                {t('common.delete')}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

AttachmentsList.propTypes = {
  t: PropTypes.func,
  items: PropTypes.array,
  canDownload: PropTypes.bool,
  canDelete: PropTypes.bool,
  handleDownload: PropTypes.func,
  handleRemove: PropTypes.func,
}

AttachmentsList.defaultProps = {
  canDownload: false,
  canDelete: false,
}

export default AttachmentsList
