// @ts-check

import { call, select, put } from 'redux-saga/effects'
import actions from '../../actions/users'
import { getFetchUserIfNeeded } from '../../../selectors'
import api from '../../api'

function* fetchUser ({ url, id, callback }) {
  try {
    const fetchNeeded = yield select(getFetchUserIfNeeded(id))

    if (fetchNeeded) {
      yield put(actions.requestInit())
      const response = yield call(api, url)

      if (response) {
        yield put(actions.fetchSucceeded(response))
      }

      if (typeof callback === 'function') {
        yield call(callback)
      }
    }
  } catch (err) {
    yield put(actions.fetchFailed(err))
    console.log('error', err)
  }
}

export default fetchUser
