// @ts-check
import { put, call, select } from 'redux-saga/effects'
import actionCreators from '../../actions/data'
import api from '../../api'
import { getFetchIfNeeded } from '../../../configurations/dataTypes'

function* fetchData ({ url, dataType, callback }) {
  try {
    const fetchNeeded = yield select(
      getFetchIfNeeded(dataType, url)
    )

    // const fetchNeeded = true
    if (fetchNeeded) {
      yield put(actionCreators.requestInit(url, dataType))
      const data = yield call(api, url)
      yield put(actionCreators.fetchSucceeded(data, dataType, url))
      if (typeof callback === 'function') {
        yield call(callback)
      }
    }
  } catch (error) {
    yield put(actionCreators.fetchFailed(dataType, url))
  }
}

export default fetchData
