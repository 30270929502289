// @ts-check

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getUserDetails } from '../../selectors'

import EditForm from './EditForm'

const Edit = () => {
  const user = useSelector(getUserDetails())

  const [message, setMessage] = React.useState(false)

  const { t } = useTranslation()

  return (
    <>
      {user && (
        <EditForm
          t={t}
          user={user}
          notify={() => setMessage(true)}
          message={message}
        />
      )}
    </>
  )
}

export default Edit
