import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getIsUserAdmin } from '../../../selectors';
import { getCurrentUserId } from '../../../utils/azure';
import { icons } from '../../../assets/icons';

import './LinkToEditPage.scss';

const LinkToEditPage = ({ pageId }) => {
  const userId = getCurrentUserId();
  const isAdmin = useSelector(getIsUserAdmin(userId));
  const currentUrl = window.location.pathname;
  return isAdmin ? (
    <Link
      className="editPageLink"
      to={`/cms/page/${pageId}/update?returnTo=${currentUrl}`}
      title="Edit this page"
    >
      <img alt="" src={icons.Edit} />
    </Link>
  ) : null;
};

LinkToEditPage.propTypes = {
  pageId: PropTypes.string,
};

export default LinkToEditPage;
