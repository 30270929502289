// @ts-check

import React from 'react'
import PropTypes from 'prop-types'
import { useVfAlchemyScript } from '../../../hooks'
import {
  CreateForm as CreateCommentForm,
  List as CommentsList
} from '../Comments'
import Description from './Description'
import Attachments from './Attachments'
import ExpertView from '../Expert'
import './TabContent.scss'

const TabContent = ({
  idea,
  t
}) => {
  useVfAlchemyScript()

  const { admin, juryMember } = idea.userDecisions

  return (
    <div className='vf-tab-bar-container'>
      <ul
        className='vf-tab-bar'
        aria-label='Idea details tab bar'
        role='tablist'
      >
        <li
          id='vf-tab-1'
          className='vf-tab-bar-item vf-tab-bar-item--active'
          role='tab'
          aria-controls='idea-description-panel'
          aria-selected='true'
          tabIndex={0}
        >
          <div className='vf-tab-bar-link vf-icon-idea-details'>
            {t('idea-details.tab1')}
          </div>
        </li>
        {juryMember && (
          <li
            id='vf-tab-2'
            className='vf-tab-bar-item'
            role='tab'
            aria-controls='one-pager-panel'
            aria-selected='false'
            tabIndex={-1}
          >
            <div className='vf-tab-bar-link vf-icon-one-pager'>
              {t('idea-details.tab2')}
            </div>
          </li>
        )}
        {juryMember && (
          <li
            id='vf-tab-3'
            className='vf-tab-bar-item'
            role='tab'
            aria-controls='expert-view-panel'
            aria-selected='false'
            tabIndex={-1}
          >
            <div className='vf-tab-bar-link vf-icon-expert-view'>
              {t('idea-details.tab3')}
            </div>
          </li>
        )}
      </ul>

      <div className='vf-tab-bar-panels'>
        <div
          id='idea-description-panel'
          className='vf-panel'
          role='tabpanel'
          tabIndex={0}
          aria-labelledby='vf-tab-1'
        >
          <Description idea={idea} t={t} />
        </div>

        <div
          id='one-pager-panel'
          className='vf-panel'
          role='tabpanel'
          tabIndex={-1}
          aria-labelledby='vf-tab-2'
          hidden
        >
          <Description idea={idea} t={t} isOnePagerPanel />

          {!!idea.documents.length &&
            <Attachments idea={idea} isExpert={(juryMember || admin)} t={t} />}

          <h3>{t('titles.comments')}</h3>
          <CreateCommentForm idea={idea} resetAfterSubmit />
          <div className='mt-3'>
            <CommentsList idea={idea} />
          </div>
        </div>

        {(juryMember || admin) && (
          <div
            id='expert-view-panel'
            className='vf-panel'
            role='tabpanel'
            tabIndex={-1}
            aria-labelledby='vf-tab-3'
            hidden
          >
            <ExpertView idea={idea} />
          </div>
        )}
      </div>
    </div>
  )
}

TabContent.propTypes = {
  idea: PropTypes.object,
  // i18next
  t: PropTypes.func
}

export default TabContent
