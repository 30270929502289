import React, { useCallback, useMemo, useState } from 'react';
import { Formik, FieldArray, Field } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPeopleGroupsState } from '../../selectors';
import {
  createPeopleGroupsPreInit,
  updatePeopleGroupsPreInit,
  removePeopleGroupsPreInit,
} from '../../state/actions/peopleGroups';
import { getEnvVariable } from '../../.env.custom';
import { SubmitButton, TextInput } from '../Common';
import { VfButton } from '../DesignSystem/Buttons';
import { icons } from '../../assets/icons';

const PeopleGroupForm = ({
  group,
  index,
  initialValues,
  isDisabled,
  isNew,
  onRemove,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const hasChanges = useMemo(() => {
    return (
      group.name !== initialValues.groups[index].name ||
      group.description !== initialValues.groups[index].description
    );
  }, [group, initialValues, index]);

  const handleRemove = () => {
    if (group.id && window.confirm(t('common.deleteConfirm'))) {
      onRemove(group.id);
    }
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    setError(null);
    try {
      await onUpdate(group, index);
    } catch (e) {
      setError(t('common.updateError'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="people-group-form contact-row">
      <div className="mr-1">
        <Field
          name={`groups.${index}.name`}
          placeholder={t('Name')}
          component={TextInput}
        />
      </div>
      <div className="mr-2">
        <Field
          name={`groups.${index}.description`}
          placeholder={t('Description')}
          component={TextInput}
        />
      </div>
      {isNew && (
        <SubmitButton
          button={
            <VfButton
              className="contact-row__submit ml-3"
              text="Add new group"
              size="small"
              type="submit"
            />
          }
          inProgress={isSubmitting}
          inProgressText={t('common.submitting')}
          disabled={isDisabled || isSubmitting}
        />
      )}
      {!isNew && (
        <div className="group-actions">
          <img
            src={icons.Delete}
            alt={t('common.delete')}
            onClick={handleRemove}
            className="delete-icon"
            style={{
              cursor: 'pointer',
              width: '24px',
              height: '24px',
            }}
          />
          <SubmitButton
            button={
              <VfButton
                className="contact-row__submit mt-2 mb-2 ml-1"
                text="Update group"
                size="small"
                onClick={handleUpdate}
              />
            }
            inProgress={isSubmitting}
            inProgressText={t('common.submitting')}
            disabled={isSubmitting || !hasChanges}
          />
          {error && <div className="error-text">{error}</div>}
        </div>
      )}
    </div>
  );
};

const PeopleGroupsForm = ({ lang }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const peopleGroups = useSelector(getPeopleGroupsState);

  const url = useMemo(
    () => `${getEnvVariable('REACT_APP_API_ROOT')}people-groups`,
    []
  );

  const initialValues = useMemo(
    () => ({
      groups: peopleGroups && peopleGroups.length > 0 ? [{
        id: null,
        name: '',
        description: '',
      }, ...peopleGroups] : [{
        id: null,
        name: '',
        description: '',
      }],
    }),
    [peopleGroups]
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting, resetForm }) => {
      const newGroup = values.groups[0];
      if (newGroup.name || newGroup.description) {
        setSubmitting(true);
        dispatch(createPeopleGroupsPreInit(url, newGroup, lang))
          .then(() => {
            resetForm({
              values: {
                groups: [{
                  id: null,
                  name: '',
                  description: '',
                }, ...values.groups.slice(1)],
              },
            });
          })
          .catch((error) => {
            console.error('Error creating new group:', error);
            setSubmitting(false);
          })
          .finally(() => setSubmitting(false));
      } else {
        setSubmitting(false);
      }
    },
    [dispatch, url, lang]
  );

  const handleUpdate = useCallback(
    (group, index) => {
      return dispatch(updatePeopleGroupsPreInit(url, group, lang));
    },
    [dispatch, url, lang]
  );

  const handleRemove = useCallback(
    (groupId) => dispatch(removePeopleGroupsPreInit(url, groupId, lang)),
    [dispatch, url, lang]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({
        values,
        handleSubmit,
        isSubmitting,
      }) => {
        const isNewGroupButtonDisabled = values.groups[0].name === '' && values.groups[0].description === '';
        return (
          <form onSubmit={handleSubmit}>
            <FieldArray name="groups">
              {({ remove }) => (
                <>
                  <PeopleGroupForm
                    key="new-group"
                    group={values.groups[0]}
                    index={0}
                    isNew
                    isDisabled={isNewGroupButtonDisabled}
                    initialValues={initialValues}
                  />
                  {values.groups.slice(1).map((group, index) => {
                    if (!group || !group.id) {
                      return null;
                    }

                    return (
                      <PeopleGroupForm
                        key={group.id || `existing-${index}`}
                        group={group}
                        index={index + 1}
                        isNew={false}
                        onRemove={(groupId) => {
                          handleRemove(groupId);
                          remove(index + 1);
                        }}
                        onUpdate={(updatedGroup) => handleUpdate(updatedGroup, index + 1)}
                        initialValues={initialValues}
                      />
                    );
                  })}
                </>
              )}
            </FieldArray>
          </form>
        );
      }}
    </Formik>
  );
};

PeopleGroupsForm.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default PeopleGroupsForm;
