// @ts-check
import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { updateDataPreInit } from '../../../state/actions/data/update'
import CommentForm from './CommentForm'

const EditForm = ({ item, idea, editFormCallBack, ...other }) => {
  const dispatch = useDispatch()

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const dataType = 'comments'
    const url = item._links['comment-update'].href
    const refreshUrl = idea._links.comments.href

    const dataToInvalidate = [{ dataType: 'comments' }]

    dispatch(
      updateDataPreInit(
        url,
        values,
        dataType,
        dataToInvalidate,
        editFormCallBack,
        refreshUrl
      )
    )
  }

  return (
    <CommentForm
      {...other}
      handleSubmit={handleSubmit}
      initialValues={{ content: item.content }}
    />
  )
}

EditForm.propTypes = {
  idea: PropTypes.object,
  item: PropTypes.object,
  editFormCallBack: PropTypes.func
}

export default EditForm
